import { Input, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';

import { CardItem } from './';

const Text = ({
  value,
  label,
  edit,
  editHook,
  name,
  required,
  error,
  errorText,
  setValid,
  tooltip,
  columnMode = false,
  type
}) => {
  const classes = useStyles();
  setValid = setValid ? setValid : (setValid = () => '');
  editHook = editHook ? editHook : (editHook = () => '');
  error = error ? error : {};
  type = type ? type : "text"

  // Validate Intial Value
  useEffect(() => {
    if (required && value) {
      setValid({ [name]: true });
    } else if (!required) {
      setValid({ [name]: true });
    } else {
      setValid({ [name]: false });
    }
  }, [name, required, setValid, value]);

  let content = edit ? (
    <Input
      data-testid={label}
      className={classes.input}
      onChange={e => editHook(e.target.value, name)}
      name={name}
      value={value ? value : ''}
      type={type}
    />
  ) : (
    <Typography component="span">
      {value ? value : '-'} {tooltip ? tooltip : ''}
    </Typography>
  );

  return (
    <CardItem
      label={label}
      name={name}
      error={error}
      errorText={errorText}
      content={content}
    />
  );
};

const useStyles = makeStyles({
  input: {
    width: '100%',
    height: 24,
    '& input': {
      padding: 0,
      textAlign: 'right',
    },
  },
});
export default Text;
