import { List, ListItem, Typography, makeStyles } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { getAxios, postAxios } from '../../../util';

import AddUpdateForm from './AddUpdateForm';
import { CardHead } from '../../common/CardContent';
import LoadUpdateItem from './LoadUpdateItem';
import { PrivateComponent } from '../../common/Privatize';
import PropTypes from 'prop-types';
import { ShadowCard } from '../../common/Containers';
import { mexBorderCrossingsCoordinates } from '../../../util/options';
import moment from 'moment';
import { useAuth0 } from '../../../react-auth0-wrapper';

const LoadUpdates = ({ loadId, mexBorderCrossingId, isForager }) => {
  const classes = useStyles();

  const { accessToken } = useAuth0();
  const [loadUpdateItems, setLoadUpdateItems] = useState([]);
  const [loadBooked, setLoadBooked] = useState(null);

  const getUpdates = useCallback(
    () =>
      getAxios(`load/load-updates`, { params: { loadId } }, accessToken).then(
        data => {
          if (data) {
            const { bookedDate, loadUpdates } = data;
            const currentTimezone = moment.tz.guess();
            const loadUpdatesItems =
              loadUpdates &&
              loadUpdates.map(item => ({
                ...item,
                createdAt: moment(item.createdAt)
                  .tz(currentTimezone)
                  .format('MM/DD/YY HH:mm z'),
              }));
            setLoadUpdateItems(loadUpdatesItems);
            setLoadBooked(
              moment(bookedDate)
                .tz(currentTimezone)
                .format('MM/DD/YY HH:mm z')
            );
          }
        }
      ),
    [accessToken, loadId]
  );

  const borderCrossingObj = mexBorderCrossingsCoordinates.filter(
    crossingCity => crossingCity.id === mexBorderCrossingId
  )[0];

  const addUpdate = newUpdate =>
    postAxios(
      'load/load-updates',
      { loadId, borderCrossingObj, ...newUpdate },
      accessToken
    ).then(() => {
      getUpdates();
    });

  useEffect(() => {
    getUpdates();
  }, [loadId, accessToken, getUpdates]);

  return (
    <ShadowCard>
      <CardHead headingText="Load Updates" />
      <List className={classes.list}>
        {loadUpdateItems &&
          loadUpdateItems.map(
            ({ id, createdAt, milesToNext, city, state, updateType }) => (
              <ListItem key={id} disableGutters>
                <LoadUpdateItem
                  createdAt={createdAt}
                  milesToNext={milesToNext}
                  city={city}
                  state={state}
                  updateType={updateType}
                />
              </ListItem>
            )
          )}
      </List>
      <PrivateComponent reqScopes={['forager:read']}>
        <AddUpdateForm onAdd={addUpdate} />
      </PrivateComponent>
      <Typography align="center" className={classes.loadBooked}>
        <b>Load Booked: </b>
        {loadBooked}
      </Typography>
    </ShadowCard>
  );
};

LoadUpdates.propTypes = {
  loadId: PropTypes.string.isRequired,
  isForager: PropTypes.bool,
};

LoadUpdates.defaultProps = {
  isForager: false,
};

const useStyles = makeStyles({
  list: {
    maxHeight: 370,
    overflow: 'auto',
    margin: '15px 0',
  },
  loadBooked: {
    marginTop: 15,
  },
});

export default LoadUpdates;
