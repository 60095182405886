import { Selector, StackedText } from '../common/CardContent';
import { Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { arrayToSelectorObject, getAxios, putAxios } from '../../util';

import { Loader } from '../common/Loader';
import { PageHeader } from '../common/PageHeader';
import PropTypes from 'prop-types';
import { SaveEditIconButton } from '../common/Buttons';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import { numberToUSD } from '../../util/numberUtils';
import { useAuth0 } from '../../react-auth0-wrapper';
import DuplicateLoadButton from './DuplicateLoadButton';

const LoadOverviewHeader = ({ loadId, isForager }) => {
  const classes = useStyles();

  const { accessToken } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [loadNotFound, setLoadNotFound] = useState(false);
  const [edit, setEdit] = useState(false);
  const [headerInfo, setHeaderInfo] = useState({
    ascendNumber: '',
    status: '',
    state: '',
    bolNumber: '',
    trailerNumber: '',
  });
  const [loadStates, setLoadStates] = useState();
  const [loadStatuses, setLoadStatuses] = useState();

  const handleEdit = () => setEdit(true);

  const handleSave = () => {
    putAxios('load/update-header', { payload: { headerInfo } }, accessToken);
    setEdit(false);
  };

  useEffect(() => {
    getAxios('load/header', { params: { loadId } }, accessToken).then(data => {
      if (data) {
        setHeaderInfo({
          ...data,
        });
      } else {
        setLoadNotFound(true);
      }

      setLoading(false);
    });
  }, [accessToken, loadId]); //eslint-disable-line

  useEffect(() => {
    getAxios('load/state-types', {}, accessToken).then(data => {
      if (data) {
        setLoadStates(data);
      }
    });

    getAxios('load/status-types', {}, accessToken).then(data => {
      if (data) {
        setLoadStatuses(data);
      }
    });
  }, [accessToken, loadId]); //eslint-disable-line

  const infoFieldsContent = (
    <Grid container spacing={2}>
      <Grid item>
        <StackedText label="Mode" value={headerInfo.mode} name="mode" stacked />
      </Grid>
      <Grid item>
        <StackedText
          label="EQ Type"
          value={headerInfo.eqType}
          name="eqType"
          stacked
        />
      </Grid>
      <Grid item>
        <StackedText
          label="EQ Length"
          value={headerInfo.eqLength}
          name="eqLength"
        />
      </Grid>
      <Grid item>
        <StackedText
          label="Pickup Date"
          value={
            headerInfo.pickupDate &&
            moment(headerInfo.pickupDate).format('MM/DD/YY')
          }
          name="pickupDate"
        />
      </Grid>
      <Grid item>
        <StackedText
          label="Rate"
          value={headerInfo.rate && numberToUSD(headerInfo.rate)}
          name="rate"
        />
      </Grid>

      {headerInfo.crossing && (
        <Grid item>
          <StackedText
            label="Crossing Mode"
            value={headerInfo.crossingMode}
            name="crossingMode"
          />
        </Grid>
      )}
      {headerInfo.crossing && (
        <Grid item>
          <StackedText
            label="Crossing City"
            value={headerInfo.crossing}
            name="crossingCity"
          />
        </Grid>
      )}
      <Grid item>
        <StackedText
          label="Pickup Location"
          value={headerInfo.pickupLocation}
          name="pickupLocation"
        />
      </Grid>
      <Grid item>
        <StackedText
          label="Delivery Location"
          value={headerInfo.deliveryLocation}
          name="deliveryLocation"
        />
      </Grid>
      <Grid item className={classes.text}>
        <StackedText
          label="Reference #"
          value={headerInfo.referenceNumber}
          name="referenceNumber"
          edit={edit}
          editHook={refNum =>
            setHeaderInfo({ ...headerInfo, referenceNumber: refNum })
          }
        />
      </Grid>
      <Grid item className={classes.text}>
        <StackedText
          label="BOL #"
          value={headerInfo.bolNumber}
          name="bolNumber"
          edit={edit}
          editHook={bol => setHeaderInfo({ ...headerInfo, bolNumber: bol })}
        />
      </Grid>
      <Grid item className={classes.text}>
        <StackedText
          label="Trailer #"
          value={headerInfo.trailerNumber}
          name="trailerNumber"
          edit={edit}
          editHook={trailerNumber =>
            setHeaderInfo({ ...headerInfo, trailerNumber })
          }
        />
      </Grid>
      {isForager && (
        <>
          <Grid item className={classes.text}>
            <StackedText
              label="Ascend Load#"
              value={headerInfo.ascendNumber}
              edit={edit}
              editHook={v => setHeaderInfo({ ...headerInfo, ascendNumber: v })}
              name="ascendNumber"
            />
          </Grid>
          <Grid item className={classes.text}>
            {loadStatuses && headerInfo.state === 1 && (
              <Selector
                label="Load Status"
                options={
                  loadStatuses && arrayToSelectorObject(loadStatuses, 'id')
                }
                value={headerInfo && headerInfo.status}
                stacked={true}
                edit={edit}
                editHook={v => setHeaderInfo({ ...headerInfo, status: v })}
                name="loadStatus"
              />
            )}
          </Grid>
          <Grid item className={classes.text}>
            {loadStates && (
              <Selector
                label="Load State"
                options={loadStates && arrayToSelectorObject(loadStates, 'id')}
                value={headerInfo && headerInfo.state}
                stacked={true}
                edit={edit}
                editHook={v => setHeaderInfo({ ...headerInfo, state: v })}
                name="loadState"
              />
            )}
          </Grid>
          <Grid item>
            <SaveEditIconButton
              edit={edit}
              onClick={edit ? handleSave : handleEdit}
            />
          </Grid>
        </>
      )}
    </Grid>
  );

  const pageName = `FRGW-${loadId} ${
    isForager ? ' | ' + headerInfo.customer : ''
  } `;

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        !loadNotFound && (
          <PageHeader
            name={pageName}
            secondRowContent={infoFieldsContent}
            topRightContent={
              isForager ? '' : <DuplicateLoadButton loadId={loadId} />
            }
          />
        )
      )}
    </>
  );
};

LoadOverviewHeader.propTypes = {
  loadId: PropTypes.string.isRequired,
  isForager: PropTypes.bool,
};

LoadOverviewHeader.defaultProps = {
  isForager: false,
};

const useStyles = makeStyles(theme => ({
  foragerChip: {
    backgroundColor: theme.palette.blueSky.main,
    color: '#fff',
  },
  customerChip: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  labelSpan: {
    fontSize: '0.75rem',
    marginLeft: 10,
  },
  divider: {
    height: 30,
  },
  text: {
    '& .MuiFormControl-root div': {
      height: 24,
    },
  },
}));

export default LoadOverviewHeader;
