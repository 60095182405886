import { Grid, Typography } from '@material-ui/core';

import PropTypes from 'prop-types';
import React from 'react';
import { titleCase } from '../../../util';

const LoadUpdateItem = ({
  createdAt,
  milesToNext,
  city,
  state,
  updateType,
}) => (
  <Grid container>
    <Grid container direction="row" justify="space-between" alignItems="center">
      <Grid item xs={6}>
        <Typography
          align="left"
          variant="button"
        >{`${city}, ${state}`}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography align="right" variant="body1" color="secondary">
          {titleCase(updateType)}
        </Typography>
      </Grid>
    </Grid>
    <Grid container direction="row" justify="space-between" alignItems="center">
      <Grid item xs={6}>
        <Typography align="left" variant="caption">
          {createdAt}
        </Typography>
      </Grid>
      {/* <Grid item xs={6}>
        <Typography
          align="right"
          variant="caption"
          display="block"
        >{`${milesToNext} mi to border`}</Typography>
      </Grid> */}
    </Grid>
  </Grid>
);

LoadUpdateItem.propTypes = {
  createdAt: PropTypes.string.isRequired,
  milesToNext: PropTypes.number.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  updateType: PropTypes.string.isRequired,
};

export default LoadUpdateItem;
