import React from 'react';

import { List, makeStyles } from '@material-ui/core';
import { NavAvatar, NavParent } from './';
import { navItems } from '../../util';
import { LightDivider } from '../common/Dividers';
import { useAuth0 } from '../../react-auth0-wrapper';

const NavDropdown = () => {
  const classes = useStyles();
  const { isAuthenticated } = useAuth0();

  // Generate the Parent/Top Level Menu Items
  const createParentItems = () => {
    // Data feed from navItems file
    return navItems.map((parentItem, index) => {
      return (
        isAuthenticated && (
          <NavParent
            parentItem={parentItem}
            key={`${[parentItem.name]}-${index}`}
          />
        )
      );
    });
  };

  return (
    <List className={classes.root} component="nav">
      <NavAvatar />
      <LightDivider variant="middle" />
      {createParentItems()}
    </List>
  );
};

const useStyles = makeStyles({
  root: {
    textDecoration: 'none',
    color: '#fff',
  },
});

export default NavDropdown;
