import * as Yup from 'yup';

import {
  BorderOperations,
  EnterLoadHeader,
  LoadCharges,
  LoadCreatedModal,
  StopDetailsCard,
  Requirements,
} from '../../components/LoadSystem';
import { Box, Button, Grid, makeStyles } from '@material-ui/core';
import { FieldArray, Form, withFormik, yupToFormErrors } from 'formik';
import React, { useCallback, useEffect } from 'react';

import { AccountReps } from '../../components/common/AccountReps';
import { ProductInfoView } from '../LoadSystem';
import { postAxios } from '../../util';
import { useAuth0 } from '../../react-auth0-wrapper';
import isAfterTwoBizDays from '../../util/isAfterTwoBizDays';

const validationSchema = Yup.object().shape({
  pickups: Yup.array().of(
    Yup.object().shape({
      facilityName: Yup.string().required('Facility required'),
      contactId: Yup.string().required('Contact required'),
      apptOpen: Yup.string()
        .when('$hasLeadTime', {
          is: true,
          then: Yup.string().test({
            message: '48 hour lead time requires 2 business days to pickup',
            test: (item) => isAfterTwoBizDays(item),
          }),
          otherwise: Yup.string().required('Date is required'),
        })
        .nullable(),
    })
  ),
  deliveries: Yup.array().of(
    Yup.object().shape({
      facilityName: Yup.string().required('Facility required'),
      contactId: Yup.string().required('Contact required'),
      apptOpen: Yup.string().required('Date is required').nullable(),
    })
  ),
  products: Yup.array().of(
    Yup.object().shape({
      cargoCommodityId: Yup.string().required('Commodity required'),
      packagingId: Yup.string().required('Packaging required'),
      quantity: Yup.number()
        .required('Quantity required')
        .typeError('Quantity required')
        .positive('Must be a positive value'),
      weight: Yup.number()
        .required('Weight required')
        .typeError('Quantity required')
        .positive('Must be a positive value'),
      loadValue: Yup.mixed(),
      isLoadValueAbove250: Yup.boolean(),
      value: Yup.number()
        .typeError('Value required')
        .when(['loadValue', 'isLoadValueAbove250'], {
          // Validate loadValue against $250,000
          is: (loadValue, isLoadValueAbove250) =>
            loadValue >= 250000 && isLoadValueAbove250 === false,
          // False positive to force error message
          then: (val) =>
            val
              .lessThan(
                249999,
                'Please enter a total load value of less then $250,000'
              )
              .required(),
        })
        .when('loadValue', {
          // Validate loadValue against $250,000
          is: (loadValue) => loadValue >= 500000,
          // False positive to force error message
          then: (val) =>
            val
              .negative('Please call us for load values over $500,000')
              .required(),
        })
        .positive('Must be a positive value'),
    })
  ),
  // Customs House Brokers
  // usa -> mex: NEED usa && mex
  // mex -> usa: NEED mex && usa
  // mex -> can: NEED mex && usa && can
  // can -> mex: NEED mex && usa
  // us -> can: NEED can ONLY
  // can -> usa: NEED usa ONLY
  borderOperations: Yup.object({
    borderOperationsRoute: Yup.string(),
    mexCustomsBrokerId: Yup.string().when('borderOperationsRoute', {
      is: (val) =>
        val === 'MEXUSA' ||
        val === 'USAMEX' ||
        val === 'CANMEX' ||
        val === 'MEXCAN',
      then: (val) => val.required('Mexico customs broker required'),
    }),
    usaCustomsBrokerId: Yup.string().when('borderOperationsRoute', {
      is: (val) =>
        val === 'MEXUSA' ||
        val === 'USAMEX' ||
        val === 'CANUSA' ||
        val === 'MEXCAN' ||
        val === 'CANMEX',
      then: (val) => val.required('US customs broker required'),
    }),
    canCustomsBrokerId: Yup.string().when('borderOperationsRoute', {
      is: (val) => val === 'USACAN' || val === 'MEXCAN',
      then: (val) => val.required('Canada customs broker required'),
    }),
  }),
});

const EnterLoadInfo = (props) => {
  const classes = useStyles();
  const { accessToken } = useAuth0();

  const {
    location: {
      state: { initialEnterLoadData, loadId, duplicate },
    },
    status,
    setFieldValue,
  } = props;

  const {
    borderOperationsRoute,
    charges,
    mexBorderCrossingId,
    crossingModeId,
    delCountry,
    deliveries,
    equipmentType,
    isHazmat,
    isNextDay,
    isWeekend,
    pickups,
    puCountry,
    verifiedCustomerId,
    newLoad,
  } = props.values;

  const setAccessToFormik = useCallback(() => {
    setFieldValue('accessToken', accessToken);
  }, [accessToken]); // eslint-disable-line

  // Takes user to top of page on render
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setAccessToFormik();
  }, [setAccessToFormik]);

  return (
    <>
      <Form>
        <Grid container>
          <Grid item xs={12}>
            <EnterLoadHeader initialLoadData={initialEnterLoadData} />
          </Grid>

          <Grid item xs={8}>
            <FieldArray
              name="pickups"
              render={(
                arrayHelpers //DO NOT REMOVE - Weber
              ) => (
                <>
                  {pickups &&
                    pickups.map((pickup, index) => {
                      return (
                        <Box mt={3} key={index}>
                          <StopDetailsCard
                            index={index}
                            formikType="pickups"
                            headingText={`${pickup.address}`}
                            subheader={`Pickup #${index + 1}`}
                            customerId={verifiedCustomerId}
                            isNextDay={isNextDay}
                            isWeekend={isWeekend}
                            customerReferenceLabel="Pickup #"
                            stopDetailsType="pickup"
                            duplicate={duplicate}
                          />
                        </Box>
                      );
                    })}
                </>
              )}
            />
            <Box mt={3}>
              <Grid item>
                <BorderOperations
                  borderOperationsRoute={borderOperationsRoute}
                  crossingCityId={mexBorderCrossingId}
                  crossingModeId={crossingModeId}
                  delCountry={delCountry}
                  puCountry={puCountry}
                  formikType="borderOperations"
                  charges={charges}
                  canEdit={true}
                />
              </Grid>
            </Box>
            <FieldArray
              name="deliveries"
              render={(
                arrayHelpers //DO NOT REMOVE - Weber
              ) => (
                <>
                  {deliveries &&
                    deliveries.map((delivery, index) => (
                      <Box mt={3} key={index}>
                        <StopDetailsCard
                          index={index}
                          formikType="deliveries"
                          headingText={`${delivery.address}`}
                          subheader={`Delivery #${index + 1}`}
                          customerId={verifiedCustomerId}
                          isNextDay={isNextDay}
                          isWeekend={isWeekend}
                          customerReferenceLabel="Delivery #"
                          stopDetailsType="delivery"
                          duplicate={duplicate}
                        />
                      </Box>
                    ))}
                </>
              )}
            />
            <Box mt={3}>
              <ProductInfoView
                isHazmat={isHazmat}
                edit={true}
                equipmentType={equipmentType}
              />
            </Box>
          </Grid>

          <Grid item xs={4}>
            <Box mt={3} ml={3}>
              <AccountReps />
            </Box>
            <Box mt={3} ml={3}>
              <Requirements
                loadId={loadId}
                formikType="requirements"
                setRequirements={(initialReq) =>
                  setFieldValue('requirements', initialReq)
                }
                editable
                newLoad={newLoad}
              />
            </Box>
            <Box mt={3} ml={3}>
              <LoadCharges
                loadId={loadId}
                charges={charges}
                setCharges={(val) => setFieldValue('charges', val)}
              />
            </Box>
            <Box mt={3} ml={3}>
              <Button
                data-testid="create-load-btn"
                type="submit"
                variant="contained"
                fullWidth={true}
                disabled
                color="primary"
                className={classes.extendedIcon}
              >
                CONTACT FORAGER{' '}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Form>
      {!!status && status.isModalOpen && status.loadId && (
        <LoadCreatedModal
          loadId={status.loadId}
          isOpen={true}
          dataTestId="load-created-modal"
        />
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  extendedIcon: {
    marginRight: theme.spacing(1),
    height: '65px',
  },
}));

export default withFormik({
  mapPropsToValues: (props) => {
    // props from previous screens (user selections)
    const {
      borderOperationsRoute,
      crossingLocation,
      mexBorderCrossingId,
      crossingModeId,
      loadValue,
      isLoadValueAbove250,
      products,
      customerId,
      crossing,
      usaCustomsBroker,
      usaCustomsBrokerId,
      mexCustomsBroker,
      mexCustomsBrokerId,
      canCustomsBroker,
      canCustomsBrokerId,
      transloadFacility,
      crossingMode,
      ...rest
    } = props.location.state.initialEnterLoadData;

    const defaultProduct = {
      canStack: false,
      currencyId: 2,
      cargoCommodityId: '',
      value: loadValue,
      quantity: '',
      weight: '',
      description: '',
      hazmat: false,
      hazmatClassId: null,
      hazmatUnId: null,
      packagingId: '',
      packingGroupId: null,
      reefer: false,
      height: null,
      width: null,
      length: null,
      hiTemp: null,
      loTemp: null,
      reeferOptionId: null,
      loadValue: 0,
      isLoadValueAbove250: isLoadValueAbove250,
    };

    return {
      accessToken: '',
      crossingLocation: crossingLocation && crossingLocation,
      customerReference: '',
      bolNumber: '',
      trailerNumber: '',
      loadNotes: null,
      requirements: [],
      borderOperations: {
        borderOperationsRoute,
        borderArrival: null,
        loadCrossed: null,
        mexBorderCrossingId,
        crossingModeId,
        mexCustomsBrokerId: mexCustomsBrokerId || '',
        usaCustomsBrokerId: usaCustomsBrokerId || '',
        canCustomsBrokerId: canCustomsBrokerId || '',
        papsNumber: '',
        parsNumber: '',
      },
      products: products ? products : [defaultProduct],
      loadValue,
      customerId,
      verifiedCustomerId: customerId,
      borderOperationsRoute,
      newLoad: true,
      ...rest,
    };
  },

  handleSubmit: (values, { setStatus }) => {
    const {
      canCustomsBrokerId,
      mexCustomsBrokerId,
      usaCustomsBrokerId,
      mexBorderCrossingId, // mexBorderCrossingId
      crossingModeId,
      papsNumber,
      parsNumber,
    } = values.borderOperations;

    const {
      charges,
      verifiedCustomerId,
      bolNumber,
      trailerNumber,
      customerReference,
      equipmentTypeId,
      loadNotes,
      products,
      pickups,
      deliveries,
      totalMileage,
      borderCoords,
      requirements,
      priceQuoteId,
    } = values;

    const loadData = {
      borderCoords,
      charges: charges.filter((c) => c.per > 0),
      customerId: verifiedCustomerId,
      bolNumber,
      trailerNumber,
      customerReference,
      canCustomsBrokerId,
      mexCustomsBrokerId,
      usaCustomsBrokerId,
      crossingModeId,
      deliveries,
      pickups,
      papsNumber,
      parsNumber,
      equipmentTypeId,
      loadNotes,
      products,
      priceQuoteId: priceQuoteId || null,
      mexBorderCrossingId,
      foragerHandleCrossing:
        charges[1] && charges[1].crossingRate > 0 ? true : false,
      totalDistance: totalMileage,
      loadRequirements: requirements
        ? requirements.map((requirement) => ({
            ...requirement,
            selectedRequirement:
              requirement.selectedRequirement ||
              requirement.loadAttributeTypeId,
          }))
        : [],
    };

    postAxios('load/create-new', { ...loadData }, values.accessToken).then(
      (data) => {
        const loadId = data;
        setStatus({ isModalOpen: true, loadId });
      }
    );
  },
  validate: (values) =>
    validationSchema
      .validate(values, {
        context: { hasLeadTime: values.selectedRateType === 'lead48hr' },
        abortEarly: false,
      })
      .catch((err) => {
        throw yupToFormErrors(err);
      }),
  validateOnChange: false,
  validateOnBlur: true,
})(EnterLoadInfo);
