import {
  AccountTree,
  AttachMoney,
  Build,
  Business,
  Dashboard,
  FileCopy,
  Notes,
  People,
} from '@material-ui/icons';

const navItems = [
  {
    // Parent Menu Item Info
    name: 'Dashboard',
    // Icon
    icon: Dashboard,
    // Scope needed to see the Item
    permission: ['customer-portal:read'],
    // Child Menu Items belonging to parent
    pages: [],
    // If needs to be a single Link pass in true to no drop and a path. otherwise pass null to path
    noDrop: true,
    path: 'customer-portal/dashboard',
  },
  {
    name: 'Tools',
    icon: Build,
    permission: ['forager:read'],
    pages: [
      {
        path: 'tools/instant-rate',
        name: 'Instant Rate',
        permission: ['forager:read'],
      },
      {
        path: 'tools/bulk-upload',
        name: 'Bulk Uploader',
        permission: ['bulk-pricing:read	'],
      },
      {
        path: 'tools/portal-rates',
        name: 'All Portal Rates',
        permission: ['forager:read'],
      },
    ],
  },
  {
    name: 'Rates',
    icon: AttachMoney,
    permission: ['customer-portal:read'],
    pages: [
      {
        path: 'customer-portal/instant-rate',
        name: 'Instant Rate',
        permission: ['customer-portal:read'],
      },
      // {
      //   path: 'customer-portal/rate-history',
      //   name: 'Rate History',
      //   permission: ['customer-portal:read'],
      // },
    ],
  },
  {
    name: 'Loads',
    icon: AccountTree,
    permission: ['forager:read'],
    pages: [
      {
        path: 'loads/load-board',
        name: 'Load Board',
        permission: ['forager:read'],
      },
      {
        path: 'loads/past-loads',
        name: 'Past Loads',
        permission: ['forager:read'],
      },
    ],
  },
  {
    name: 'My Loads',
    icon: Notes,
    permission: ['customer-portal:read'],
    pages: [
      {
        path: 'customer-portal/loads/planned',
        name: 'Planned',
        permission: ['customer-portal:read'],
      },
      {
        path: 'customer-portal/loads/in-transit',
        name: 'In Transit',
        permission: ['customer-portal:read'],
      },

      {
        path: 'customer-portal/loads/history',
        name: 'Past Loads',
        permission: ['customer-portal:read'],
      },
    ],
  },
  {
    // Parent Menu Item Info
    name: 'Facilities',
    icon: Business,
    permission: ['customer-portal:read'],
    pages: [
      {
        path: 'customer-portal/my-facilities',
        name: 'My Facilities',
        permission: ['customer-portal:read'],
      },
    ],
  },
  {
    // Parent Menu Item Info
    name: 'Customers',
    icon: People,
    permission: ['forager:read'],
    pages: [
      {
        path: 'customers/all-customers',
        name: 'All Customers',
        permission: ['forager:read'],
      },
      {
        path: 'customers/add-new',
        name: 'Add New',
        permission: ['customer:create'],
      },
    ],
  },
  {
    // Parent Menu Item Info
    name: 'Facilities',
    icon: Business,
    permission: ['forager:read'],
    pages: [
      {
        path: 'facilities/all-facilities',
        name: 'All Facilities',
        permission: ['forager:read'],
      },
      {
        path: 'facilities/add-new',
        name: 'Add New',
        permission: ['facility:create'],
      },
    ],
  },

  {
    // Parent Menu Item Info
    name: 'Customs Broker',
    icon: FileCopy,
    permission: ['forager:read'],
    pages: [
      {
        path: 'customs-brokers/all',
        name: 'All Customs Brokers',
        permission: ['forager:read'],
      },
      {
        path: 'customs-brokers/add-new',
        name: 'Add New',
        permission: ['customs-broker:create'],
      },
    ],
  },
];

export default navItems;
