import React, { useEffect } from 'react';

import { CardItem } from './';
import NumberFormat from 'react-number-format';
import { makeStyles } from '@material-ui/core';

const Currency = ({
  value,
  label,
  edit,
  editHook,
  name,
  required,
  error,
  errorText,
  setValid,
  max,
  tableFormat,
}) => {
  const classes = useStyles();
  setValid = setValid ? setValid : (setValid = () => '');
  editHook = editHook ? editHook : (editHook = () => '');

  // Validate Intial Value
  useEffect(() => {
    if (max && Number(value) > max) {
      setValid({ [name]: false });
    } else if (required) {
      if (value) {
        setValid({ [name]: true });
      } else {
        setValid({ [name]: false });
      }
    } else {
      setValid({ [name]: true });
    }
  }, [max, name, required, setValid, value]);

  let content = edit ? (
    <NumberFormat
      className={classes.input}
      value={value}
      data-testid={name}
      name={name}
      thousandSeparator={true}
      prefix="$"
      onValueChange={values => {
        const { value } = values;
        editHook(value, name);
      }}
    />
  ) : (
    <NumberFormat
      value={value}
      thousandSeparator={true}
      prefix="$"
      displayType="text"
      renderText={value => (
        <span className={!tableFormat && classes.text}>
          {value ? value : '-'}{' '}
        </span>
      )}
    />
  );

  if (tableFormat) {
    return <>{content}</>;
  }

  return (
    <CardItem
      label={label}
      name={name}
      error={error}
      errorText={errorText}
      content={content}
    />
  );
};

const useStyles = makeStyles({
  input: {
    height: 24,
    border: 'none',
    borderBottom: '1px solid black',
    fontSize: '1rem',
    padding: 0,
    textAlign: 'right',
    width: '100%',
    outline: 'none',
  },
  text: {
    fontSize: '1rem',
    textAlign: 'right',
    height: 24,
  },
});

export default Currency;
