import React, { useEffect, useState } from 'react';

import { FilterTable } from '../common/Tables';
import { Grid } from '@material-ui/core';
import { Loader } from '../common/Loader';
import { ShadowCard } from '../common/Containers';
import formatPhoneNumber from '../../util/formatPhoneNumber';
import { getAxios } from '../../util/axiosHelper';
import { useAuth0 } from '../../react-auth0-wrapper';
import { customerTypes } from '../../util/options';
import EyeBallLinkCell from '../common/Tables/EyeBallLinkCell';

const CustomerSearchTable = ({ table, limit }) => {
  const [customers, setCustomers] = useState();
  const [loading, setLoading] = useState(true);
  const { accessToken } = useAuth0();

  useEffect(() => {
    getAxios('customer/all', {}, accessToken).then(data => {
      if (data) {
        const custData = data.map(cust => {
          return {
            ...cust,
            customer: cust.dbaName || cust.legalName,
          };
        });
        setCustomers(custData);
        setLoading(false);
      }
    });
  }, [accessToken]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {loading ? (
          <Loader />
        ) : (
          <ShadowCard noTopPadding>
            <FilterTable
              title="All Customers"
              filtering
              noFooter={limit}
              noSearch={limit}
              columns={columns}
              data={customers}
            />
          </ShadowCard>
        )}
      </Grid>
    </Grid>
  );
};

const columns = [
  EyeBallLinkCell('/customers/profile/id', 'companyId'),
  {
    title: 'Customer',
    field: 'customer',
  },
  {
    title: 'Customer Type',
    field: 'customerType',
    render: ({ customerType }) => (customerType === null ? '' : customerTypes[customerType]),
  },
  {
    title: 'Main Rep',
    field: 'mainRep',
  },
  { title: 'City', field: 'city' },

  { title: 'Main POC', field: 'mainPoc' },
  {
    title: 'POC Phone',
    field: 'pocPhone',
    render: ({ pocPhone }) => pocPhone && formatPhoneNumber(pocPhone),
  },
  { title: 'POC Email', field: 'pocEmail' },
];

export default CustomerSearchTable;
