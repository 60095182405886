import Moment from 'moment';
// if its after 3pm they are required to select a time on the 3rd business day
// if its before 3pm they can select a time on the 2nd business day
// moment has saturday as 6 and sunday as 7

const nextAvailableDayModifier = {
  1: 1, // Monday => Wednesday
  2: 1, // Teusday => Thursday
  3: 1, // Wednesday => Friday
  4: 3, // Thursday => Monday
  5: 3, // Friday => Tuesday
  6: 2, // Saturday => Tuesday
  7: 1, // Sunday => Tuesday
};

const isAfterTwoBizDays = date => {
  const dayOfWeekToday = Moment().format('d');

  const after5 = Moment().format('H') >= 17;

  const adjustment = after5
    ? nextAvailableDayModifier[(parseInt(dayOfWeekToday) + 1) % 7]
    : nextAvailableDayModifier[dayOfWeekToday];

  return Moment(date).isAfter(Moment().add(adjustment, 'd'), 'day');
};

export default isAfterTwoBizDays;
