import './assets/css/index.css';
import './errorReporting';

import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { Grid, Typography } from '@material-ui/core';

import App from './App';
import { Auth0Provider } from './react-auth0-wrapper';
import PrivateComponent from './components/common/Privatize/PrivateComponent';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import history from './util/history';
import theme from './theme';

require('dotenv').config();

// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

const productionBar = process.env.REACT_APP_ENV_NAME === 'production' && (
  <Grid
    container
    direction="row"
    justify="center"
    alignItems="center"
    style={{
      backgroundColor:
        process.env.REACT_APP_ENV_NAME === 'production' ? '#ff0000' : '#F79520',
      position: 'fixed',
      color: '#fff',
      zIndex: 10000,
      fontSize: '15px',
    }}
  >
    <Typography>
      <span role="img" aria-label="warning">
        ⚠️☠️
      </span>{' '}
      NOTICE: YOU ARE IN <strong>PRODUCTION ENVIRONMENT!</strong>
      <span role="img" aria-label="warning">
        ☠️⚠️
      </span>
    </Typography>
  </Grid>
);

const stagingBar = process.env.REACT_APP_ENV_NAME === 'staging' && (
  <Grid
    container
    direction="row"
    justify="center"
    alignItems="center"
    style={{
      backgroundColor:
        process.env.REACT_APP_ENV_NAME === 'production' ? '#ff0000' : '#F79520',
      position: 'fixed',
      color: '#fff',
      zIndex: 10000,
      fontSize: '15px',
    }}
  >
    <Typography>
      <span role="img" aria-label="warning">
        ⚠️☠️
      </span>{' '}
      NOTICE: YOU ARE IN <strong>STAGING ENVIRONMENT!</strong>
      <span role="img" aria-label="warning">
        ☠️⚠️
      </span>
    </Typography>
  </Grid>
);

ReactDOM.render(
  <Router history={history}>
    <Auth0Provider
      domain="forager-logistics.auth0.com"
      client_id="mdL9JnEF0ghoDfozlN4Y4QybwnU3uPlz"
      redirect_uri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={process.env.REACT_APP_API_URL}
      leeway={3000}
    >
      {/*Normalizes CSS for Material UI
        https://material-ui.com/components/css-baseline/*/}
      <CssBaseline />
      {/* Exposes/provides theme defined above to App */}
      <PrivateComponent reqScopes={['dev:read']}>
        {productionBar}
      </PrivateComponent>
      <PrivateComponent reqScopes={['forager:read']}>
        {stagingBar}
      </PrivateComponent>
      <MuiThemeProvider theme={theme}>
        <App />
      </MuiThemeProvider>
    </Auth0Provider>
  </Router>,
  document.getElementById('root')
);
