// Utility Function that checks if all inputs are valid

const checkArray = validArr => {
  // Check if the array contains pairs that have a false value
  return validArr.every(
    val => Object.values(val)[0] === true || Object.values(val)[0] === undefined
  );
};

const createErrorObj = validArr => {
  // Create an error object that containes objects with key input name and value set to true
  const errObj = {};
  validArr.map(valid => {
    if (Object.values(valid)[0] === false) {
      errObj[Object.keys(valid)[0]] = !Object.values(valid)[0];
    }

    return false;
  });

  return errObj;
};

// It rejects with an object of errors
const catchErrors = validArr => {
  return new Promise((resolve, reject) => {
    if (checkArray(validArr)) {
      // Resolve if no errors found
      resolve();
    } else {
      //  Reject with error object
      reject(createErrorObj(validArr));
    }
  });
};

export default catchErrors;
