import { SvgIcon, makeStyles } from '@material-ui/core';

import { CopyToClipboard as CopyPackage } from 'react-copy-to-clipboard';
import { FileCopyOutlined } from '@material-ui/icons';
import React from 'react';
import { red } from '@material-ui/core/colors';

// Renders and Icon that when clicked copies its prop to clipboard
function CopyToClipboard({ toCopy }) {
  const classes = useStyles();

  return (
    <CopyPackage text={toCopy}>
      <SvgIcon className={classes.iconHover}>
        <FileCopyOutlined />
      </SvgIcon>
    </CopyPackage>
  );
}

const useStyles = makeStyles(theme => ({
  iconHover: {
    height: '.70em',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
      cursor: 'pointer',
    },
    '&:active': {
      color: red[200],
    },
  },
}));

export default CopyToClipboard;
