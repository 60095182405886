import {
  FilledInput,
  FormControl,
  FormHelperText,
  Input,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect } from 'react';

const StackedText = ({
  label,
  value,
  name,
  edit,
  editHook,
  errorText,
  required,
  setValid,
  error,
  filled = false,
}) => {
  const classes = useStyles();
  setValid = setValid ? setValid : (setValid = () => '');
  error = error ? error : {};
  editHook = editHook ? editHook : (editHook = () => '');

  useEffect(() => {
    if (required && value) {
      setValid({ [name]: true });
    } else if (!required) {
      setValid({ [name]: true });
    } else {
      setValid({ [name]: false });
    }
  }, [name, required, setValid, value]);

  const inputProps = {
    value: value || '',
    onChange: e => editHook(e.target.value, name),
    className: classes.input,
    autoComplete: 'new-password',
  };

  const input = filled ? (
    <FilledInput {...inputProps} disableUnderline />
  ) : (
    <Input {...inputProps} />
  );

  return (
    <FormControl className={classes.formControl}>
      {edit ? (
        <>
          <FormHelperText>{label}</FormHelperText>
          {input}
        </>
      ) : (
        <>
          <FormHelperText>{label}</FormHelperText>
          <Typography
            align="left"
            noWrap
            className={classes.text}
            data-testid={label}
          >
            {value ? value : '-'}
          </Typography>
        </>
      )}
      <FormHelperText error>{error[name] && errorText}</FormHelperText>
    </FormControl>
  );
};

const useStyles = makeStyles({
  formControl: {
    width: '100%',
  },
  text: {
    height: 29,
    margin: '5px 0 0',
  },
  input: {
    height: 50,
    margin: '5px 0 0',
    '& input': {
      padding: 0,
    },
  },
});

export default StackedText;
