import { Dashboard, LoadsView } from '../views/CustomerPortal';
import { Redirect, Route } from 'react-router-dom';

import { MyFacilities } from '../views/CustomerPortal/Facilities';
import { PrivateRoute } from '../components/common/Privatize';
import { RateCalculator } from '../views/ToolSystem';
import React from 'react';

const CustomerPortal = ({ match }) => {
  return (
    <>
      <PrivateRoute
        path={`${match.url}/dashboard`}
        render={props => <Dashboard {...props} />}
      />
      <PrivateRoute
        path={`${match.url}/my-facilities`}
        render={props => <MyFacilities {...props} />}
      />
      <PrivateRoute
        path={`${match.url}/instant-rate`}
        render={props => <RateCalculator {...props} />}
      />
      {/* <PrivateRoute
        path={`${match.url}/rate-history`}
        render={props => <MyRates {...props} />}
      /> */}
      <PrivateRoute
        path={`${match.url}/loads`}
        render={props => <LoadsView {...props} />}
      />
      <Route
        exact
        path={`${match.path}`}
        render={props => (
          <Redirect to={`${match.url}/instant-rate`} {...props} />
        )}
      />
    </>
  );
};

export default CustomerPortal;
