import { AttachMoney, History, Person, Work } from '@material-ui/icons';
import { Button, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { Loader } from '../common/Loader';
import { PageHeader } from '../common/PageHeader';
import PropTypes from 'prop-types';
import { getAxios } from '../../util';
import { useAuth0 } from '../../react-auth0-wrapper';

const rightTabsOptions = [
  {
    name: 'main',
    label: 'Profile',
    icon: <Person />,
  },
  {
    name: 'rates',
    label: 'Rates',
    icon: <AttachMoney />,
  },
  {
    name: 'ops',
    label: 'Operations',
    icon: <Work />,
  },
  {
    name: 'loads',
    label: 'All Loads',
    icon: <History />,
  },
];

const ProfileHeader = ({ customerId }) => {
  const classes = useStyles();

  const { accessToken } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [customerNotFound, setCustomerNotFound] = useState(false);
  const [activeTab, setActiveTab] = useState('main');
  const [info, setInfo] = useState({});

  const handleTabChange = tabName => () => {
    setActiveTab(tabName);
  };

  useEffect(() => {
    // Grab the param of the url string
    const pathArray = window.location.pathname.split('/');
    const lastStr = pathArray[pathArray.length - 1];
    // Pass param into the setter
    setActiveTab(lastStr);
  }, [activeTab]);

  useEffect(() => {
    getAxios(
      'customer/company-meta',
      { params: { customerId } },
      accessToken
    ).then(data => {
      if (data) {
        setInfo(data);
      } else {
        setCustomerNotFound(true);
      }
      setLoading(false);
    });
  }, [accessToken, customerId]);

  const tabsContent = (
    <Grid container justify="flex-end">
      {rightTabsOptions.map(({ name, icon, label }) => (
        <Button
          key={name}
          variant="contained"
          color={name === activeTab ? 'primary' : 'secondary'}
          onClick={handleTabChange(name)}
          icon={icon}
          className={classes.button}
          component={Link}
          to={name}
        >
          {label}
        </Button>
      ))}
    </Grid>
  );

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <PageHeader
          name={info.dbaName || info.legalName}
          subHeadingLocation={
            !customerNotFound && `${info.city}, ${info.state}`
          }
          topRightContent={tabsContent}
        />
      )}
    </>
  );
};

ProfileHeader.propTypes = {
  customerId: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
  button: {
    marginRight: 10,
    minWidth: 74,
  },
});

export default ProfileHeader;
