import React, { useEffect, useState } from 'react';
import { FilterTable, RateCell } from '../../components/common/Tables';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import { useAuth0 } from '../../react-auth0-wrapper';
import { getAxios } from '../../util/axiosHelper';
import { mexBorderCrossings } from '../../util/options';
import { ShadowCard } from '../../components/common/Containers';
import { Loader } from '../../components/common/Loader';

const PortalRates = () => {
  const [myRatesData, setMyRatesData] = useState(null);
  const { accessToken } = useAuth0();

  useEffect(() => {
    const params = {
      portalRates: true,
    };
    getAxios('pricing/portal-rates', { params }, accessToken).then(data => {
      setMyRatesData(data);
    });
  }, [accessToken]);
  const columns = [
    {
      title: 'Quote Date',
      defaultSort: 'desc',
      field: 'quoteDate',
      render: rowData => moment(rowData.quoteDate).format('MM/DD/YYYY HH:mm'),
    },
    { title: 'Equip', field: 'eqType' },
    { title: 'Origin', field: 'origin' },
    { title: 'Destination', field: 'destination' },
    {
      title: 'Crossing',
      field: 'crossingId',
      render: rowData =>
        rowData.crossingId && mexBorderCrossings[rowData.crossingId],
    },
    { title: 'Hazmat', field: 'hazmat', type: 'boolean' },
    { title: 'Team', field: 'team', type: 'boolean' },
    {
      title: 'Next Day',
      field: 'nextday',
      render: ({ nextday }) => nextday ? <RateCell { ...nextday } /> : '',
    },
    {
      title: '48-Hour Lead Time',
      field: 'leadtime',
      render: ({ leadtime }) => leadtime ? <RateCell { ...leadtime } /> : '',
    },
    {
      title: 'Weekend',
      field: 'weekend',
      render: ({ weekend }) => weekend ? <RateCell { ...weekend } /> : '',
    },
    {
      title: 'Generated By',
      field: 'generatedBy',
    },
    {
      title: 'Company',
      field: 'companyName',
    },
    {
      title: 'Expire Date',
      field: 'expireDate',
      render: rowData =>
        moment(rowData.quoteDate)
          .add(30, 'days')
          .format('MM/DD/YYYY'),
    },
  ];

  return (
    <>
      {!myRatesData ? (
        <Loader />
      ) : (
        <ShadowCard noTopPadding>
          <FilterTable
            columns={columns}
            data={myRatesData && myRatesData}
            title="All Portal Rates"
            showActions={false}
            filtering
            noSearch
          />
        </ShadowCard>
      )}
    </>
  );
};

export default PortalRates;
