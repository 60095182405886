import { AddNew, AllCustomers, Profile } from '../views/CustomerSystem';

import { PrivateRoute } from '../components/common/Privatize';
import React from 'react';
import { Route } from 'react-router-dom';

const CustomerSystem = ({ match }) => {
  return (
    <>
      <Route
        path={`${match.url}/all-customers`}
        render={props => <AllCustomers {...props} />}
      />
      <PrivateRoute
        path={`${match.url}/add-new`}
        render={props => <AddNew {...props} />}
        reqScope={['customer:create']}
      />
      <Route
        path={`${match.url}/profile/id/:id`}
        render={props => <Profile {...props} />}
      />
    </>
  );
};

export default CustomerSystem;
