import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { CardHead, Text } from '../../common/CardContent';
import React, { useCallback, useEffect, useState } from 'react';
import { currencies, feeTypes } from '../../../util/options';
import {
  getAxios,
  numberToUSD,
  postAxios,
  putAxios,
  deleteAxios,
} from '../../../util';

import AddFeeForm from './AddFeeForm';
import FeeItem from './FeeItem';
import PropTypes from 'prop-types';
import { SaveEditIconButton } from '../../common/Buttons';
import { ShadowCard } from '../../common/Containers';
import { useAuth0 } from '../../../react-auth0-wrapper';

const findIdFromOptionsByValue = (options, value) =>
  Object.keys(options).find(key => options[key] === value);

const LoadCharges = ({ loadId, charges, isForager, setCharges }) => {
  const { accessToken } = useAuth0();
  const [edit, setEdit] = useState(false);
  const [loadCharges, setLoadCharges] = useState([]);

  // Calculates the total charges for display
  const totalCharges =
    loadCharges &&
    loadCharges.reduce((total, charge) => {
      return total + charge.value;
    }, 0);

  const handleEdit = () => setEdit(true);

  const updateCharges = () => {
    putAxios(
      'customer-portal/update-load-charges',
      { loadCharges },
      accessToken
    );
  };

  const handleSave = () => {
    updateCharges();
    setEdit(false);
  };

  const handleUpdateCharge = chargeId => (value, key) => {
    setLoadCharges(
      loadCharges.map(charge =>
        charge.id === chargeId ? { ...charge, [key]: parseInt(value) } : charge
      )
    );
  };

  const addCharge = newFee => {
    postAxios(
      'customer-portal/create-load-charge',
      { loadId, ...newFee },
      accessToken
    ).then(getLoadCharges);
  };

  const deleteCharge = chargeId => {
    deleteAxios(
      'customer-portal/delete-load-charge',
      { chargeId },
      accessToken
    ).then(getLoadCharges);
  };

  const getLoadCharges = useCallback(() => {
    if (loadId) {
      getAxios(
        'customer-portal/load-charges',
        { params: { loadId } },
        accessToken
      ).then(data => {
        const formattedCharges =
          data &&
          !data.msg &&
          data.map(c => ({
            ...c,
            rateUnitId: 8,
            rateTypeId: parseInt(findIdFromOptionsByValue(feeTypes, c.label)),
            currencyId: findIdFromOptionsByValue(currencies, c.currency),
          }));

        setLoadCharges(formattedCharges);

        if (setCharges) setCharges(formattedCharges);
      });
    }
  }, [accessToken, loadId, setCharges]);

  useEffect(() => {
    if (charges) {
      const normalizedCharges = [
        {
          currency: 'USD',
          label: 'transportation',
          value: charges[0].transportationCharges || charges[0].value,
          rateTypeId: charges[0].rateTypeId,
        },
      ];
      if (charges[1] && charges[1].crossingRate > 0) {
        normalizedCharges.push({
          currency: 'USD',
          label: 'crossing fee',
          value: charges[1].crossingRate || charges[1].value,
          rateTypeId: charges[1].rateTypeId,
        });
      }
      setLoadCharges(normalizedCharges);
    } else {
      getLoadCharges();
    }
  }, [getLoadCharges, charges]);

  return (
    <ShadowCard data-testid="load-charges">
      <CardHead
        headingText="Charges"
        editable={isForager}
        action={
          isForager && (
            <SaveEditIconButton
              edit={edit}
              onClick={edit ? handleSave : handleEdit}
            />
          )
        }
      />
      <Grid item xs={12}>
        <Box mt={2}>
          {loadCharges &&
            loadCharges.map(({ id, rateTypeId, currencyId, value }, idx) => (
              <FeeItem
                key={idx}
                id={id}
                rateTypeId={rateTypeId}
                currencyId={currencyId}
                value={value}
                handleChange={handleUpdateCharge(id)}
                edit={edit}
                handleDelete={deleteCharge}
                isDeleteable
              />
            ))}
        </Box>
        {isForager && edit && (
          <Box mt={2}>
            <AddFeeForm addCharge={addCharge} />
          </Box>
        )}
        <Divider variant="fullWidth" />
        <Box mt={2}>
          <Text label="Total" value={`${numberToUSD(totalCharges)}`} />
          {loadId && (
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="flex-end"
            >
              <Typography variant="caption">
                Expenses due 30 days after receipt of paperwork
              </Typography>
            </Grid>
          )}
        </Box>
      </Grid>
    </ShadowCard>
  );
};

LoadCharges.propTypes = {
  charges: PropTypes.arrayOf(PropTypes.shape({})),
  isForager: PropTypes.bool,
  setCharges: PropTypes.func,
};

LoadCharges.defaultProps = {
  charges: null,
  isForager: false,
};

export default LoadCharges;
