import { CardHead, Currency, Percent } from '../common/CardContent';
import { Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { getAxios, putAxios } from '../../util';

import { Loader } from '../common/Loader';
import PrivateComponent from '../common/Privatize/PrivateComponent';
import PropTypes from 'prop-types';
import { SaveEditIconButton } from '../common/Buttons';
import { ShadowCard } from '../common/Containers';
import { useAuth0 } from '../../react-auth0-wrapper';

const AccountManagement = ({ customerId }) => {
  const classes = useStyles();

  const { accessToken } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [customerNotFound, setCustomerNotFound] = useState(false);
  const [edit, setEdit] = useState(false);
  const [accountManagement, setAccountManagement] = useState({});

  const handleEdit = () => setEdit(true);

  const handleSave = () => {
    putAxios(
      'customer/account-management',
      { payload: { customerId, ...accountManagement } },
      accessToken
    );
    setEdit(false);
  };

  const handleChange = (value, key) =>
    setAccountManagement({ ...accountManagement, [key]: value });

  useEffect(() => {
    getAxios(
      'customer/account-management',
      { params: { customerId } },
      accessToken
    ).then(data => {
      if (data) {
        setAccountManagement(data);
      } else {
        setCustomerNotFound(true);
      }
      setLoading(false);
    });
  }, [accessToken, customerId]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        !customerNotFound && (
          <ShadowCard>
            <CardHead
              headingText="Account Management"
              action={
                <PrivateComponent reqScopes={['customer-margins:update']}>
                  <SaveEditIconButton
                    edit={edit}
                    onClick={edit ? handleSave : handleEdit}
                  />
                </PrivateComponent>
              }
            />
            <Grid container spacing={2} className={classes.form}>
              <Grid item xs={6}>
                <Percent
                  label="Southbound Margin"
                  value={accountManagement.marginSb}
                  edit={edit}
                  editHook={handleChange}
                  name="marginSb"
                />
              </Grid>
              <Grid item xs={3}>
                <Currency
                  label="Min"
                  value={accountManagement.marginSbMin}
                  edit={edit}
                  editHook={handleChange}
                  max={accountManagement.marginSbCap}
                  name="marginSbMin"
                />
              </Grid>
              <Grid item xs={3}>
                <Currency
                  label="Max"
                  value={accountManagement.marginSbCap}
                  edit={edit}
                  editHook={handleChange}
                  name="marginSbCap"
                />
              </Grid>
              <Grid item xs={6}>
                <Percent
                  label="Northbound Margin"
                  value={accountManagement.marginNb}
                  edit={edit}
                  editHook={handleChange}
                  name="marginNb"
                />
              </Grid>
              <Grid item xs={3}>
                <Currency
                  label="Min"
                  value={accountManagement.marginNbMin}
                  edit={edit}
                  editHook={handleChange}
                  max={accountManagement.marginNbCap}
                  name="marginNbMin"
                />
              </Grid>
              <Grid item xs={3}>
                <Currency
                  label="Max"
                  value={accountManagement.marginNbCap}
                  edit={edit}
                  editHook={handleChange}
                  name="marginNbCap"
                />
              </Grid>
            </Grid>
          </ShadowCard>
        )
      )}
    </>
  );
};

AccountManagement.propTypes = {
  customerId: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
  form: {
    marginTop: 15,
  },
});

export default AccountManagement;
