import {
  Box,
  Button,
  CardActions,
  CardContent,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  makeStyles,
} from '@material-ui/core';
import { CardHead, Suggest } from '../common/CardContent';
import { Delete, RemoveRedEye } from '@material-ui/icons';
import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ShadowCard } from '../common/Containers';

const RelatedCustomsBrokers = ({
  title,
  handleAdd,
  handleRemove,
  customsBrokers,
  companyId,
  country,
}) => {
  const classes = useStyles();

  const [customsBrokerId, setCustomsBrokerId] = useState(null);
  const [customsBrokerName, setCustomsBrokerName] = useState(null);

  const handleAddClick = () => {
    handleAdd(companyId, customsBrokerId);
    setCustomsBrokerId(null);
    setCustomsBrokerName(null);
  };

  return (
    <ShadowCard>
      <CardHead headingText={title} />
      <CardContent className={classes.cardContent}>
        <Grid container className={classes.listHeader}>
          <Grid item xs={2} />
          <Grid item xs={4}>
            <Box>Customs Broker</Box>
          </Grid>
          <Grid item xs={5}>
            <Box>Location</Box>
          </Grid>
        </Grid>

        <List padding={{ padding: 0 }}>
          <Divider />
          {customsBrokers.map(item => {
            return (
              <ListItem key={item.id} disableGutters>
                <Grid container alignItems="center">
                  <Grid item xs={2}>
                    <IconButton
                      size="small"
                      component={Link}
                      to={`/customs-brokers/profile/id/${item.customsBrokerCompanyId}/main`}
                    >
                      <RemoveRedEye />
                    </IconButton>
                  </Grid>
                  <Grid item xs={4}>
                    <Box>{item.dbaName || item.legalName}</Box>
                  </Grid>
                  <Grid item xs={5}>
                    <Box>{item.city}</Box>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      size="small"
                      onClick={() => handleRemove(companyId, item)}
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                </Grid>
              </ListItem>
            );
          })}
        </List>
      </CardContent>
      <CardActions>
        <Grid container justify="space-between">
          <Suggest
            edit
            errorText="This field is required"
            name={title}
            placeholder="Customs Broker"
            value={customsBrokerName || ''}
            onAddressEmpty={() => {
              setCustomsBrokerId(null);
              setCustomsBrokerName(null);
            }}
            editHook={customsBroker => {
              setCustomsBrokerId(customsBroker.id);
              setCustomsBrokerName(
                customsBroker.dbaName || customsBroker.legalName
              );
            }}
            valuesToExclude={(() => {
              const values = customsBrokers.map(
                chb => chb.customsBrokerCompanyId
              );

              values.push(country);

              return values;
            })()}
            urlString="customs-broker/autocomplete/search"
          />
          <Button
            color="primary"
            size="small"
            variant="contained"
            className={classes.addButton}
            onClick={handleAddClick}
          >
            Add
          </Button>
        </Grid>
      </CardActions>
    </ShadowCard>
  );
};

const useStyles = makeStyles(theme => ({
  cardContent: {
    padding: '0',
  },
  listHeader: {
    color: theme.palette.secondary.main,
    marginTop: 25,
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  addButton: {
    margin: theme.spacing(1),
  },
}));

RelatedCustomsBrokers.propTypes = {
  title: PropTypes.string.isRequired,
  handleAdd: PropTypes.func.isRequired,
  handleShowMoreClick: PropTypes.func.isRequired,
  customsBrokers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      customsBroker: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default RelatedCustomsBrokers;
