import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Button, TextField, makeStyles } from '@material-ui/core';
import { DateTime, Selector } from '../../common/CardContent';
import { Toggle } from '../../common/Switches';
import { getAxios, postAxios } from '../../../util';

const CreateIncident = ({ loadId, accessToken, setEdit, edit }) => {
  const classes = useStyles();
  const [incidentDesc, setIncidentDesc] = useState('');
  const [selectedIncident, setSelectedIncident] = useState(0);
  // the Date constructor creates a new time object with the current time/date,
  // which will be auto-converted into a DB accepted format
  // (2019-11-21T05:00:00.000Z) during JSON.stringify
  const [incidentDate, setIncidentDate] = useState(new Date());
  const [isPrivate, setIsPrivate] = useState(false);
  const [incidentOptions, setIncidentOptions] = useState([]);

  const clearInputs = () => {
    setIncidentDesc('');
    setIncidentDate(null);
    setIsPrivate(false);
    setSelectedIncident(0);
  };

  const cancelEdit = () => {
    clearInputs();
    setEdit(!edit);
  };

  // post new incident
  const addIncident = () => {
    const newIncident = {
      selectedIncident,
      incidentDesc,
      incidentDate,
      isPrivate,
    };
    postAxios('load/load-incidents', { loadId, newIncident }, accessToken)
      .then(res => {
        setEdit(!edit);
      })
      .catch(err => console.log(err));
    clearInputs();
  };

  // get incident options
  const getIncidentOptions = useCallback(() => {
    getAxios('load/load-incident-options', {}, accessToken)
      .then(res => {
        const options = res.reduce((dropdownList, option) => {
          dropdownList[Number(option.id)] = option.type;
          return dropdownList;
        }, {});
        setIncidentOptions({ 0: 'Incident Type', ...options });
      })
      .catch(err => console.log(err));
  }, [accessToken]);

  useEffect(() => {
    getIncidentOptions();
  }, [accessToken, getIncidentOptions]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <TextField
          className={classes.textArea}
          multiline
          canEdit={edit}
          value={incidentDesc}
          variant="filled"
          placeholder="Description"
          rows={4}
          margin="normal"
          onChange={e => setIncidentDesc(e.target.value)}
        />
      </Grid>
      <Grid container direction="row" justify="space-between">
        <Grid item xs={12}>
          <DateTime
            enableCalendar
            dateTime={incidentDate}
            edit={true}
            label="Incident Date"
            name="incidentDate"
            editHook={setIncidentDate}
          />
        </Grid>
        <Grid item xs={6}>
          <Toggle
            label="Private"
            labelPlacement="start"
            value={isPrivate}
            editHook={() => setIsPrivate(!isPrivate)}
            checked={isPrivate}
          />
        </Grid>
        <Grid item xs={6}>
          <Selector
            value={selectedIncident}
            options={incidentOptions}
            editHook={setSelectedIncident}
            edit
          />
        </Grid>
        <Grid item xs={12} container justify="flex-end">
          <Grid item xs={2}>
            <Button
              color="primary"
              variant="contained"
              onClick={cancelEdit}
              size="small"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={addIncident}
              size="small"
              disabled={selectedIncident === 0 || !incidentDesc}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CreateIncident;

const useStyles = makeStyles({
  textArea: {
    width: '100%',
    marginBottom: '20px',
  },
});
