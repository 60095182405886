import React, { useEffect, useState } from 'react';

import { Loader } from '../common/Loader';
import { PageHeader } from '../common/PageHeader';
import { getAxios } from '../../util/axiosHelper';
import { useAuth0 } from '../../react-auth0-wrapper';

const FacilityProfileHeader = ({ companyId }) => {
  const { accessToken } = useAuth0();
  const [info, setInfo] = useState({
    facilityName: '',
    facilityCity: '',
    facilityState: '',
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAxios(
      'facility/profile-header',
      { params: { companyId } },
      accessToken
    ).then(data => {
      const facilityInfo = data && {
        ...data,
        name: data.dbaName || data.legalName,
      };
      setInfo(facilityInfo);
      setLoading(false);
    });
  }, [companyId, accessToken]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <PageHeader name={info.name} location={info.city} />
      )}
    </>
  );
};

export default FacilityProfileHeader;
