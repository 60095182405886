import React from 'react';
import { Grid } from '@material-ui/core';
import { InTransitLoadsView, PastLoadsView, PlannedLoadsView } from './';
import { LoadOverview } from '../LoadSystem';
import { Route, Redirect } from 'react-router-dom';

const LoadsView = ({ match }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Route
          path={`${match.path}/in-transit`}
          render={props => <InTransitLoadsView {...props} />}
        />

        <Route
          path={`${match.path}/history`}
          render={props => <PastLoadsView {...props} />}
        />

        <Route
          path={`${match.path}/planned`}
          render={props => <PlannedLoadsView {...props} />}
        />

        <Route
          path={`${match.path}/id/:id`}
          render={props => <LoadOverview {...props} />}
        />

        {/* Redirect to planend */}
        <Route
          exact
          path={`${match.path}`}
          render={props => <Redirect to={`${match.url}/planned`} {...props} />}
        />
      </Grid>
    </Grid>
  );
};

export default LoadsView;
