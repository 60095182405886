import { Button, Grid, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { ModalContainer } from '../common/Modals';
import PropTypes from 'prop-types';
import { getAxios } from '../../util';
import { useAuth0 } from '../../react-auth0-wrapper';

const LoadCreatedModal = ({ loadId, isOpen, dataTestId = '' }) => {
  const classes = useStyles();
  const [distributionEmail, setDistributionEmail] = useState(null);
  const { accessToken } = useAuth0();

  useEffect(() => {
    getAxios('customer/get-distribution-email', {}, accessToken).then(data =>
      setDistributionEmail(data)
    );
  }, [accessToken]);

  return (
    <ModalContainer open={isOpen} modalWidth={400} dataTestId={dataTestId}>
      <Typography
        variant="h6"
        color="secondary"
        align="center"
        className={classes.header}
      >
        Load Created Successfully!
      </Typography>
      <Typography align="center">
        Thank you for using SCOUT by Forager!, <br /> Your{' '}
        <b>Load# FRGW-{loadId}</b> has been created.
      </Typography>
      <Typography align="center" className={classes.lightText}>
        For any questions or issues, please email our team at{' '}
        <Link href={`mailto:${distributionEmail}`} className={classes.email}>
          {distributionEmail}
        </Link>
      </Typography>
      <Grid container spacing={3} justify="space-around">
        <Grid item>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            size="small"
            component={Link}
            to="/customer-portal/instant-rate"
          >
            New Quote
          </Button>
        </Grid>
        <Grid item>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            size="small"
            component={Link}
            to={`/customer-portal/loads/id/${loadId}`}
          >
            View Load
          </Button>
        </Grid>
      </Grid>
    </ModalContainer>
  );
};

LoadCreatedModal.propTypes = {
  loadId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: 25,
  },
  lightText: {
    marginTop: 25,
    color: '#727272',
  },
  email: {
    color: theme.palette.blueSky.main,
  },
  button: {
    marginTop: 25,
    minWidth: 120,
    backgroundColor: theme.palette.blueSky.main,
  },
}));

export default LoadCreatedModal;
