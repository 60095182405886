import React from 'react';
import { Grid } from '@material-ui/core';
import { CustomerSearchTable } from '../../components/CustomerSystem';

const AllCustomers = ({ match }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CustomerSearchTable />
      </Grid>
    </Grid>
  );
};

export default AllCustomers;
