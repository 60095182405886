import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { getAxios, putAxios } from '../../util';
import { CardHead, Selector } from '../common/CardContent'
import { Loader } from '../common/Loader'
import { useAuth0 } from '../../react-auth0-wrapper';
import { Grid, makeStyles } from '@material-ui/core';
import options from '../../util/options'
import { ShadowCard } from '../common/Containers';
import { SaveEditIconButton } from '../common/Buttons';


const CustomerInformation = ({ companyId }) => {
  const classes = useStyles()
  const { accessToken } = useAuth0();

  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState({})
  const [isEditing, setIsEditing] = useState(false)

  const setEditing = () => setIsEditing(true)
  const handleChange = (value, key) => setData({ ...data, [key]: value });


  const handleSave = async () => {
    putAxios(`customer/company/${ companyId }`, data, accessToken)

    setIsEditing(false)
  }

  useEffect(() => {
    const fetchData = async () => {
        const data = await getAxios(`customer/company/${ companyId }`, {}, accessToken)
    
        setLoading(false)
        if (data) setData(data)
    }

    fetchData()
  }, [accessToken, companyId])

  return (
    <ShadowCard>
      {
        isLoading
          ? <Loader />
          : (<>
            <CardHead
              headingText="Customer"
              action={
                <SaveEditIconButton
                  edit={ isEditing }
                  onClick={ isEditing ? handleSave : setEditing }
                />
              }
            />
            <Grid container spacing={1} className={classes.form}>
              <Grid item xs={12}>
                <Selector
                  label="Customer Type"
                  edit={ isEditing }
                  required
                  value={ data.customerType }
                  name="customerType"
                  options={ options.customerTypes }
                  editHook={handleChange}
                />
              </Grid>
            </Grid>
          </>)
      }
    </ShadowCard>
  )
}

CustomerInformation.propTypes = {
  companyId: PropTypes.string,
}

const useStyles = makeStyles({
  form: {
    marginTop: 15,
  },
});

export default CustomerInformation