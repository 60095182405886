import { Card } from '@material-ui/core';
import React from 'react';
import { styled } from '@material-ui/styles';

// Creates a styled variant of Material UI Card
const ShadowCard = styled(({ noPadding, noTopPadding, ...other }) => (
  <Card {...other} />
))({
  backgroundColor: '#fff',
  border: 'none',
  borderRadius: '.35rem',
  boxShadow:
    '0 0.46875rem 2.1875rem rgba(90,97,105,.1), 0 0.9375rem 1.40625rem rgba(90,97,105,.1), 0 0.25rem 0.53125rem rgba(90,97,105,.12), 0 0.125rem 0.1875rem rgba(90,97,105,.1)',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  padding: props =>
    props.noTopPadding ? '0px 20px 20px 20px' : props.noPadding ? 0 : '20px',
  width: '100%',
});

export default ShadowCard;
