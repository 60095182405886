import { Add, Delete } from '@material-ui/icons';
import { Fab, Grid, Typography, makeStyles } from '@material-ui/core';
import { FieldArray, connect } from 'formik';
import React, { useEffect } from 'react';

import { ProductInfoForm } from '.';
import { ShadowCard } from '../../components/common/Containers';

const ProductInfoView = ({
  loadCargo,
  isForager,
  formik,
  isHazmat,
  equipmentType,
  loadId,
}) => {
  const classes = useStyles();

  const { setFieldValue } = formik;

  let products;

  if (loadCargo) {
    products = loadCargo;
  } else {
    products = formik.values.products;
  }

  useEffect(() => {
    // Calculates total value of products
    const totalValue = products.reduce((totalValue, product) => {
      return totalValue + product.value;
    }, 0);
    products.forEach((product, idx) => {
      setFieldValue(`products.${idx}.loadValue`, totalValue);
    });
  }, [products, setFieldValue]);

  return (
    <Grid container>
      <FieldArray
        name="products"
        render={arrayHelpers => (
          <>
            {!!products ? (
              <Grid container>
                {products.map((product, index) => (
                  <Grid item xs={12} className={classes.cardSpacer} key={index}>
                    <ProductInfoForm
                      loadId={loadId}
                      index={index}
                      isHazmat={isHazmat}
                      equipmentType={equipmentType}
                      productValues={product}
                    />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <ShadowCard>
                <Typography>No Products found.</Typography>
              </ShadowCard>
            )}
            <Grid container>
              <Grid item xs={12}>
                {products.length < 3 && !loadId ? (
                  <Fab
                    variant="extended"
                    size="small"
                    color="primary"
                    onClick={() =>
                      arrayHelpers.push({
                        canStack: false,
                        currencyId: 2,
                        cargoCommodityId: '',
                        value: 0,
                        quantity: '',
                        weight: '',
                        description: '',
                        hazmat: false,
                        hazmatClassId: null,
                        hazmatUnId: null,
                        packagingId: '',
                        packingGroupId: null,
                        reefer: false,
                        height: null,
                        width: null,
                        length: null,
                        hiTemp: null,
                        loTemp: null,
                        reeferTypeId: null,
                      })
                    }
                    aria-label="Add Product Information"
                    className={classes.addButtonSpacer}
                  >
                    <Add />
                    Product
                  </Fab>
                ) : (
                  ''
                )}
                {products.length > 1 && (
                  <Fab
                    variant="extended"
                    size="small"
                    color="primary"
                    onClick={() => arrayHelpers.pop()}
                    aria-label="Delete Product Info"
                    className={classes.addButtonSpacer}
                  >
                    <Delete />
                    Product
                  </Fab>
                )}
              </Grid>
            </Grid>
          </>
        )}
      />
    </Grid>
  );
};

const useStyles = makeStyles({
  addButtonSpacer: {
    marginTop: '10px',
    marginRight: 8,
    float: 'right',
  },
  cardSpacer: {
    marginBottom: '25px',
  },
});

export default connect(ProductInfoView);
