import { Hours } from '../common/Hours';
import PropTypes from 'prop-types';
import React from 'react';

const FacilityHours = ({ companyId }) => {
  return (
    <Hours
      params={{ companyId }}
      title="Facility Hours"
      getPath="facility/hours"
      putPath="facility/hours"
    />
  );
};

FacilityHours.propTypes = {
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default FacilityHours;
