import { Address, CardHead, Phone, Text } from '../common/CardContent';
import { Button, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { FacilityProfile } from '../../views/FacilitySystem';
import { ShadowCard } from '../common/Containers';
import { catchErrors } from '../../util';
import { postAxios } from '../../util/axiosHelper';
import { useAuth0 } from '../../react-auth0-wrapper';

const AddNewFacility = () => {
  const { accessToken } = useAuth0();
  const [facilityId, setFacilityId] = useState(null);

  //Validation
  const [legalNameValid, setLegalNameValid] = useState(true);
  const [addressValid, setAddressValid] = useState(true);
  const [phoneValid, setPhoneValid] = useState(true);

  //Error setter
  const [error, setError] = useState({});

  //State
  const [newFacility, setNewFacility] = useState({
    legalName: '',
    dba: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    postalCode: '',
    phone: '',
    phoneExt: '',
  });

  const handleCreate = () => {
    catchErrors([legalNameValid, phoneValid, addressValid])
      .then(() => {
        setError({});
        createFacility();
      })
      .catch(errors => {
        setError(errors);
      });
  };

  const createFacility = () => {
    postAxios('facility/add-new', { ...newFacility }, accessToken).then(
      data => {
        setFacilityId(data.newFacilityId.facilityId);
      }
    );
  };

  if (facilityId) {
    return (
      <Switch>
        <Redirect
          push
          component={FacilityProfile}
          to={`/facilities/profile/id/${facilityId}`}
        />
      </Switch>
    );
  }

  return (
    <ShadowCard>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CardHead headingText="New Facility" />
        </Grid>
        <Grid item xs={12}>
          <Text
            required
            editHook={(text, name) =>
              setNewFacility({ ...newFacility, [name]: text })
            }
            value={newFacility.legalName}
            label="Legal Name"
            edit
            name="legalName"
            setValid={setLegalNameValid}
            error={error}
            errorText="Please enter a value"
          />
        </Grid>
        <Grid item xs={12}>
          <Text
            editHook={(text, name) =>
              setNewFacility({ ...newFacility, [name]: text })
            }
            value={newFacility.dba}
            label="DBA"
            edit
            name="dba"
          />
        </Grid>
        <Grid item xs={12}>
          <Address
            label="Physical Address"
            edit
            addressName="address"
            address2Name="address2"
            cityName="city"
            stateName="state"
            postalCodeName="postalCode"
            address={newFacility.address}
            address2={newFacility.address2}
            city={newFacility.city}
            state={newFacility.state}
            postalCode={newFacility.postalCode}
            editHook={location =>
              setNewFacility({ ...newFacility, ...location })
            }
            required
            setValid={setAddressValid}
            error={error}
            errorText="Please enter a valid address"
          />
        </Grid>
        <Grid item xs={12}>
          <Phone
            number={newFacility.phone}
            edit
            name="phone"
            canSms
            label="Phone"
            editHook={(phone, name) =>
              setNewFacility({ ...newFacility, [name]: phone })
            }
            required
            setValid={setPhoneValid}
            error={error}
            errorText="Please enter a valid phone number"
          />
          <Text
            value={newFacility.phoneExt}
            edit
            name="phoneExt"
            label="Ext."
            editHook={(text, name) =>
              setNewFacility({ ...newFacility, [name]: text })
            }
            type="number"
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="flex-end" spacing={2}>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                size="large"
                onClick={handleCreate}
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ShadowCard>
  );
};

export default AddNewFacility;
