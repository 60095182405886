import { Grid, Typography, makeStyles } from '@material-ui/core';

import DateTimePicker from 'react-datetime-picker';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment-timezone/builds/moment-timezone-with-data';

const FormDate = ({ name, canEdit }) => {
  const classes = useStyles();
  return (
    <Grid container justify="flex-end">
      <Field
        name={name}
        render={({ field, form }) => {
          const value = field.value ? moment(field.value).format() : '';
          return canEdit ? (
            <>
              <DateTimePicker
                value={value}
                disableClock
                disableCalendar
                className={classes.dateTime}
                format="MM-d-y HH:mm"
                onChange={dateTime => {
                  const formattedDate = dateTime && moment(dateTime).format();
                  form.setFieldValue(name, formattedDate ? formattedDate : '');
                }}
              />
            </>
          ) : (
            <>
              <Typography>{value ? value : '-'}</Typography>
            </>
          );
        }}
      />
    </Grid>
  );
};

FormDate.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  canEdit: PropTypes.bool,
};

const useStyles = makeStyles({
  dateTime: {
    flex: 1,
    fontSize: '1rem',
    marginRight: 2,
    error: {
      color: 'red',
    },
    '& .react-datetime-picker__wrapper': {
      border: 'none',
      borderBottom: '1px solid black',
      height: 24,
      width: '100%',
      '& button': {
        padding: 0,
      },
    },
    '& .react-datetime-picker__clear-button': {
      backgroundColor: 'white',
      marginLeft: 2,
      outline: 'none',
    },
    '& .react-datetime-picker__inputGroup': {
      justifyContent: 'flex-end',
    },
  },
});

export default FormDate;
