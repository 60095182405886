import React, { useCallback, useEffect } from 'react';
import { ModalContainer } from '../common/Modals';
import { Typeahead } from '../common/Typeahead';
import { Loader } from '../common/Loader';

import { Currency, CardItem } from '../common/CardContent';
import { equipmentTypes } from '../../util/options';
import { FormSelect } from '../common/Forms';
import { Grid, Button, FormHelperText, Typography } from '@material-ui/core';
import { getAxios, postAxios, putAxios } from '../../util';
import { useAuth0 } from '../../react-auth0-wrapper';
import { Form, withFormik, ErrorMessage } from 'formik';
import { mexBorderCrossings, crossingModeOptions } from '../../util/options';

const ContractedRatesModal = props => {
  const {
    canEdit,
    handleChange,
    handleModal,
    isModalOpen,
    setFieldValue,
    status,
    values,
  } = props;

  const { accessToken } = useAuth0();

  const renderRateSuccessMessage = () => (
    <Typography variant="h6" color="secondary" align="center">
      Rate Successfully Saved!
    </Typography>
  );

  const renderErrorMessage = name => (
    <ErrorMessage name={name}>
      {msg => <FormHelperText error={true}>{msg}</FormHelperText>}
    </ErrorMessage>
  );

  const setLocationCity = async (locationValue, type) => {
    const { city, stateLongName: state, name } = locationValue;

    const params = {
      city,
      state,
      name,
    };

    const isExistingCity = await getAxios(
      'geo/city/info',
      { params },
      accessToken
    );

    if (!isExistingCity.cityId) {
      await getAxios(
        'geo/geocode/perm',
        { params: { city, name } },
        accessToken
      ).then(
        data =>
          data && postAxios('geo/create/city', { city: data }, accessToken)
      );
    }

    setFieldValue(
      `searchString${type}`,
      `${locationValue.city}, ${locationValue.stateLongName}`
    );
    setFieldValue(`${type}City`, locationValue.city);
    setFieldValue(`${type}State`, locationValue.stateLongName);
  };

  const setAccessToFormik = useCallback(() => {
    setFieldValue('accessToken', accessToken);
  }, [accessToken]); // eslint-disable-line

  useEffect(() => {
    setAccessToFormik();
  }, [setAccessToFormik]);
  return (
    <ModalContainer
      open={isModalOpen}
      modalWidth={850}
      handleClose={handleModal}
    >
      {!!status && status.rateCreated ? (
        renderRateSuccessMessage()
      ) : (
        <Form>
          <Grid container spacing={3}>
            <Grid item sm={6}>
              <CardItem
                label="Origin"
                content={
                  <>
                    <Typeahead
                      noRecordsFoundMsg="No cities found!"
                      disabled={!canEdit}
                      onChange={values => setLocationCity(values, 'origin')}
                      urlString="geo/autocomplete/city"
                      value={
                        values.searchStringorigin ||
                        `${values.originCity} ${values.originState}`
                      }
                    />
                  </>
                }
              />
            </Grid>
            <Grid item sm={6}>
              <CardItem
                label="Destination"
                content={
                  <>
                    <Typeahead
                      noRecordsFoundMsg="No cities found!"
                      disabled={!canEdit}
                      onChange={values => {
                        setLocationCity(values, 'destination');
                      }}
                      urlString="geo/autocomplete/city"
                      value={
                        values.searchStringdestination ||
                        `${values.destinationCity} ${values.destinationState}`
                      }
                    />
                  </>
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item sm={6}>
              <CardItem
                label="Crossing City"
                content={
                  <>
                    {values.mexBorderCrossingId || canEdit ? (
                      <FormSelect
                        canEdit={canEdit}
                        name="mexBorderCrossingId"
                        options={mexBorderCrossings}
                        value={values && values.mexBorderCrossingId}
                        onChange={handleChange(`mexBorderCrossingId`)}
                      />
                    ) : (
                      '---'
                    )}
                  </>
                }
              />
            </Grid>
            <Grid item sm={6}>
              <CardItem
                label="Equip Type"
                content={
                  <>
                    <FormSelect
                      canEdit={canEdit}
                      name="equipmentType"
                      options={equipmentTypes}
                      value={values && values.equipmentType}
                      onChange={handleChange(`equipmentType`)}
                    />
                  </>
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item sm={6}>
              <CardItem
                label="Crossing Mode"
                content={
                  <>
                    <FormSelect
                      canEdit={canEdit}
                      name="crossingModeId"
                      options={crossingModeOptions}
                      value={values && values.crossingModeId}
                      onChange={handleChange(`crossingModeId`)}
                    />
                  </>
                }
              />
            </Grid>
            <Grid item sm={6}>
              <CardItem
                label="Direct Rate"
                content={
                  <>
                    <Currency
                      value={values.directRate}
                      edit={
                        parseInt(values.crossingModeId) === 2 ? true : false
                      }
                      editHook={value => setFieldValue(`directRate`, value)}
                      name="directRate"
                    />
                    {renderErrorMessage('directRate')}
                  </>
                }
              />
              <CardItem
                label="Transload Rate"
                content={
                  <>
                    <Currency
                      value={values.transloadRate}
                      edit={
                        parseInt(values.crossingModeId) === 1 ? true : false
                      }
                      editHook={value => setFieldValue(`transloadRate`, value)}
                      name="transloadRate"
                    />
                    {renderErrorMessage('transloadRate')}
                  </>
                }
              />
            </Grid>
          </Grid>

          <Grid container justify="flex-end">
            <Grid item xs={12}>
              {!!status && status.isLoading ? (
                <Loader />
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  type="submit"
                >
                  {canEdit ? 'Add' : 'Save'}
                </Button>
              )}
            </Grid>
          </Grid>
        </Form>
      )}
    </ModalContainer>
  );
};

export default withFormik({
  mapPropsToValues: props => {
    const { currentContractedRate } = props;
    return {
      mexBorderCrossingId: currentContractedRate.crossingBorderId || null,
      crossingModeId: currentContractedRate.crossingModeId || null,
      equipmentType: currentContractedRate.eq_type_id || '',
      accessToken: '',
      originCity: currentContractedRate.originCity || '',
      originState: currentContractedRate.originState || '',
      destinationCity: currentContractedRate.deliveryCity || '',
      destinationState: currentContractedRate.deliveryState || '',
      searchStringorigin: '',
      searchStringdestination: '',
      directRate: currentContractedRate.directRate || null,
      transloadRate: currentContractedRate.transloadRate || null,
      customerId: props.customerId,
      canEdit: props.canEdit,
      contractedRateId: currentContractedRate.contractedRateId,
      getContractedRates: props.getContractedRates,
    };
  },
  handleSubmit: (values, { setStatus }) => {
    const {
      crossingModeId,
      equipmentType,
      mexBorderCrossingId,
      directRate,
      transloadRate,
      destinationCity,
      destinationState,
      originCity,
      originState,
      customerId,
      canEdit,
      contractedRateId,
      getContractedRates,
    } = values;

    setStatus({ isLoading: true });

    if (!canEdit) {
      putAxios(
        'customer/add-contracted-rate',
        {
          directRate,
          transloadRate,
          contractedRateId,
        },
        values.accessToken
      ).then(() => {
        setStatus({ rateCreated: true, isLoading: false });
      });
    } else {
      postAxios(
        'customer/add-contracted-rate',
        {
          crossingModeId,
          customerId,
          destinationCity,
          destinationState,
          equipmentType,
          mexBorderCrossingId,
          originCity,
          originState,
          directRate,
          transloadRate,
        },
        values.accessToken
      ).then(() => {
        setStatus({ rateCreated: true, isLoading: false });
        getContractedRates();
      });
    }
  },
})(ContractedRatesModal);
