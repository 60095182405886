import { CardContent, FormHelperText, Grid } from '@material-ui/core';
import {
  CardHead,
  CardItem,
  DateTime,
  Selector,
  Text,
} from '../common/CardContent';
import { ErrorMessage, connect } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { arrayToSelectorObject, getAxios, putAxios } from '../../util';
import {
  crossingModeOptions,
  mexBorderCrossings,
  mexBorderCrossingsCoordinates,
} from '../../util/options';

import { FormField } from '../common/Forms';
import { Loader } from '../common/Loader';
import { PrivateComponent } from '../common/Privatize';
import { SaveEditIconButton } from '../common/Buttons';
import { ShadowCard } from '../common/Containers';
import { debounce } from 'lodash';
import { useAuth0 } from '../../react-auth0-wrapper';

const BorderOperations = ({
  borderOperations,
  borderOperationsRoute,
  canEdit: edit,
  charges,
  crossingCityId,
  loadId,
  crossingModeId,
  formik,
  formikType,
  refetchStatus,
  setRefetchStatus,
  isForager,
  verifiedCustomerId,
}) => {
  const { accessToken } = useAuth0();

  borderOperations = borderOperations || {};

  const values = formik.values[formikType] || borderOperations;
  const [canEdit, setCanEdit] = useState(edit);

  const [canCustomsBrokers, setCanCustomsBrokers] = useState([]);
  const [canCustomsBrokersSelector, setCanCustomsBrokersSelector] = useState();
  const [mexCustomsBrokers, setMexCustomsBrokers] = useState([]);
  const [mexCustomsBrokersSelector, setMexCustomsBrokersSelector] = useState();
  const [usaCustomsBrokers, setUsaCustomsBrokers] = useState([]);
  const [usaCustomsBrokersSelector, setUsaCustomsBrokersSelector] = useState();
  // when Forager selects a new customs broker on edit
  const [newCanCustomsBroker, setNewCanCustomsBroker] = useState(null);
  const [newMexCustomsBroker, setNewMexCustomsBroker] = useState(null);
  const [newUsaCustomsBroker, setNewUsaCustomsBroker] = useState(null);

  const [loading, setLoading] = useState(true);
  const [newBorderArrival, setNewBorderArrival] = useState(null);
  const [newBorderCrossed, setNewBorderCrossed] = useState(null);
  const { borderArrival, borderCrossed } = borderOperations;

  const {
    touched,
    errors,
    handleChange,
    setFieldTouched,
    setFieldValue,
  } = formik;

  const isUSCustomsBroker = borderOperationsRoute === 'CANUSA';
  const isCANCustomsBroker =
    borderOperationsRoute === 'USACAN' || borderOperationsRoute === 'MEXCAN';

  const onSave = () => {
    putAxios(
      'load/border-operations',
      {
        loadId,
        newBorderCrossed,
        newBorderArrival,
        borderOperationsRoute,
        newCanCustomsBroker,
        newMexCustomsBroker,
        newUsaCustomsBroker,
      },
      accessToken
    ).then(() => {
      setRefetchStatus(!refetchStatus);
    });
  };

  const handleCanEdit = () => {
    setCanEdit(!canEdit);
    canEdit && onSave();
  };

  const onSyncChange = e => debounce.bind(null, handleChange(e), 2000);

  const handleFieldChange = (fieldName, e) => {
    if (e.persist) {
      e.persist();
    }
    setFieldTouched(fieldName, true, false);
    return onSyncChange(e);
  };
  useEffect(() => {
    if (values) {
      setLoading(false);
    }
  }, [values]);

  const getCustomsBrokers = useCallback(
    (country, setCallback) => {
      getAxios(
        'customer/preferred-customs-brokers',
        { params: { country, verifiedCustomerId } },
        accessToken
      ).then(customsBrokers => {
        setCallback(customsBrokers || []);
      });
    },
    [accessToken, verifiedCustomerId]
  );

  const [tz, setTz] = useState();

  useEffect(() => {
    const getTimezone = (lat, lng, timestamp) => {
      return getAxios(
        'geo/timezone',
        { params: { lat, lng, timestamp } },
        accessToken
      );
    };

    if (borderOperations.mexBorderCrossingId) {
      const crossingCoords =
        mexBorderCrossingsCoordinates[borderOperations.mexBorderCrossingId - 1]
          .coordinates;
      getTimezone(crossingCoords[1], crossingCoords[0], Date.now()).then(
        data => {
          setTz(data);
        }
      );
    }
  }, [accessToken, borderOperations.mexBorderCrossingId, tz]);

  useEffect(() => {
    setNewBorderArrival(borderArrival);
  }, [borderArrival]);

  useEffect(() => {
    setNewBorderCrossed(borderCrossed);
  }, [borderCrossed]);

  useEffect(() => {
    getCustomsBrokers('US', setUsaCustomsBrokers);
    getCustomsBrokers('MX', setMexCustomsBrokers);
    getCustomsBrokers('CA', setCanCustomsBrokers);
  }, [getCustomsBrokers]);

  useEffect(() => {
    const usaBrokersOptions = arrayToSelectorObject(
      usaCustomsBrokers,
      'customsBrokerCompanyId'
    );
    setUsaCustomsBrokersSelector(usaBrokersOptions);
  }, [usaCustomsBrokers]);

  useEffect(() => {
    const mxBrokersOptions = arrayToSelectorObject(
      mexCustomsBrokers,
      'customsBrokerCompanyId'
    );
    setMexCustomsBrokersSelector(mxBrokersOptions);
  }, [mexCustomsBrokers]);

  useEffect(() => {
    const caBrokersOptions = arrayToSelectorObject(
      canCustomsBrokers,
      'customsBrokerCompanyId'
    );
    setCanCustomsBrokersSelector(caBrokersOptions);
  }, [canCustomsBrokers]);

  useEffect(() => {
    setNewCanCustomsBroker(borderOperations.canCustomsBrokerId);
    setNewMexCustomsBroker(borderOperations.mexCustomsBrokerId);
    setNewUsaCustomsBroker(borderOperations.usaCustomsBrokerId);
  }, [
    borderOperations.canCustomsBrokerId,
    borderOperations.mexCustomsBrokerId,
    borderOperations.usaCustomsBrokerId,
  ]);

  const renderErrorMessage = name => (
    <ErrorMessage name={name}>
      {msg => <FormHelperText error={true}>{msg}</FormHelperText>}
    </ErrorMessage>
  );

  const PrivateEditIcon = (
    <PrivateComponent reqScopes={['stop-details:edit']}>
      <SaveEditIconButton edit={canEdit} onClick={handleCanEdit} />
    </PrivateComponent>
  );

  const PrivateArrivalCrossedTimes = borderOperations.mexBorderCrossingId && (
    <>
      <PrivateComponent reqScopes={['forager:read']}>
        <DateTime
          setValid={() => ''}
          edit={canEdit}
          label="Border Arrival"
          name="borderArrival"
          dateTime={newBorderArrival}
          editHook={date => setNewBorderArrival(date)}
          timeZone={tz}
        />

        <DateTime
          setValid={() => ''}
          edit={canEdit}
          label="Load Crossed"
          name="loadCrossed"
          dateTime={newBorderCrossed}
          editHook={date => setNewBorderCrossed(date)}
          timeZone={tz}
        />
      </PrivateComponent>
      {!canEdit && (
        <PrivateComponent reqScopes={['customer-portal:read']}>
          <DateTime
            setValid={() => ''}
            label="Border Arrival"
            name="borderArrival"
            dateTime={borderArrival}
            editHook={() => console.log('...')}
            timeZone={tz}
          />

          <DateTime
            setValid={() => ''}
            label="Load Crossed"
            name="loadCrossed"
            dateTime={borderCrossed}
            editHook={() => console.log('...')}
            timeZone={tz}
          />
        </PrivateComponent>
      )}
    </>
  );
  const usaCustomsBrokerElement =
    !loadId || (isForager && canEdit) ? (
      <>
        <Selector
          edit={canEdit}
          name={'usaCustomsBrokerId'}
          label="US Customs Broker"
          options={usaCustomsBrokersSelector}
          value={newUsaCustomsBroker || values.usaCustomsBrokerId}
          editHook={value =>
            isForager
              ? setNewUsaCustomsBroker(value)
              : setFieldValue(`${formikType}.["usaCustomsBrokerId"]`, value)
          }
        />
        {renderErrorMessage('borderOperations.usaCustomsBrokerId')}
      </>
    ) : (
      <Text
        label="US Customs Broker"
        value={
          // borderOperations is passed down from parent and does not 
          // reflect changes made to state, using newUsaCustomsBroker
          // to track changes
          (usaCustomsBrokersSelector &&
            usaCustomsBrokersSelector[newUsaCustomsBroker]) ||
          borderOperations.usaCustomsBrokerDBAName ||
          borderOperations.usaCustomsBrokerLegalName
        }
      />
    );

  const mexCustomsBrokerElement =
    !loadId || (isForager && canEdit) ? (
      <>
        <Selector
          edit={canEdit}
          name="mexCustomsBrokerId"
          label="MX Customs Broker"
          options={mexCustomsBrokersSelector}
          value={newMexCustomsBroker || values.mexCustomsBrokerId}
          editHook={value =>
            isForager
              ? setNewMexCustomsBroker(value)
              : setFieldValue(`${formikType}["mexCustomsBrokerId"]`, value)
          }
        />
        {renderErrorMessage('borderOperations.mexCustomsBrokerId')}
      </>
    ) : (
      <Text
        label="MX Customs Broker"
        value={
          (mexCustomsBrokersSelector &&
            mexCustomsBrokersSelector[newMexCustomsBroker]) ||
          borderOperations.mexCustomsBrokerDBAName ||
          borderOperations.mexCustomsBrokerLegalName
        }
      />
    );

  const canCustomsBrokerElement =
    !loadId || (isForager && canEdit) ? (
      <>
        <Selector
          edit={canEdit}
          name="canCustomsBrokerId"
          label="CAN Customs Broker"
          options={canCustomsBrokersSelector}
          value={newCanCustomsBroker || values.canCustomsBrokerId}
          editHook={value =>
            isForager
              ? setNewCanCustomsBroker(value)
              : setFieldValue(`${formikType}["canCustomsBrokerId"]`, value)
          }
        />
        {renderErrorMessage('borderOperations.canCustomsBrokerId')}
      </>
    ) : (
      <Text
        label="CAN Customs Broker"
        value={
          (canCustomsBrokersSelector &&
            canCustomsBrokersSelector[newCanCustomsBroker]) ||
          borderOperations.canCustomsBrokerDBAName ||
          borderOperations.canCustomsBrokerLegalName
        }
      />
    );

  const mexCard = (
    <>
      <Grid item xs={6}>
        {usaCustomsBrokerElement}
      </Grid>
      <Grid item xs={6}>
        {mexCustomsBrokerElement}
      </Grid>
      <Grid item xs={6}>
        <Selector
          name="mexBorderCrossingId"
          label="Crossing City"
          options={mexBorderCrossings}
          value={(values && values.mexBorderCrossingId) || crossingCityId}
        />
      </Grid>
      <Grid item xs={6}>
        <Selector
          name="crossingModeId"
          label="Type"
          options={crossingModeOptions}
          value={(values && values.crossingModeId) || crossingModeId}
        />
      </Grid>
      <Grid item xs={6}>
        <Text
          label="Forager Handles Crossing"
          value={
            charges && charges[1]
              ? charges[1].per > 0
                ? 'Yes'
                : 'No'
              : borderOperations.foragerHandleCrossing
              ? 'Yes'
              : 'No'
          }
        />
      </Grid>
    </>
  );

  const canCard = (
    <>
      {(isUSCustomsBroker || isCANCustomsBroker) && (
        <Grid item xs={6}>
          {isUSCustomsBroker && usaCustomsBrokerElement}
          {isCANCustomsBroker && canCustomsBrokerElement}
        </Grid>
      )}
      {loadId && (
        <>
          <Grid item xs={6}>
            <CardItem
              label="PAPS #"
              content={
                <FormField
                  name="borderOperations.papsNumber"
                  helperText={
                    touched.borderOperations &&
                    touched.borderOperations.papsNumber
                      ? errors.borderOperations &&
                        errors.borderOperations.papsNumber
                      : ''
                  }
                  error={
                    touched.borderOperations &&
                    touched.borderOperations.papsNumber &&
                    Boolean(
                      errors.borderOperations &&
                        errors.borderOperations.papsNumber
                    )
                  }
                  value={
                    values &&
                    values.borderOperations &&
                    values.borderOperations.papsNumber
                  }
                  onChange={handleFieldChange.bind(
                    null,
                    'borderOperations.papsNumber'
                  )}
                  canEdit={canEdit}
                  editHook={value =>
                    setFieldValue(
                      `${formikType}["borderOperations.papsNumber"]`,
                      value
                    )
                  }
                />
              }
            />
          </Grid>
          <Grid item xs={6}>
            <CardItem
              label="PARS #"
              content={
                <FormField
                  name="borderOperations.parsNumber"
                  helperText={
                    touched.borderOperations &&
                    touched.borderOperations.parsNumber
                      ? errors.borderOperations &&
                        errors.borderOperations.parsNumber
                      : ''
                  }
                  error={
                    touched.borderOperations &&
                    touched.borderOperations.parsNumber &&
                    Boolean(
                      errors.borderOperations &&
                        errors.borderOperations.parsNumber
                    )
                  }
                  value={
                    values &&
                    values.borderOperations &&
                    values.borderOperations.parsNumber
                  }
                  onChange={handleFieldChange.bind(
                    null,
                    'borderOperations.parsNumber'
                  )}
                  canEdit={canEdit}
                  editHook={value =>
                    setFieldValue(
                      `${formikType}["borderOperations.parsNumber"]`,
                      value
                    )
                  }
                />
              }
            />
          </Grid>
        </>
      )}
    </>
  );

  return (
    <>
      {!loading ? (
        <ShadowCard>
          <Grid item xs={12}>
            <CardHead
              headingText="Border Operations"
              action={PrivateEditIcon}
            />
          </Grid>
          <CardContent>
            <Grid container spacing={2}>
              {borderOperationsRoute === 'MEXUSA' ||
              borderOperationsRoute === 'USAMEX'
                ? mexCard
                : null}
              {borderOperationsRoute === 'USACAN' ||
              borderOperationsRoute === 'CANUSA'
                ? canCard
                : null}
              {borderOperationsRoute === 'MEXCAN' ||
              borderOperationsRoute === 'CANMEX' ? (
                <>
                  {mexCard} {canCard}
                </>
              ) : null}
              <Grid item xs={6}>
                {PrivateArrivalCrossedTimes}
              </Grid>
            </Grid>
          </CardContent>
        </ShadowCard>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default connect(BorderOperations);
