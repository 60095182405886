import React from 'react';
import { Grid } from '@material-ui/core';
import {
  FacilityProfileHeader,
  FacilityMeta,
  FacilityHours,
} from '../../components/FacilitySystem';
import { MainContact } from '../../components/common/Contacts';

const MainView = ({ companyId }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FacilityProfileHeader companyId={companyId} />
      </Grid>
      <Grid item xs={6}>
        <FacilityMeta companyId={companyId} />
      </Grid>
      <Grid item xs={6}>
        <FacilityHours companyId={companyId} />
      </Grid>
      <Grid item xs={6}>
        <MainContact companyId={companyId} getPath="company/main-contact" />
      </Grid>
    </Grid>
  );
};

export default MainView;
