import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';
import { Email, Phone } from '../../common/CardContent';

import React from 'react';

const AccountRepItem = ({
  photoUrl,
  firstName,
  lastName,
  email,
  officePhone,
  title,
}) => {
  return (
    <List data-testid="rep">
      <ListItem disableGutters={true}>
        <ListItemAvatar>
          <Avatar alt={firstName} src={photoUrl} />
        </ListItemAvatar>
        <ListItemText
          primaryTypographyProps={{ variant: 'h5', gutterBottom: false }}
          primary={firstName + ' ' + lastName}
          secondary={title}
        />
      </ListItem>
      {officePhone && (
        <Phone number={officePhone} label="Office Phone" name="phone" />
      )}
      {email && <Email label="Email" email={email} />}
      <Divider />
    </List>
  );
};

export default AccountRepItem;
