import React from 'react'
import { Link } from 'react-router-dom'
import { RemoveRedEye } from "@material-ui/icons"

const EyeBallLinkCell = (baseUrl, rowDataId) => {
  return {
    cellStyle: { borderRight: 'none', paddingTop: '15px' },
    render: rowData => (
      <Link to={`${baseUrl}/${rowData[rowDataId]}`} >
        <RemoveRedEye style={{ color: 'rgba(0,0,0,0.54)' }} />
      </Link>)
  }
}

export default EyeBallLinkCell