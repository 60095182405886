import { Grid, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';

import { CardItem } from './';
import DateTimePicker from 'react-datetime-picker';
import momentTz from 'moment-timezone/builds/moment-timezone-with-data';

const DateTime = ({
  label,
  edit,
  editHook,
  name,
  dateTime,
  timeZone,
  required,
  setValid,
  error,
  errorText,
  enableCalendar,
  onCalendarChange,
  testId,
  variant,
}) => {
  const classes = useStyles();
  // If this component is used as display only, set setValid to dummy function

  setValid = setValid ? setValid : () => '';
  errorText = errorText ? errorText : 'Please enter a valid Date & Time';
  useEffect(() => {
    if (dateTime === null && required) {
      setValid({ [name]: false });
    } else {
      setValid({ [name]: true });
    }
  }, [dateTime, name, required, setValid]);

  const jsDate = dateTime === null ? dateTime : new Date(dateTime);

  const content = edit ? (
    <Grid container justify="flex-end" data-testid={testId}>
      <DateTimePicker
        value={jsDate}
        disableClock
        data-testid={name}
        required={required}
        disableCalendar={false}
        className={classes.dateTime}
        format="MM-d-yy HH:mm"
        onChange={dateTime => editHook(dateTime && dateTime, name)}
      />
    </Grid>
  ) : (
    <Typography variant={variant}>
      {dateTime
        ? `${momentTz(dateTime).format('MM-DD-YY HH:mm')} ${momentTz
            .tz(dateTime, timeZone)
            .format('z')}`
        : ' - '}
    </Typography>
  );

  return (
    <CardItem
      label={label}
      name={name}
      error={error}
      errorText={errorText}
      content={content}
    />
  );
};

const useStyles = makeStyles({
  dateTime: {
    flex: 1,
    fontSize: '1rem',
    marginRight: 2,
    '& .react-datetime-picker__wrapper': {
      border: 'none',
      borderBottom: '1px solid black',
      height: 24,
      width: '100%',
      '& button': {
        padding: 0,
      },
    },
    '& .react-datetime-picker__clear-button': {
      backgroundColor: 'white',
      marginLeft: 2,
      outline: 'none',
    },
    '& .react-datetime-picker__inputGroup': {
      justifyContent: 'flex-end',
    },
  },
});

export default DateTime;
