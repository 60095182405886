import {
  Card,
  CircularProgress,
  IconButton,
  Typography,
} from '@material-ui/core';
import MaterialTable, { MTableCell } from 'material-table';

import { DefaultTableIcons } from './';
import React from 'react';
import { RemoveRedEye } from '@material-ui/icons';
import { styled } from '@material-ui/styles';

const TableWrapper = styled(Card)({
  backgroundColor: '#fff',
  border: 'none',
  boxShadow: 'none',
  borderRadius: 0,
});

const handlePageChange = () => {
  // Waiting on page to load before taking the user to top of page
  setTimeout(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, 100);
};

const FilterTable = ({
  columns,
  data,
  title,
  showActions,
  filtering,
  dashIconClick,
  noToolbar,
  noSearch,
  noFooter,
}) => {
  const loadingContent = <CircularProgress size={50} />;
  const tableContent = (
    <MaterialTable
      localization={{
        header: {
          actions: '',
        },
        body: {
          emptyDataSourceMessage: 'No Data Found',
        },
      }}
      actions={
        showActions
          ? [
              {
                onClick: (event, rowData) => {
                  dashIconClick(rowData);
                },
              },
            ]
          : null
      }
      onChangeRowsPerPage={handlePageChange}
      options={{
        paging: !noFooter,
        toolbar: !noToolbar,
        search: !noSearch,
        filtering,
        pageSize: 15,
        pageSizeOptions: [15, 25, 50, 100],
        headerStyle: {
          padding: '4px',
          // whiteSpace: 'nowrap',
          textAlign: 'center',
          paddingLeft: 26,
        },
        filterCellStyle: {
          padding: '4px',
        },
      }}
      icons={DefaultTableIcons}
      columns={columns}
      data={data}
      title={
        <Typography variant="h2" component="p">
          {title}
        </Typography>
      }
      components={{
        Action: props => (
          <IconButton
            size="small"
            onClick={event => props.action.onClick(event, props.data)}
          >
            <RemoveRedEye />
          </IconButton>
        ),
        Container: props => <TableWrapper {...props} />,
        // Over rides Cells Generated with these in order to create border effect
        Cell: props => (
          <MTableCell
            {...props}
            style={{
              padding: '8px',
              fontSize: '12px',
              textAlign: 'center',
              borderRight: '1px solid rgb(224, 224, 224)',
            }}
          />
        ),
      }}
    />
  );

  return data === null ? loadingContent : tableContent;
};

export default FilterTable;
