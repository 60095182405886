import { Grid } from '@material-ui/core';
import { LoadsTable } from '../../components/LoadSystem';
import React from 'react';

const PastLoads = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <LoadsTable
          table="pastLoads"
          isForager
          requestParams={{ isPastLoads: true }}
        />
      </Grid>
    </Grid>
  );
};

export default PastLoads;
