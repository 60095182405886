import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, makeStyles } from '@material-ui/core';

import FeeItem from './FeeItem';

const AddFeeForm = ({ addCharge }) => {
  const classes = useStyles();

  const [newFee, setNewFee] = useState({
    rateTypeId: '',
    value: '',
  });

  const handleChange = (value, key) => {
    setNewFee({ ...newFee, [key]: value });
  };

  const handleAddUpdate = () => {
    addCharge(newFee);
    setNewFee({
      ...newFee,
      value: '',
      rateTypeId: null,
    });
  };

  return (
    <Grid container spacing={2} alignItems="center" className={classes.form}>
      <Grid item xs={12}>
        <FeeItem {...newFee} handleChange={handleChange} edit newFee />
      </Grid>

      <Grid item xs={12}>
        <Grid container justify="flex-end">
          <Button
            color="primary"
            variant="contained"
            size="small"
            className={classes.button}
            onClick={handleAddUpdate}
            disabled={!(newFee.rateTypeId && newFee.value)}
          >
            Add
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

AddFeeForm.propTypes = {
  onAdd: PropTypes.func.isRequired,
};

const useStyles = makeStyles({
  form: {
    margin: '10px 0',
  },
  button: { color: '#fff' },
});

export default AddFeeForm;
