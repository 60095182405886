import React from 'react';
import { Grid } from '@material-ui/core';
import { Route, Redirect } from 'react-router-dom';
import { MainView } from './';
import { Contacts } from '../../components/common/Contacts';
import { CustomsBrokerProfileHeader } from '../../components/CustomsBrokerSystem';

const CustomsBrokerProfile = ({ match }) => {
  const companyId = match.params.id;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CustomsBrokerProfileHeader companyId={companyId} />
      </Grid>
      <Grid item xs={12}>
        <Route
          path={`${match.path}/main`}
          render={props => <MainView companyId={companyId} {...props} />}
        />
        <Route
          path={`${match.path}/contacts`}
          render={props => (
            <Contacts
              id={companyId}
              getPath="company/contacts"
              putPath="company/contacts"
              rolesFor="chb"
              {...props}
            />
          )}
        />
        <Route
          exact
          path={`${match.path}`}
          render={props => <Redirect to={`${match.url}/main`} {...props} />}
        />
      </Grid>
    </Grid>
  );
};

export default CustomsBrokerProfile;
