import React from 'react';
import { NavLink } from 'react-router-dom';

import { ListItem, ListItemText, makeStyles } from '@material-ui/core';

const NavChild = ({ path, name }) => {
  const classes = useStyles();

  return (
    <NavLink
      activeClassName={classes.active}
      className={classes.root}
      to={'/' + path}
    >
      <ListItem button className={classes.navItem}>
        <ListItemText
          primary={name}
          className={[classes.title, name].join(' ')}
          disableTypography={true}
        />
      </ListItem>
    </NavLink>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    textDecoration: 'none',
    color: '#fff',
  },
  navItem: {
    borderRadius: '5px',
    height: '44px',
    marginLeft: 30,
  },
  active: {
    color: theme.palette.blueSky.main,
  },
  title: {
    fontWeight: 400,
    fontSize: '16px',
  },
}));

export default NavChild;
