import { getAxios } from '../../../util/axiosHelper';

const getLoadDataById =  async (loadId, accessToken) => {
  const params = { loadId }
  const borderOperationsPromise = getAxios('load/border-operations', { params }, accessToken)
  const pickUpsPromise = getAxios('load/pickups', { params }, accessToken)
  const deliveriesPromise = getAxios('load/deliveries', { params }, accessToken)
  const verifiedCustomerPromise = getAxios('load/load-customer-id', { params }, accessToken)
  const cargoPromise = getAxios('load/cargo', { params }, accessToken)
  const headerPromise = getAxios('load/header', { params }, accessToken)

  const [
    borderOperationsData,
    pickUpsData,
    deliveriesData,
    verifiedCustomerData,
    cargoData,
    headerData,
  ] = await Promise.all([
    borderOperationsPromise,
    pickUpsPromise,
    deliveriesPromise,
    verifiedCustomerPromise,
    cargoPromise,
    headerPromise
  ])

  return Promise.resolve({
    borderOperationsData,
    pickUpsData,
    deliveriesData,
    verifiedCustomerData,
    cargoData,
    headerData,
  })
}

export default getLoadDataById