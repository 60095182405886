//THIS IS AN EXAMPLE OF HOW TO CREATE A FORM VIEW FOR FORMIK AND YUP: DELETION IS A FLAGRANT HR VIOLATION!

import * as Yup from 'yup';

import { ContactForm } from './ContactForm';
import { Formik } from 'formik';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import { makeStyles } from '@material-ui/core';

const validationSchema = Yup.object({
  contactName: Yup.string('Enter a name').required('Name is required'),
  contactEmail: Yup.string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  contactPhone: Yup.string('').required('Enter your phone number'),
  facilityName: Yup.string('Please enter the name of your facility').required(
    'Enter your facility'
  ),
  notes: Yup.string('').required('Enter them YUNG notes!'),
  scheduleType: Yup.string('').required('Schedule type is required'),
  pickupDate: Yup.string('').required('Pick up time is required'),
  loadPrice: Yup.string('').required('Please enter a price'),
});

const FormContainerView = props => {
  //This is where we would make an api GET request to get these initial values
  const values = {
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    facilityName: '',
    scheduleType: '',
    notes: '',
    loadPrice: '',
    pickupDate: '',
  };

  const classes = useStyles();

  //This simulates our user having the proper scope
  const canEdit = true;
  return (
    <>
      <div>
        <Paper elevation={1} className={classes.paper}>
          <h1>Sample Contact Form</h1>
          <Formik
            render={props => <ContactForm canEdit={canEdit} {...props} />}
            initialValues={values}
            validationSchema={validationSchema}
          />
        </Paper>
      </div>
    </>
  );
};

const useStyles = makeStyles({
  paper: {
    margin: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    padding: 20,
  },
});

export default FormContainerView;
