import {
  Address,
  CardHead,
  Email,
  Phone,
  Selector,
  Text,
  Website,
} from '../common/CardContent';
import { Box, Button, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { CustomsBrokerProfile } from '../../views/CustomsBrokerSystem/';
import { ShadowCard } from '../common/Containers';
import { Toggle } from '../common/Switches';
import catchErrors from '../../util/catchErrors';
import options from '../../util/options';
import { postAxios } from '../../util/axiosHelper';
import { useAuth0 } from '../../react-auth0-wrapper';

const AddNewCustomsBroker = () => {
  const { accessToken } = useAuth0();
  const [customsBrokerId, setCustomsBrokerId] = useState(null);
  const [error, setError] = useState({});

  const [newCustomsBroker, setNewCustomsBroker] = useState({
    legalName: '',
    dba: '',
    address: '',
    address2: '',
    city: '',
    postalCode: '',
    state: '',
    phone: '',
    phoneExt: '',
    email: '',
    website: '',
    countryServicedId: null,
    papeworkDeliveryMethodId: null,
    ctpat: null,
    borderDrayageProvided: null,
    overtimeAvailable: null,
  });
  // Validation
  const [legalNameValid, setLegalNameValid] = useState(false);
  const [addressValid, setAddressValid] = useState(false);
  const [phoneValid, setPhoneValid] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [countryServicedIdValid, setCountryServicedIdValid] = useState(false);

  const handleChange = (value, key) => {
    setNewCustomsBroker({ ...newCustomsBroker, [key]: value });
  };

  const handleToggle = (key, value) => {
    setNewCustomsBroker({ ...newCustomsBroker, [key]: value });
  };

  const createCustomsBroker = () => {
    postAxios(
      'customs-broker/new-customs-broker',
      { ...newCustomsBroker },
      accessToken
    ).then(data => {
      setCustomsBrokerId(data.id);
    });
  };

  const handleCreate = () => {
    catchErrors([
      legalNameValid,
      addressValid,
      phoneValid,
      emailValid,
      countryServicedIdValid,
    ])
      .then(() => {
        setError({});
        createCustomsBroker();
      })
      .catch(error => {
        setError(error);
      });
  };

  if (customsBrokerId) {
    return (
      <Switch>
        <Redirect
          push
          component={CustomsBrokerProfile}
          to={`/customs-brokers/profile/id/${customsBrokerId}`}
        />
      </Switch>
    );
  }

  return (
    <ShadowCard>
      <Box pb={3}>
        <CardHead editable={false} headingText="Customs Broker" />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Text
            required
            error={error}
            setValid={setLegalNameValid}
            errorText="This field is required"
            editHook={handleChange}
            value={newCustomsBroker.legalName}
            label="Legal Name"
            edit
            name="legalName"
          />
        </Grid>
        <Grid item xs={6}>
          <Text
            editHook={handleChange}
            value={newCustomsBroker.dba}
            label="DBA"
            edit
            name="dba"
          />
        </Grid>
        <Grid item xs={6}>
          <Address
            label="Physical Address"
            edit
            disableSetSearchString
            address={newCustomsBroker.address}
            addressName="address"
            address2={newCustomsBroker.address2}
            address2Name="address2"
            city={newCustomsBroker.city}
            cityName="city"
            postalCode={newCustomsBroker.postalCode}
            postalCodeName="postalCode"
            state={newCustomsBroker.state}
            stateName="state"
            editHook={location =>
              setNewCustomsBroker({ ...newCustomsBroker, ...location })
            }
            error={error}
            setValid={setAddressValid}
            errorText="Please enter a valid address"
            required
          />
        </Grid>
        <Grid item xs={6}>
          <Phone
            number={newCustomsBroker.phone}
            edit
            name="phone"
            canSms
            label="Phone"
            editHook={handleChange}
            errorText="Please enter a valid Phone Number"
            setValid={setPhoneValid}
            error={error}
            required
          />
          <Text
            value={newCustomsBroker.phoneExt}
            edit
            name="phoneExt"
            label="Ext."
            editHook={handleChange}
            type="number"
          />
        </Grid>
        <Grid item xs={6}>
          <Email
            email={newCustomsBroker.email}
            edit
            name="email"
            label="Email"
            errorText="Please enter a valid Email"
            error={error}
            setValid={setEmailValid}
            editHook={handleChange}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <Website
            website={newCustomsBroker.website}
            edit
            name="website"
            error={error}
            label="Website"
            errorText="Please enter a valid Website"
            editHook={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <Selector
            label="Country Serviced"
            edit
            value={newCustomsBroker.countryServicedId}
            name="countryServicedId"
            options={options.countriesServiced}
            editHook={handleChange}
            required
            error={error}
            errorText="This field is required"
            setValid={setCountryServicedIdValid}
          />
        </Grid>
        <Grid item xs={6}>
          <Selector
            label="Paperwork Delivery Method"
            edit
            value={newCustomsBroker.papeworkDeliveryMethodId}
            name="papeworkDeliveryMethodId"
            options={options.paperworkDeliveryMethods}
            editHook={handleChange}
            checked={newCustomsBroker.papeworkDeliveryMethodId}
          />
        </Grid>
        <Grid item xs={3}>
          <Toggle
            label="CTPAT"
            name={`ctpat`}
            value={newCustomsBroker.ctpat}
            editHook={handleToggle}
            checked={newCustomsBroker.ctpat}
          />
        </Grid>
        <Grid item xs={3}>
          <Toggle
            label="Border Drayage Provided"
            name={`borderDrayageProvided`}
            value={newCustomsBroker.borderDrayageProvided}
            editHook={handleToggle}
            checked={newCustomsBroker.borderDrayageProvided}
          />
        </Grid>
        <Grid item xs={3}>
          <Toggle
            label="Overtime Available"
            name={`overtimeAvailable`}
            value={newCustomsBroker.overtimeAvailable}
            editHook={handleToggle}
            checked={newCustomsBroker.overtimeAvailable}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} justify="flex-end">
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                size="large"
                onClick={handleCreate}
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ShadowCard>
  );
};

export default AddNewCustomsBroker;
