import { CardHead, Email, Phone, Text } from '../CardContent';
import { Grid, IconButton, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { Loader } from '../Loader';
import PropTypes from 'prop-types';
import { ShadowCard } from '../Containers';
import { Visibility } from '@material-ui/icons';
import { getAxios } from '../../../util';
import { useAuth0 } from '../../../react-auth0-wrapper';

const MainContact = ({ companyId, getPath }) => {
  const classes = useStyles();

  const { accessToken } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [dataNotFound, setDataNotFound] = useState(false);
  const [contact, setContact] = useState({});

  useEffect(() => {
    getAxios(getPath, { params: { companyId } }, accessToken).then(data => {
      if (data) {
        setContact(data);
      } else {
        setDataNotFound(true);
      }
      setLoading(false);
    });
  }, [accessToken, getPath, companyId]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <ShadowCard>
          <CardHead
            headingText="Main Contact"
            action={
              <Grid container alignItems="center">
                <Grid item>
                  <Typography>See All Contacts</Typography>
                </Grid>
                <Grid item>
                  <IconButton component={Link} to={'contacts'} size="small">
                    <Visibility />
                  </IconButton>
                </Grid>
              </Grid>
            }
          />

          {!dataNotFound ? (
            <Grid container spacing={1} className={classes.form}>
              <Grid item xs={12}>
                <Text
                  label="Name"
                  value={`${contact.firstName} ${contact.lastName}`}
                />
              </Grid>
              <Grid item xs={12}>
                <Text label="Title" value={contact.title} />
              </Grid>
              <Grid item xs={12}>
                <Phone label="Cell Phone" name="phone" number={contact.phone} />
              </Grid>
              <Grid item xs={12}>
                <Phone label="Office Phone" number={contact.officePhone} />
              </Grid>
              <Grid item xs={12}>
                <Text label="Office Phone Ext" value={contact.officePhoneExt} />
              </Grid>
              <Grid item xs={12}>
                <Email label="Email" name="email" email={contact.email} />
              </Grid>
            </Grid>
          ) : (
            <Grid container className={classes.form}>
              <Grid item xs={12}>
                <Typography>No Main Contact Found</Typography>
              </Grid>
            </Grid>
          )}
        </ShadowCard>
      )}
    </>
  );
};

MainContact.propTypes = {
  companyId: PropTypes.string.isRequired,
  getPath: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
  form: {
    marginTop: 15,
  },
});

export default MainContact;
