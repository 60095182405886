import { Grid, Typography, makeStyles } from '@material-ui/core';

import { CardHead } from '../CardContent';
import { FormTextArea } from '../Forms';
import PropTypes from 'prop-types';
import React from 'react';
import { ShadowCard } from '../Containers';
import { TouchAppRounded } from '@material-ui/icons';
import { connect } from 'formik';
import { debounce } from 'lodash';

const TextAreaCard = ({
  maxCharacters,
  minHeight,
  maxHeight,
  formik,
  canEdit,
  name,
  rows
}) => {
  const classes = useStyles();

  const onSyncChange = e => debounce.bind(null, handleChange(e), 2000);

  const handleFieldChange = (fieldName, e) => {
    if (e.persist) {
      e.persist();
    }
    setFieldTouched(fieldName, true, false);
    return onSyncChange(e);
  };

  const { touched, errors, handleChange, setFieldTouched, values } = formik;
  const { loadNotes } = values;
  return (
    <ShadowCard>
      <CardHead headingText="Notes" />
      <Grid
        style={{ minHeight: minHeight, maxHeight: maxHeight }}
        className={classes.MuiTextField}
      >
        {canEdit ? (
          <FormTextArea
            name="loadNotes"
            helperText={TouchAppRounded.loadNotes ? errors.loadNotes : ''}
            error={touched.loadNotes && Boolean(errors.loadNotes)}
            value={values[name]}
            onChange={handleFieldChange.bind(null, name)}
            canEdit={true}
            rows={rows}
          />
        ) : (
          <Typography className={classes.typography}>{loadNotes}</Typography>
        )}
      </Grid>
    </ShadowCard>
  );
};

TextAreaCard.propTypes = {
  maxCharacters: PropTypes.number,
  minHeight: PropTypes.number,
  maxHeight: PropTypes.number,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  editViewOnly: PropTypes.bool,
  editView: PropTypes.bool,
  withoutButtons: PropTypes.bool,
  initialValue: PropTypes.string,
  name: PropTypes.string,
};

const useStyles = makeStyles({
  MuiTextField: {
    overflow: 'auto',
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'black',
    },
  },
  typography: { whiteSpace: 'pre-line' },
});

export default connect(TextAreaCard);
