import React from 'react';
import { Suggest } from '../common/CardContent';
import { getAxios, postAxios } from '../../util/axiosHelper';
import { useAuth0 } from '../../react-auth0-wrapper';
import { Delete } from '@material-ui/icons';
import { IconButton, Box } from '@material-ui/core';

const Location = ({
  address,
  addressType,
  index,
  onAddressSelect,
  onAddressEmpty,
  onDelete,
  delCountry,
  puCountry,
  setValid,
  error,
  errorText,
  name,
}) => {
  const { accessToken } = useAuth0();

  const handleOptionClick = async address => {
    const params = { city: address.city, state: address.stateLongName };
    let dbCityInfo = await getAxios('geo/city/info', { params }, accessToken);
    dbCityInfo = dbCityInfo.cityId
      ? dbCityInfo
      : await getAxios(
          'geo/geocode/perm',
          { params: { city: address.name } },
          accessToken
        ).then(
          data =>
            data && postAxios('geo/create/city', { city: data }, accessToken)
        );

    dbCityInfo = {
      ...dbCityInfo,
      coordinates: [dbCityInfo.lng, dbCityInfo.lat],
      city: address.city,
      state: address.stateLongName,
      name: address.name,
      countryLongName: address.countryLongName,
      countryShortName: address.countryShortName,
    };

    onAddressSelect(index, dbCityInfo);
  };

  const exclude = () => {
    let toExclude = [];
    const countries = ['mx', 'ca', 'us'];
    if (addressType === 'pickup') {
      if (delCountry) {
        toExclude = countries.filter(country => country === delCountry);
      }
      if (puCountry) {
        toExclude = countries.filter(country => country !== puCountry);
      }
    } else if (addressType === 'delivery') {
      if (puCountry) {
        toExclude = countries.filter(country => country === puCountry);
      }
      if (delCountry) {
        toExclude = countries.filter(country => country !== delCountry);
      }
    }
    return toExclude;
  };

  const label = `${addressType.replace(
    /^./,
    addressType[0].toUpperCase()
  )} Location #${index + 1}`;

  const placeholder = `${addressType.replace(
    /^./,
    addressType[0].toUpperCase()
  )} Location #${index + 1}`;

  return (
    <Suggest
      edit="true"
      label={label}
      value={address || ''}
      placeholder={placeholder}
      valuesToExclude={exclude()}
      name={name}
      editHook={address => handleOptionClick(address)}
      urlString="geo/autocomplete/city"
      iconBtn={
        index !== 0 ? (
          <IconButton onClick={onDelete} style={{ color: '#EE164A' }}>
            <Delete fontSize="small" />
          </IconButton>
        ) : (
          <Box m={2.7}></Box>
        )
      }
      error={error}
      errorText={errorText}
      setValid={setValid}
      onAddressEmpty={() => onAddressEmpty(index)}
      required
    />
  );
};

export default Location;
