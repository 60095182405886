import {
  Input,
  MenuItem,
  MenuList,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { Loader } from '../Loader';
import { useTypeahead } from '../../../hooks';

const Typeahead = ({
  value,
  name,
  onChange,
  disabled,
  placeholder,
  urlString,
  menuCta,
  noRecordsFoundMsg = 'No records found!',
  valuesToExclude,
  iconBtn,
  disableSetSearchString,
  onEmpty,
}) => {
  onEmpty = onEmpty ? onEmpty : () => '';
  const classes = useStyles();
  const [searchString, setSearchString] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const { typeaheadData, isLoading } = useTypeahead(
    searchString,
    urlString,
    valuesToExclude
  );
  const handleOnChange = e => {
    setShowOptions(true);
    setSearchString(e.target.value);

    if (e.target.value.length === 0) {
      onEmpty();
    }

    if (!e.target.value) {
      setShowOptions(false);
    }
  };

  const handleOptionClick = item => {
    setShowOptions(false);
    if (disableSetSearchString) {
    } else {
      setSearchString(item.name);
    }
    onChange(item);
  };

  let paperContent;

  if (isLoading) {
    paperContent = (
      <div className={classes.loadBox}>
        <Loader />
      </div>
    );
  } else {
    paperContent = (
      <MenuList id="typeahead-menu" data-testid={name}>
        {Array.isArray(typeaheadData) && typeaheadData.length > 0 ? (
          typeaheadData.map((item, id) => (
            <MenuItem key={id} onClick={() => handleOptionClick(item)} data-testid={item.name}
>
              {item.name}
            </MenuItem>
          ))
        ) : (
          <Typography variant="overline" align="center">
            {noRecordsFoundMsg}
          </Typography>
        )}
        {menuCta}
      </MenuList>
    );
  }

  useEffect(() => {
    setSearchString(value);
  }, [value]);

  return (
    <div className={classes.root}>
      {iconBtn && iconBtn}
      <Input
        value={searchString}
        disabled={disabled}
        onChange={handleOnChange}
        onBlur={() => setTimeout(() => setShowOptions(false), 200)}
        placeholder={placeholder}
        className={classes.input}
        data-testid={name}
        autoComplete="new-password"
      />

      {showOptions ? (
        <Paper className={classes.typeaheadResultList} data-testid={name}>{paperContent}</Paper>
      ) : null}
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  input: {
    position: 'relative',
    width: '100%',
    '& input': {
      textAlign: 'right',
    },
  },
  typeaheadResultList: {
    position: 'absolute',
    zIndex: 500,
    minWidth: '100%',
    minHeight: 100,
    top: '40px',
  },
  loadBox: {
    width: '100%',
    minHeight: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default Typeahead;
