import 'react-phone-number-input/style.css';

import { SvgIcon, Typography, makeStyles } from '@material-ui/core';

import { CopyToClipboard } from '../Utility';
import React from 'react';
import { Sms } from '@material-ui/icons';
import formatPhoneNumber from '../../../util/formatPhoneNumber';

const PhoneLink = ({ number, canSms, alignment }) => {
  const classes = useStyles();
  if (!number) {
    return <Typography align={alignment}>-</Typography>;
  }
  if (number[0] !== '+' || number.length < 12 || number.length > 13) {
    return <Typography align={alignment}>Invalid Format</Typography>;
  }
  return (
    <Typography align={alignment}>
      <a href={`tel:${number}`} className={classes.phoneLink}>
        {formatPhoneNumber(number)}
      </a>
      <CopyToClipboard toCopy={number} />
      {canSms && (
        <a href={`sms:${number}`}>
          <SvgIcon fontSize="small">
            <Sms />
          </SvgIcon>
        </a>
      )}
    </Typography>
  );
};

const useStyles = makeStyles(theme => ({
  phoneLink: {
    fontSize: 15,
    textDecoration: 'none',
    minHeight: 24,
    color: theme.palette.blueSky.main,
  },
}));

export default PhoneLink;
