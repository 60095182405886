import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { useAuth0 } from '../../react-auth0-wrapper';
import history from '../../util/history';

import getLoadDataById from '../../views/LoadSystem/utils/getLoadDataById';
import transformLoadToRate from '../../views/LoadSystem/utils/transformLoadToRate';

const DuplicateLoadButton = ({ loadId }) => {
  const { accessToken } = useAuth0();
  const [isLoading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);

    const data = await getLoadDataById(loadId, accessToken);

    const initialEnterLoadData = transformLoadToRate(data);

    setLoading(false);

    history.push({
      pathname: '/customer-portal/instant-rate/enter-load-info',
      state: {
        initialEnterLoadData,
        loadId,
        duplicate: true,
      },
    });
  };

  return (
    <Button
      onClick={handleClick}
      variant="contained"
      size="small"
      color="primary"
      disabled={isLoading}
    >
      Duplicate
    </Button>
  );
};

DuplicateLoadButton.propTypes = {
  loadId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default DuplicateLoadButton;
