import { MenuItem, makeStyles } from '@material-ui/core';

import { FormField } from './index';
import PropTypes from 'prop-types';
import React from 'react';

const FormSelect = ({
  label,
  name,
  error,
  helperText,
  options,
  value,
  canEdit,
  onChange,
  styleClasses,
}) => {
  const classes = makeStyles({ styleClasses });

  const renderMenuItems = options =>
    Object.keys(options).map(option => (
      <MenuItem key={option} value={option}>
        {options[option]}
      </MenuItem>
    ));

  return (
    <FormField
      canEdit={canEdit}
      className={classes.input}
      error={error}
      name={name}
      helperText={helperText}
      id={name}
      label={label}
      onChange={onChange}
      select={true}
      value={value}
      options={options}
      data-testid={name}
    >
      {canEdit && renderMenuItems(options)}
    </FormField>
  );
};

FormSelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  options: PropTypes.object,
  value: PropTypes.node,
  canEdit: PropTypes.bool,
  onChange: PropTypes.func,
  styleClasses: PropTypes.object,
};

export default FormSelect;
