import deriveBorderOperationsRoute from './deriveBorderOperationsRoute';

const countryMap = {
  USA: {
    shortName: 'US',
    longName: 'United States',
  },
  MEX: {
    shortName: 'MX',
    longName: 'Mexico',
  },
  CAN: {
    shortName: 'CA',
    longName: 'Canada',
  },
};

export const formatStop = (stop, type) => {
  const {
    stopNumber,
    facilityState,
    facilityCity,
    country,
    coordinates,
    facilityLegalName,
    ...rest
  } = stop;

  return {
    ...rest,
    stop: stopNumber,
    address: `${facilityCity}, ${facilityState}, ${countryMap[country].longName}`,
    addressType: type,
    customerReference: '', // purposefully set to empty string since the new load will not have one even if the prior load did
    apptOpen: null, // purposefully set to null since the new load will not have one even if the prior load did
    apptClose: null, // purposefully set to null since the new load will not have one even if the prior load did
    cityName: facilityCity,
    state: facilityState,
    stateName: facilityState,
    country: countryMap[country] && countryMap[country].shortName.toLowerCase(),
    shortCountry: countryMap[country].shortname,
    name: `${facilityCity}, ${facilityState}, ${countryMap[country].longName}`,
    lat: coordinates[1],
    lng: coordinates[0],
    coordinates,
    city: facilityCity,
    countryLongName: countryMap[country].longName,
    countryShortName:
      countryMap[country] && countryMap[country].shortName.toLowerCase(),
    facilityLegalName,
    facilityCity,
    facilityState,
  };
};

export const formatProduct = product => {
  const { canStack, hazmat, reefer, reeferOptionId, value, ...rest } = product;

  return {
    ...rest,
    canStack: !!canStack,
    hazmat: !!hazmat,
    reefer: !!reefer,
    referTypeId: reeferOptionId,
    reeferOptionId,
    loadValue: value,
    value,
    isLoadValueAbove250: value > 250000,
  };
};

export default ({
  borderOperationsData,
  pickUpsData,
  deliveriesData,
  verifiedCustomerData,
  cargoData,
  headerData,
}) => {
  const {
    mode: shipMode,
    eqType: equipmentType,
    crossing: crossingLocation,
    pickupLocation: startingPickupLocation,
    deliveryLocation: endingDeliveryLocation,
    ...restHeader
  } = headerData;

  return {
    pickups: pickUpsData.map(pickup => formatStop(pickup, 'pickup')),
    deliveries: deliveriesData.map(delivery =>
      formatStop(delivery, 'delivery')
    ),
    products: cargoData.map(formatProduct),
    ...borderOperationsData,
    borderOperationsRoute: deriveBorderOperationsRoute(
      countryMap[pickUpsData[0].country].shortName,
      countryMap[deliveriesData[deliveriesData.length - 1].country].shortName
    ),
    customerId: verifiedCustomerData ? verifiedCustomerData.customer_id : null,
    shipMode,
    equipmentType,
    crossingLocation,
    startingPickupLocation,
    endingDeliveryLocation,
    ...restHeader,
    bolNumber: '',
  };
};
