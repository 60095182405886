import {
  Button,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

const CustomerRepDefault = ({
  repData,
  deactivateRep,
  activateRep,
  makeRepMain,
  edit,
}) => {
  const [rep, setRep] = useState({
    userId: null,
    firstName: '',
    lastName: '',
    title: '',
    edit: '',
    active: '',
    main: '',
  });

  const handleDeactivateClick = () => {
    deactivateRep(rep.userId);
  };

  const handleMakeMainClick = () => {
    makeRepMain(rep.userId);
  };

  const handleActivateClick = () => {
    activateRep(rep.userId);
  };

  useEffect(() => {
    setRep({ ...repData });
  }, [repData]);

  return (
    <List>
      <ListItem disableGutters={true}>
        <Grid container spacing={1}>
          <Grid item xs={7}>
            <ListItemText
              primaryTypographyProps={{ variant: 'h5', gutterBottom: false }}
              primary={`${rep.firstName} ${rep.lastName}`}
              secondary={rep.title}
            />
          </Grid>
          <Grid item xs={5}>
            <Grid container spacing={0}>
              <Grid item xs={edit ? 6 : 12}>
                {rep.main ? (
                  <ListItemText
                    primary={
                      <Chip
                        size="small"
                        label="Main"
                        style={{
                          backgroundColor: '#1FDB74',
                          color: 'white',
                          fontSize: 12,
                        }}
                      />
                    }
                  />
                ) : (
                  edit && (
                    <ListItemText
                      primary={
                        <Button
                          size="small"
                          variant="outlined"
                          onClick={handleMakeMainClick}
                          style={{
                            fontSize: 12,
                          }}
                        >
                          Make Main
                        </Button>
                      }
                    />
                  )
                )}
                {!rep.active && !edit && (
                  <ListItemText
                    primary={
                      <Chip
                        size="small"
                        label="Deactivated"
                        style={{
                          backgroundColor: '#EE164A',
                          color: 'white',
                          fontSize: 12,
                        }}
                      />
                    }
                  />
                )}
              </Grid>

              {edit && (
                <>
                  <Grid item xs={6}>
                    {rep.active ? (
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={handleDeactivateClick}
                        style={{ color: '#EE164A', borderColor: '#EE164A' }}
                      >
                        Deactivate
                      </Button>
                    ) : (
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={handleActivateClick}
                        style={{ color: '#1FDB74', borderColor: '#1FDB74' }}
                      >
                        Activate
                      </Button>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </ListItem>
      <Divider />
    </List>
  );
};

export default CustomerRepDefault;
