import React, { useState } from 'react';
import { useAuth0 } from '../../react-auth0-wrapper';
import { NavLink } from 'react-router-dom';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  makeStyles,
} from '@material-ui/core';

import { ExpandLess, ExpandMore } from '@material-ui/icons';

import { NavChild } from './';

import { userHasScopes } from '../../util';

const NavParent = ({ parentItem }) => {
  const { name, icon: ListIcon, pages, path, noDrop, permission } = parentItem;
  const classes = useStyles();
  const { isAuthenticated, scopes } = useAuth0();

  // Tracks State for if menu dropdown should be open or closed
  const [open, setOpen] = useState(false);

  function handleClick() {
    setOpen(!open);
  }

  // Generates all the child menu items for dropdown
  const createMenuItems = data => {
    return data.map(page => {
      const { path, name } = page;
      return isAuthenticated && <NavChild path={path} name={name} key={name} />;
    });
  };

  if (!userHasScopes(permission, scopes)) {
    return null;
  }

  if (noDrop) {
    return (
      <NavLink
        activeClassName={classes.active}
        className={classes.root}
        to={'/' + path}
      >
        <ListItem button className={classes.navItem}>
          <ListItemIcon>
            <ListIcon className={classes.icon} />
          </ListItemIcon>
          <ListItemText
            primary={name}
            className={classes.title}
            disableTypography={true}
          />
        </ListItem>
      </NavLink>
    );
  }

  return (
    <>
      <ListItem button onClick={handleClick} className={classes.navItem}>
        <ListItemIcon>
          <ListIcon className={classes.icon} />
        </ListItemIcon>
        <ListItemText disableTypography={true} primary={name} />
        {/* If Open icon is up, if closed icon points down */}
        {open ? (
          <ExpandLess className={classes.icon} />
        ) : (
          <ExpandMore className={classes.icon} />
        )}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div">{createMenuItems(pages)}</List>
      </Collapse>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    textDecoration: 'none',
    color: '#fff',
  },
  active: {
    color: `${theme.palette.blueSky.main}!important`,
  },
  navItem: {
    borderRadius: '3px',
    color: 'inherit',
    fontWeight: 500,
    fontSize: 16,
    height: '54px',
    marginTop: '5px',
    textDecoration: 'none',
  },
  icon: {
    color: '#fff',
  },
}));

export default NavParent;
