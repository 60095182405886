import {
  Box,
  Button,
  Divider,
  Grid,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  CardHead,
  Currency,
  Email,
  Phone,
  Subheader,
  Text,
} from '../common/CardContent';
import { Link, Redirect } from 'react-router-dom';
import React, { Fragment, useCallback, useEffect, useState } from 'react';

import { Loader } from '../common/Loader';
import RateDisplayItem from './RateDisplayItem';
import { ShadowCard } from '../common/Containers';
import { Toggle } from '../common/Switches';
import { Warning } from '@material-ui/icons';
import logoMarkBlue from '../../assets/img/logo-mark-blue.svg';
import moment from 'moment';

const RatesDisplay = ({ instantRateFormData, history, isForager }) => {
  const classes = useStyles();
  const {
    borderOperationsRoute,
    crossingLocation,
    mexBorderCrossingId,
    deliveryAddressInputs,
    deliveryRouteMileage,
    delCountry,
    delLocations = [],
    equipmentType,
    equipmentTypeId,
    isHazmat = { checked: false },
    isMxPresent = false,
    isTeamService = { checked: false },
    loadValue,
    pickupAddressInputs,
    pickupRouteMileage,
    pricing,
    puLocations = [],
    puCountry,
    shipMode = 'Truckload',
    totalMileage = 0,
    daysToBorder,
    borderToDeliver,
    transitTime,
    customerName,
    verifiedCustomerId,
    crossingCityCoordinates,
    priceQuoteId,
  } = instantRateFormData;

  const [loadRate, setLoadRate] = useState(0);
  const [showCost, setShowCost] = useState(isForager);
  const [crossingRate, setCrossingRate] = useState(0);
  const [activeRate, setActiveRate] = useState();
  const [hasNoFacilities, setHasNoFacilities] = useState(false);
  const [selectedRateType, setSelectedRateType] = useState(null);
  const [pickupDate, setPickupDate] = useState(moment());
  const [crossingModeId, setCrossingModeId] = useState(null);
  const [saturdayPickUpDate, setSaturdayPickUpDate] = useState(
    moment().format('MM/DD/YYYY')
  );
  const [sundayPickUpdate, setSundayPickUpdate] = useState(
    moment().format('MM/DD/YYYY')
  );
  const [isWeekend, setIsWeekend] = useState(false);
  const [isNextDay, setIsNextDay] = useState(false);

  const doesHaveFacilities = useCallback(() => {
    let uniq = [];

    [...puLocations, ...delLocations].map(item =>
      uniq.push(item.facilitiesWithinRadius)
    );

    if (Array.from(new Set(uniq)).includes(false)) {
      setHasNoFacilities(true);
    }
  }, [delLocations, puLocations]);

  const crossingFee = pricing
    ? pricing.crossingFee
      ? pricing.crossingFee
      : null
    : null;

  const loadPickupDate = useCallback(() => {
    let newPickupDate = moment().format('MM/DD/YYYY');
    let saturdayPickUpDate;
    let sundayPickUpdate;

    if (selectedRateType === 'nextDay') {
      newPickupDate = moment()
        .add(1, 'days')
        .format('MM/DD/YYYY');
      setPickupDate(newPickupDate);
      setIsNextDay(true);
      setIsWeekend(false);
    } else if (selectedRateType === 'lead48hr') {
      newPickupDate = moment()
        .add(2, 'days')
        .format('MM/DD/YYYY');
      setPickupDate(newPickupDate);
      setIsNextDay(false);
      setIsWeekend(false);
    } else if (selectedRateType === 'weekendRates') {
      // sets to closest Saturday
      saturdayPickUpDate = moment()
        .day(6)
        .format('MM/DD/YYYY');

      // sets to closest Sunday
      sundayPickUpdate = moment()
        .day(7)
        .format('MM/DD/YYYY');
      setSaturdayPickUpDate(saturdayPickUpDate);
      setSundayPickUpdate(sundayPickUpdate);
      setIsWeekend(true);
      setIsNextDay(false);
    }

    // if not a weekend
    setPickupDate(newPickupDate);
  }, [selectedRateType]);

  let enterLoadInfoPayload;

  // Flag that lets the next screen know if the initial load value is above $250,000
  const isLoadValueAbove250 = loadValue > 250000 ? true : false;

  if (!pricing) {
    enterLoadInfoPayload = {};
  } else {
    enterLoadInfoPayload = {
      borderOperationsRoute,
      mexBorderCrossingId,
      crossingLocation,
      crossingModeId,
      deliveries: deliveryAddressInputs,
      delCountry,
      deliveryRouteMileage,
      endingDeliveryLocation: delLocations[delLocations.length - 1].address,
      equipmentType,
      equipmentTypeId,
      isForager,
      isHazmat,
      isNextDay,
      isWeekend,
      pickupDate,
      pickupRouteMileage,
      pickups: pickupAddressInputs,
      puCountry,
      saturdayPickUpDate,
      selectedRateType,
      shipMode,
      startingPickupLocation: puLocations[0].address,
      sundayPickUpdate,
      totalMileage,
      loadValue,
      customerName,
      verifiedCustomerId,
      daysToBorder,
      borderToDeliver,
      crossingCityCoordinates,
      isLoadValueAbove250,
      priceQuoteId,
      charges: [
        {
          rateUnitId: 8,
          rateTypeId: 53,
          transportationCharges: loadRate,
          amount: 1,
          currencyId: 2,
          per: loadRate,
        },
        {
          rateUnitId: 8,
          rateTypeId: 1,
          crossingRate,
          amount: 1,
          currencyId: 2,
          per: crossingRate,
        },
      ],
    };
  }

  const goToEnterLoadInfo = () => {
    history.push({
      pathname: 'enter-load-info',
      state: {
        initialEnterLoadData: { ...enterLoadInfoPayload },
      },
    });
  };

  // Takes user to top of page on render
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    doesHaveFacilities();
  }, [doesHaveFacilities]);

  useEffect(() => {
    loadPickupDate();
  }, [loadPickupDate]);

  if (!pricing) {
    return (
      <Redirect
        to={isForager ? '/tools/instant-rate' : '/customer-portal/instant-rate'}
      />
    );
  }
  return (
    <Box p={4}>
      <Grid item xs={12}>
        <CardHead headingText="Rate Options" />
      </Grid>

      {!pricing || !pricing.rates ? (
        <Loader />
      ) : (
        <Grid container justify="flex-end" alignItems="flex-end">
          <Grid item xs={12}>
            <Subheader headingText="Locations" />
          </Grid>
          <Grid item xs={12}>
            {puLocations.map((location, i) => {
              return (
                <Text
                  label={`Pickup Location #${i + 1}`}
                  key={i}
                  value={location.address}
                  tooltip={
                    !location.facilitiesWithinRadius && (
                      <Tooltip
                        interactive
                        classes={{ tooltip: classes.toolTipNoMaxWidth }}
                        title={
                          <ShadowCard>
                            <Typography variant="h5" align="center">
                              Whoops! No facility on file for this request!
                            </Typography>
                            <Typography variant="body2" align="center">
                              Please contact Team Forager so we can update your
                              profile.
                            </Typography>
                            <Typography display="inline" align="center">
                              <Box component="span" mt={2} mb={2}>
                                <img
                                  src={logoMarkBlue}
                                  alt="Forager Team"
                                  width="35px"
                                />
                                <strong>Forager Team</strong>
                              </Box>
                            </Typography>
                            <Typography variant="body1">
                              <Email
                                label="Email"
                                email="operations@foragerscs.com"
                              />
                              <Phone
                                number="+18554367243"
                                label="Phone"
                                name="phone"
                              />
                            </Typography>
                          </ShadowCard>
                        }
                        placement="right"
                      >
                        <Warning color="error" fontSize="small" />
                      </Tooltip>
                    )
                  }
                />
              );
            })}
          </Grid>
          {crossingLocation && (
            <Grid item xs={12}>
              <Text label="Cross City" value={crossingLocation} />
            </Grid>
          )}
          <Grid item xs={12}>
            {delLocations.map((location, i) => (
              <Text
                key={i}
                label={`Delivery Location #${i + 1}`}
                value={location.address}
                tooltip={
                  !location.facilitiesWithinRadius && (
                    <Tooltip
                      interactive
                      classes={{ tooltip: classes.toolTipNoMaxWidth }}
                      title={
                        <ShadowCard>
                          <Typography variant="h5" align="center">
                            Whoops! No facility on file for this request!
                          </Typography>
                          <Typography variant="body2" align="center">
                            Please contact Team Forager so we can update your
                            profile.
                          </Typography>
                          <Typography display="inline" align="center">
                            <Box component="span" mt={2} mb={2}>
                              <img
                                src={logoMarkBlue}
                                alt="Forager Team"
                                width="35px"
                              />
                              <strong>Forager Team</strong>
                            </Box>
                          </Typography>
                          <Typography variant="body1">
                            <Email
                              label="Email"
                              email="operations@foragerscs.com"
                            />
                            <Phone
                              number="+18554367243"
                              label="Phone"
                              name="phone"
                            />
                          </Typography>
                        </ShadowCard>
                      }
                      placement="right"
                    >
                      <Warning color="error" fontSize="small" />
                    </Tooltip>
                  )
                }
              />
            ))}
          </Grid>
          <Grid item xs={12}>
            <Subheader headingText="Route Info" />
          </Grid>
          <Grid item xs={12}>
            <Text label="Total Miles" value={`${totalMileage} miles`} />
            {isMxPresent ? (
              <Fragment>
                <Text label="Origin to Border" value={daysToBorder} />
                <Text label="Border to Delivery" value={borderToDeliver} />
              </Fragment>
            ) : (
              <Text label="Transit Time" value={transitTime} />
            )}
          </Grid>
          <Grid item xs={12}>
            <Subheader headingText="Additional Info" />
          </Grid>
          <Grid item xs={12}>
            <Text label="Hazmat" value={isHazmat.checked ? 'Yes' : 'No'} />
            <Text
              label="Team Service"
              value={isTeamService.checked ? 'Yes' : 'No'}
            />
            <Text label="Equipment Type" value={equipmentType} />
            <Currency label="Load Value" value={loadValue || 'N/A'} />
          </Grid>
          <Grid item xs={12}>
            <Subheader headingText="Select Rate" />
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
              spacing={2}
            >
              {pricing.rates.lead48hr.transload ? (
                <Fragment>
                  <Grid item>
                    <Box mr={3}>
                      <Typography variant="subtitle2">Transload</Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box mr={3}>
                      <Typography variant="subtitle2">Direct</Typography>
                    </Box>
                  </Grid>
                </Fragment>
              ) : (
                <Fragment>
                  <Grid item>
                    <Box>
                      <Typography variant="subtitle2">Standard Rate</Typography>
                    </Box>
                  </Grid>
                </Fragment>
              )}
            </Grid>
            {pricing &&
              Object.keys(pricing.rates).map((rate, idx) => (
                <RateDisplayItem
                  setActiveRate={setActiveRate}
                  activeRate={activeRate}
                  key={idx}
                  rateName={rate}
                  ratesObj={pricing.rates[rate]}
                  setCrossingModeId={setCrossingModeId}
                  setLoadRate={setLoadRate}
                  setSelectedRateType={setSelectedRateType}
                  cost={showCost}
                />
              ))}
            {isForager && (
              <Grid container justify="flex-end">
                <Box mr={4} mt={2}>
                  <Toggle
                    label="Show Cost"
                    name="cost"
                    editHook={(name, value) => setShowCost(value)}
                    checked={showCost}
                  />
                </Box>
              </Grid>
            )}
          </Grid>

          <Grid item xs={12}>
            <Box mt={4} mb={2}>
              <Divider variant="fullWidth" />
            </Box>
            {crossingFee && (
              <Grid
                container
                wrap="nowrap"
                justify="space-between"
                alignItems="center"
                spacing={3}
              >
                <Grid item xs={11}>
                  <Currency
                    label="Have Forager handle crossing?"
                    value={crossingFee}
                  />
                </Grid>
                <Grid item className={classes.noTopPadding}>
                  <Button
                    size="small"
                    variant="contained"
                    color={crossingRate > 0 ? 'secondary' : 'primary'}
                    onClick={() => {
                      if (crossingRate === 0) {
                        setCrossingRate(crossingFee);
                      } else {
                        setCrossingRate(0);
                      }
                    }}
                  >
                    {crossingRate ? 'Remove' : 'Add'}
                  </Button>
                </Grid>
              </Grid>
            )}
            <Text
              label="Total"
              value={
                <Currency
                  value={
                    activeRate
                      ? crossingRate === 0
                        ? loadRate
                        : loadRate + crossingFee
                      : null
                  }
                />
              }
            />
            <Grid container justify="center" alignItems="center" spacing={3}>
              <Grid item xs={2}>
                <Button
                  to={{
                    pathname: isForager
                      ? '/tools/instant-rate'
                      : '/customer-portal/instant-rate',
                    state: enterLoadInfoPayload,
                  }}
                  component={Link}
                  size="small"
                  variant="contained"
                  color="secondary"
                  fullWidth={true}
                  data-testid="start-over-btn"
                >
                  Start Over
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Grid container wrap="nowrap">
                  {!isForager && (
                    <Fragment>
                      <Button
                        onClick={goToEnterLoadInfo}
                        size="small"
                        variant="contained"
                        color="primary"
                        fullWidth={true}
                        disabled={!loadRate || hasNoFacilities}
                        data-testid="rates-display-book-btn"
                      >
                        Book
                      </Button>
                      {hasNoFacilities && (
                        <Tooltip
                          classes={{ tooltip: classes.toolTipNoMaxWidth }}
                          title={
                            <ShadowCard>
                              <Typography variant="h5" align="center">
                                Whoops! No facility on file for this request!
                              </Typography>
                              <Typography variant="body2" align="center">
                                Please contact Team Forager so we can update
                                your profile.
                              </Typography>
                              <Typography display="inline" align="center">
                                <Box component="span" mt={2} mb={2}>
                                  <img
                                    src={logoMarkBlue}
                                    alt="Forager Team"
                                    width="35px"
                                  />
                                  <strong>Forager Team</strong>
                                </Box>
                              </Typography>
                              <Typography variant="body1">
                                <Email
                                  label="Email"
                                  email="operations@foragerscs.com"
                                />
                                <Phone
                                  number="+18554367243"
                                  label="Phone"
                                  name="phone"
                                />
                              </Typography>
                            </ShadowCard>
                          }
                          placement="right"
                        >
                          <Warning color="error" fontSize="small" />
                        </Tooltip>
                      )}
                    </Fragment>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container justify="center" alignItems="center">
              <Box mt={2}>
                <Typography variant="caption" display="block">
                  All rates are saved automatically under My Rates
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

const useStyles = makeStyles({
  toolTipNoMaxWidth: {
    maxWidth: 'none',
    background: 'none',
  },
  noTopPadding: {
    paddingTop: '0px!important',
  },
});

export default RatesDisplay;
