import React, { useEffect } from 'react';

import { CardItem } from './';
import { Typeahead } from '../Typeahead';
import { Typography } from '@material-ui/core';

const Suggest = ({
  value,
  label,
  edit,
  editHook,
  name,
  required,
  error,
  errorText,
  setValid,
  urlString,
  menuCta,
  placeholder,
  iconBtn,
  valuesToExclude,
  onAddressEmpty,
}) => {
  setValid = setValid ? setValid : (setValid = () => '');
  editHook = editHook ? editHook : (editHook = () => '');
  onAddressEmpty = onAddressEmpty ? onAddressEmpty : () => '';

  // Validate Intial Value
  useEffect(() => {
    if (required && value) {
      setValid({ [name]: true });
    } else if (!required) {
      setValid({ [name]: true });
    } else {
      setValid({ [name]: false });
    }
  }, [name, required, setValid, value]);

  let content = edit ? (
    <Typeahead
      menuCta={menuCta}
      urlString={urlString}
      name={name}
      value={value}
      onChange={value => editHook(value, name)}
      placeholder={placeholder}
      iconBtn={iconBtn}
      valuesToExclude={valuesToExclude}
      onEmpty={() => onAddressEmpty()}
    />
  ) : (
    <Typography>{value ? value : '-'}</Typography>
  );

  return (
    <CardItem
      label={label}
      name={name}
      error={error}
      errorText={errorText}
      content={content}
    />
  );
};

export default Suggest;
