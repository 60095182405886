import { CircularProgress, Grid } from '@material-ui/core';

import React from 'react';

const Loader = () => (
  <Grid data-testid="loader" container justify="center">
    <CircularProgress />
  </Grid>
);

export default Loader;
