const isUnitedStates = country => country === 'us' || country ==='usa'
const isMexico = country => country === 'mx' || country === 'mex'
const isCanada = country => country === 'ca' || country === 'can'

export default (first = '', last = '') => {
  const firstLower = first.toLowerCase()
  const lastLower = last.toLowerCase()


  if (isMexico(firstLower) && isUnitedStates(lastLower)) {
    return 'MEXUSA';
  } else if (isUnitedStates(firstLower) && isMexico(lastLower)) {
    return 'USAMEX';
  } else if (isCanada(firstLower) && isUnitedStates(lastLower)) {
    return 'CANUSA';
  } else if (isUnitedStates(firstLower) && isCanada(lastLower)) {
    return 'USACAN';
  } else if (isCanada(firstLower) && isMexico(lastLower)) {
    return 'CANMEX';
  } else if (isMexico(firstLower) && isCanada(lastLower)) {
    return 'MEXCAN';
  }
}
