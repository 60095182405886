// Material UI Imports

import { createMuiTheme } from '@material-ui/core';

// Creates a Theme for MUI accessible by entire App
const theme = createMuiTheme({
  palette: {
    primary: { main: '#32489E', hover: '#5169C6' },
    secondary: { main: '#6D6E70', hover: '#4B5157' },
    default: { main: '#E6E6E6', hover: '#676767' },
    blueSky: { main: '#29AAE2' },
    risingSun: { main: '#F79520' },
    vastField: { main: '#1FDB74' },
    danger: { main: '#EE164A' },
    nightSky: { main: '#29AAE2' },
    moonWalk: { main: '#2D2D2D' },
    motherEarth: { main: '6D6E70' },
    white: { main: '#FFF' },
    whiteMarble: { main: '#E6E6E6' },
    soLight: { main: '#EEE' },
    background: {
      dark: 'linear-gradient(216.33deg, #29323C 12.92%, #484863 96.82%)',
    },
  },

  // Overide MUI Picker Styles
  overrides: {
    MuiTypography: {
      h1: {
        fontSize: 24,
        color: '#000',
        fontWeight: 'bold',
      },
      h2: {
        fontSize: 22,
        fontWeight: 'bold',
        color: 'rgba(18,21,78,.8)',
      },
      h3: {
        fontSize: 20,
        fontWeight: 'bold',
        color: 'rgba(18,21,78,.8)',
      },
      h4: {
        fontSize: 18,
        fontWeight: 'bold',
        color: 'rgba(18,21,78,.8)',
      },
      h5: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#000',
      },
      h6: {
        fontSize: 16,
        fontWeight: 'bold',
        color: 'rgba(18,21,78,.8)',
      },
      overline: {
        fontSize: '0.65rem',
        letterSpacing: '0.03333em',
        textTransform: 'inherit',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        fontSize: 16,
      },
      contained: {
        color: '#676767',
      },
      containedPrimary: {
        color: '#fff',
      },
      containedSecondary: {
        '&:hover': {
          backgroundColor: '#5169C6',
        },
      },
    },
    MuiFab: {
      root: {
        textTransform: 'none',
      },
      primary: {
        color: '#fff',
      },
      secondary: {
        '&:hover': {
          backgroundColor: '#5169C6',
        },
      },
    },
    MuiChip: {
      // Disables the default colors
      root: {
        borderRadius: 5,
        margin: 3,
        fontSize: 14,
        boxShadow:
          '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);',
      },
      clickable: {
        '&:focus': {
          backgroundColor: '$labelcolor',
        },
        '&:active': {
          backgroundColor: '$labelcolor',
        },
        '&:hover, &:focus': {
          backgroundColor: '$labelcolor',
        },
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundImage:
          'linear-gradient(216.33deg, #29323C 12.92%, #484863 96.82%)',
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: '#fff',
      },
      toolbarBtnSelected: {
        color: '#fff',
      },
    },
    MuiPickersDay: {
      daySelected: {
        color: '#fff',
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: '0.65rem',
        marginTop: 2,
      },
    },
    MuiCard: {
      root: {
        overflow: 'visible',
      },
    },

    MuiFormLabel: {
      root: {
        color: '#6e7881',
        '&$disabled': {
          color: '#6e7881',
        },
      },
    },
    MuiInputBase: {
      root: {
        color: '#18191a',
        '&$disabled': {
          color: '#18191a',
        },
      },
    },
  },
});

export default theme;
