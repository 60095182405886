import {
  OperationHoursModal,
  RelatedCustomsBrokers,
  RelatedFacilities,
} from '../../../components/CustomerSystem';
import React, { useEffect, useState } from 'react';
import { getAxios, postAxios } from '../../../util';

import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useAuth0 } from '../../../react-auth0-wrapper';
import { deleteAxios } from '../../../util/axiosHelper';

const OperationsView = ({ companyId }) => {
  const [shouldUpdate, forceUpdate] = useState(false);
  const [isOperationHoursModalOpen, changeIsOperationHoursModalOpen] = useState(
    false
  );
  const [facilityDetails, setFacilityDetails] = useState({});

  const [canCustomsBrokers, setCanCustomsBrokers] = useState([]);
  const [usaCustomsBrokers, setUsaCustomsBrokers] = useState([]);
  const [mexCustomsBrokers, setMexCustomsBrokers] = useState([]);

  const [relatedFacilities, setRelatedFacilities] = useState([]);
  const { accessToken } = useAuth0();

  const createCustomsBroker = (companyId, chbCompanyId) => {
    postAxios(
      'customer/preferred-customs-brokers',
      { companyId, chbCompanyId },
      accessToken
    ).then(() => {
      forceUpdate(!shouldUpdate);
    });
  };

  const addFacility = (companyId, facilityId) => {
    postAxios(
      'customer/add-related-facility',
      { companyId, facilityId },
      accessToken
    ).then(() => {
      forceUpdate(!shouldUpdate);
    });
  };

  const removeFacility = (companyId, facilityData) => {
    const { facilityId } = facilityData;
    deleteAxios(
      'customer/remove-related-facility',
      { companyId, facilityId },
      accessToken
    ).then(() => {
      forceUpdate(!shouldUpdate);
    });
  };

  const removeCustomsBroker = (companyId, brokersData) => {
    const { customsBrokerCompanyId } = brokersData;
    deleteAxios(
      'customer/remove-customs-broker',
      { companyId, customsBrokerCompanyId },
      accessToken
    ).then(() => {
      forceUpdate(!shouldUpdate);
    });
  };

  useEffect(() => {
    const getRelatedFacilities = () => {
      return getAxios(
        'customer/related-facilities',
        { params: { companyId } },
        accessToken
      ).then(facilities => {
        setRelatedFacilities(facilities || []);
      });
    };

    const getCustomsBrokers = (country, setCallback) => {
      getAxios(
        'customer/preferred-customs-brokers',
        { params: { country, companyId } },
        accessToken
      ).then(customsBrokers => {
        setCallback(customsBrokers || []);
      });
    };

    getCustomsBrokers('US', setUsaCustomsBrokers);
    getCustomsBrokers('MX', setMexCustomsBrokers);
    getCustomsBrokers('CA', setCanCustomsBrokers);
    getRelatedFacilities();
  }, [accessToken, companyId, shouldUpdate]);

  const handleShowMoreClick = facility => {
    setFacilityDetails(facility);
    changeIsOperationHoursModalOpen(true);
  };

  const handleCloseOperationHoursModal = () => {
    changeIsOperationHoursModalOpen(false);
    setFacilityDetails({});
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <OperationHoursModal
            isOpen={!!facilityDetails && isOperationHoursModalOpen}
            handleClose={handleCloseOperationHoursModal}
            details={facilityDetails}
            companyId={companyId}
          />
          <Grid item xs={4}>
            <RelatedCustomsBrokers
              title="Canadian Customs Brokers"
              handleAdd={createCustomsBroker}
              handleRemove={removeCustomsBroker}
              customsBrokers={canCustomsBrokers}
              companyId={companyId}
              country={'CA'}
            />
          </Grid>
          <Grid item xs={4}>
            <RelatedCustomsBrokers
              title="US Customs Brokers"
              handleAdd={createCustomsBroker}
              handleRemove={removeCustomsBroker}
              customsBrokers={usaCustomsBrokers}
              companyId={companyId}
              country={'US'}
            />
          </Grid>
          <Grid item xs={4}>
            <RelatedCustomsBrokers
              title="Mexico Customs Brokers"
              handleAdd={createCustomsBroker}
              handleRemove={removeCustomsBroker}
              customsBrokers={mexCustomsBrokers}
              handleShowMoreClick={handleShowMoreClick}
              companyId={companyId}
              country={'MX'}
            />
          </Grid>
          <Grid item xs={4}>
            <RelatedFacilities
              title="Related Facilities"
              handleAdd={addFacility}
              handleRemove={removeFacility}
              facilities={relatedFacilities}
              handleShowMoreClick={handleShowMoreClick}
              companyId={companyId}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

OperationsView.propTypes = {
  customerId: PropTypes.string.isRequired,
};

export default OperationsView;
