import axios from 'axios';
import * as Sentry from '@sentry/browser';

export async function getAxios(endpoint, params = {}, token) {
  const jwt = {
    headers: {
      Authorization: 'Bearer ' + token,
    },
    ...params,
  };

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/${endpoint}`,
      token ? jwt : { params }
    );

    return response.data;
  } catch (error) {
    console.log('🚨🚨🚨 ', error);
    Sentry.captureException(error);
    return error.response && error.response.data;
  }
}

export async function postAxios(endpoint, payload = {}, token) {
  const options = {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/${endpoint}`,
      { payload },
      token ? options : {}
    );
    return response.data;
  } catch (error) {
    console.log('🚨🚨🚨 ', error);
    Sentry.captureException(error);
    return error.response.data;
  }
}

export async function putAxios(endpoint, payload = {}, token) {
  const jwt = {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  };

  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/${endpoint}`,
      payload,
      token && jwt
    );
    return response.data;
  } catch (error) {
    console.log('🚨🚨🚨 ', error);
    Sentry.captureException(error);
    return error.response.data;
  }
}

export async function deleteAxios(endpoint, payload = {}, token) {
  const configObject = {
    headers: {
      Authorization: 'Bearer ' + token,
    },
    data: payload,
  };

  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/${endpoint}`,
      configObject
    );

    return response.data;
  } catch (error) {
    console.log('🚨🚨🚨 ', error);
    Sentry.captureException(error);
    return error.response.data;
  }
}
