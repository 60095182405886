import React from 'react';
import { Grid } from '@material-ui/core';
import { AccountReps } from '../../components/common/AccountReps';
import { LoadsTable } from '../../components/LoadSystem';

const Dashboard = () => {
  const tables = ['inTransit', 'planned', 'history'];
  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <Grid container spacing={2}>
          {tables.map(table => {
            return (
              <Grid item xs={12} key={table}>
                <LoadsTable table={table} limit={3} />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <AccountReps />
      </Grid>
    </Grid>
  );
};

export default Dashboard;
