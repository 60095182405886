import React, { useEffect, useState } from 'react';
import { useAuth0 } from '../react-auth0-wrapper';
import {
  Avatar,
  CardContent,
  CardHeader,
  makeStyles,
  Typography,
} from '@material-ui/core';

import { ShadowCard } from '../components/common/Containers';
import { Loader } from '../components/common/Loader';
import { Email, Text, Phone } from '../components/common/CardContent';

import { getAxios } from '../util';

const MyProfile = () => {
  const classes = useStyles();
  const { loading, user, accessToken } = useAuth0();
  const { name, picture, email } = user;
  const [profile, setProfile] = useState();

  useEffect(() => {
    getAxios('profile/my-profile', {}, accessToken).then(data => {
      setProfile(data);
    });
  }, [accessToken]);

  if (loading || !profile) {
    return <Loader />;
  }

  return (
    <ShadowCard className={classes.root}>
      <CardHeader
        avatar={<Avatar className={classes.avatar} alt={name} src={picture} />}
        title={<Typography variant="h2">{name}</Typography>}
        subheader={
          <span>
            {profile.title} <br />
            {profile.dbaName ? profile.dbaName : profile.legalName}
            <br /> {profile.location}
          </span>
        }
      />
      <CardContent>
        <Email email={email} label="Email" name="email" />
        <Phone
          number={profile.cellPhone}
          label="Cell Phone"
          name="Cell Phone"
          canSms
        />
        <Phone
          number={profile.officePhone}
          label="Office Phone"
          name="Office Phone"
        />
        <Text label="Ext." name="ext" value={profile.officePhoneExt} />
        <Phone number={profile.fax} label="Fax" name="Fax" isFax />
      </CardContent>
    </ShadowCard>
  );
};

const useStyles = makeStyles({
  root: {
    maxWidth: '500px',
    minHeight: '400px',
  },
  avatar: {
    marginRight: 10,
    width: 100,
    height: 100,
  },
});

export default MyProfile;
