/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core';
import { getAxios, postAxios } from '../../util';
import { useAuth0 } from '../../react-auth0-wrapper';
import { ShadowCard } from '../common/Containers';
import { CardHead } from '../common/CardContent';
import { SaveEditIconButton } from '../common/Buttons';

const CustomerCommodity = ({ companyId }) => {
  const classes = useStyles();
  const [edit, setEdit] = useState(false);
  const { accessToken } = useAuth0();
  const [commodityOptions, setCommodityOptions] = useState([]);
  const [commodities, setCommodities] = useState([]);
  const [filteredCommodityOptions, setFilteredCommodityOptions] = useState([]);

  //Post axios sending new selected commodities to the backend needs to be done

  const saveCommodities = () => {
    postAxios('customer/commodities', { companyId, commodities }, accessToken);
  };

  const handleEdit = () => setEdit(true);

  const handleSave = () => {
    saveCommodities();
    setEdit(false);
  };

  const handleChange = (event, value) => {
    setCommodities(value);
  };

  useEffect(() => {
    // Create comparable array of commodity IDs
    const ids = commodities.map(com => com.id);
    // Check options agains IDs
    const filteredOptions = commodityOptions.filter(option => {
      return ids.indexOf(option.id) === -1;
    });
    // Set filtered options array
    setFilteredCommodityOptions(filteredOptions);
  }, [commodityOptions, commodities]);

  useEffect(() => {
    getAxios('customer/commodity-options', {}, accessToken).then(data => {
      setCommodityOptions(data);
      return;
    });
    getAxios(
      'customer/commodities',
      { params: { companyId } },
      accessToken
    ).then(data => {
      setCommodities(data);
      return;
    });
  }, [accessToken, companyId]);

  return (
    <ShadowCard>
      <CardHead
        headingText="Commodities"
        editable
        action={
          <SaveEditIconButton
            edit={edit}
            onClick={edit ? handleSave : handleEdit}
          />
        }
      />
      <Autocomplete
        multiple
        disabled={!edit}
        onChange={handleChange}
        options={filteredCommodityOptions}
        getOptionLabel={option => option.commodity}
        filterSelectedOptions
        value={commodities}
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={edit ? 'Select a commodity' : ''}
            margin="normal"
            fullWidth
            className={!edit && classes.deleteTag}
          />
        )}
      />
    </ShadowCard>
  );
};

const useStyles = makeStyles({
  deleteTag: {
    '& .MuiChip-deleteIcon': {
      display: 'none',
    },
  },
});

export default CustomerCommodity;
