import {
  Button,
  Grid,
  List,
  ListItem,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { CardHead, Selector } from '../common/CardContent';
import { DeleteButton, SaveEditIconButton } from '../common/Buttons';
import React, { useCallback, useEffect, useState } from 'react';
import { deleteAxios, getAxios, postAxios } from '../../util';

import { ShadowCard } from '../common/Containers';
import { connect } from 'formik';
import { useAuth0 } from '../../react-auth0-wrapper';

const Requirements = ({
  loadId,
  isForager,
  formik,
  setRequirements,
  editable,
  newLoad,
}) => {
  const classes = useStyles();
  const { accessToken } = useAuth0();
  const [amount, setAmount] = useState('');
  const [edit, setEdit] = useState(editable);
  // used to track the selected requirement (option dropdown)
  const [selectedRequirement, setSelectedRequirement] = useState(0);
  // used for requirements already saved in the db
  const [existingRequirements, setExistingRequirements] = useState([]);
  // used to populate the requirement options dropdown
  const [requirementOptions, setRequirementOptions] = useState({});
  const { setFieldValue } = formik;

  const getRequirementOptions = useCallback(() => {
    getAxios('load/load-requirement-options', {}, accessToken)
      .then(res => {
        const options = res.reduce((sum, x) => {
          sum[Number(x.id)] = x.type;
          return sum;
        }, {});
        setRequirementOptions({ 0: 'Requirements', ...options });
      })
      .catch(err => console.log(err));
  }, [accessToken]);

  const getLoadRequirements = useCallback(() => {
    // dont run axios call when creating a new load (i.e. when loadId is undefined)
    loadId &&
      getAxios('load/load-requirements', { params: { loadId } }, accessToken)
        .then(res => {
          // make sure res is at the very least, an array
          res.length > 0 && setExistingRequirements(res);
          if (setRequirements) setRequirements(res);
        })
        .catch(err => console.log(err));
  }, [loadId, accessToken]); // eslint-disable-line

  // get existing load requirements and requirement options on initial render
  useEffect(() => {
    getLoadRequirements();
    getRequirementOptions();
  }, [loadId, accessToken, getLoadRequirements, getRequirementOptions]);

  const addRequirement = () => {
    postAxios(
      'load/load-requirements',
      { loadId, selectedRequirement, amount },
      accessToken
    )
      .then(() => {
        getLoadRequirements();
      })
      .catch(err => console.log(err));
    // clear inputs
    setAmount('');
    setSelectedRequirement(0);
  };

  const addRequirementOnCreate = () => {
    // when there is no loadId (customer is on the create screen)
    // save any new requirements to the formik array
    const newRequirements = [
      ...formik.values.requirements,
      {
        selectedRequirement,
        amount,
        type: requirementOptions[selectedRequirement],
      },
    ];
    setFieldValue('requirements', newRequirements);
    // when displaying to the user, show requirement text

    setExistingRequirements(newRequirements);
    setAmount('');
    setSelectedRequirement(0);
  };

  const deleteRequirement = requirementId => {
    if (!loadId || newLoad) {
      // delete from formik array
      const updatedRequirements = formik.values.requirements.filter(
        ({ requirementId: itemId }) => itemId !== requirementId
      );
      setFieldValue('requirements', updatedRequirements);
      setExistingRequirements(updatedRequirements);
    } else {
      // delete from database
      deleteAxios(
        'load/load-requirements',
        { loadId, requirementId },
        accessToken
      )
        .then(res => {
          getLoadRequirements();
        })
        .catch(err => console.log(err));
    }
  };

  return (
    <ShadowCard>
      <CardHead
        headingText="Requirements"
        action={
          isForager && (
            <SaveEditIconButton edit={edit} onClick={() => setEdit(!edit)} />
          )
        }
      />
      {/* list existing load requirements */}
      <List className={classes.list}>
        {existingRequirements.map((req, i) => (
          <Grid
            container
            direction="row"
            justify="space-between"
            key={req.requirementId || i}
          >
            <Grid item xs={7}>
              <ListItem disableGutters>
                <Typography>{req.type}</Typography>
              </ListItem>
            </Grid>
            <Grid item xs={3}>
              <ListItem>
                <Typography>{req.amount}</Typography>
              </ListItem>
            </Grid>
            {/* if customer AND there is no load id (i.e. the customer is creating
            a load), OR if edit is set to true */}
            {((!isForager && !loadId) || edit) && (
              <Grid item xs={2}>
                <Grid
                  container
                  justify="flex-end"
                  className={classes.buttonWrapper}
                >
                  <DeleteButton
                    onClick={() => deleteRequirement(req.requirementId, i)}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        ))}
      </List>
      {/* add a new load requirement */}
      {((!isForager && !loadId) || edit) && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Selector
              value={selectedRequirement || ''}
              options={requirementOptions}
              editHook={setSelectedRequirement}
              edit
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              className={classes.amountInput}
              value={amount}
              onChange={e => setAmount(e.target.value)}
              placeholder="Amount"
              type="number"
              inputProps={{ style: { textAlign: 'right' } }}
            />
          </Grid>
          <Grid item xs={12} container justify="flex-end">
            <Button
              color="primary"
              variant="contained"
              className={classes.button}
              onClick={
                loadId && !newLoad ? addRequirement : addRequirementOnCreate
              }
              size="small"
              disabled={selectedRequirement === 0 || !amount}
            >
              Add
            </Button>
          </Grid>
        </Grid>
      )}
    </ShadowCard>
  );
};

const useStyles = makeStyles({
  list: {
    maxHeight: '370px',
    overflow: 'auto',
    margin: '15px 0',
  },
  amountInput: {
    '& ::placeholder': {
      textAlign: 'right',
    },
  },
  button: { color: 'fff' },
  buttonWrapper: { paddingTop: '5px' },
});

export default connect(Requirements);
