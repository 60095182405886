import { Button, Grid, List, makeStyles } from '@material-ui/core';
import { CardHead, Suggest } from '../../common/CardContent';
import React, { useCallback, useEffect, useState } from 'react';
import { getAxios, postAxios, putAxios } from '../../../util/axiosHelper';

import CustomerRepDefault from './CustomerRepDefault';
import { SaveEditIconButton } from '../../common/Buttons';
import { ShadowCard } from '../../common/Containers';
import { useAuth0 } from '../../../react-auth0-wrapper';

const CustomerReps = ({ companyId }) => {
  const classes = useStyles();
  const [edit, setEdit] = useState(false);
  const { accessToken } = useAuth0();
  const [reps, setReps] = useState([]);
  const [repName, setRepName] = useState('');
  const [userId, setUserId] = useState(null);

  const getReps = useCallback(() => {
    getAxios(
      'company/company-reps/all',
      { params: { companyId } },
      accessToken
    ).then(data => {
      setReps(data);
    });
  }, [accessToken, companyId]);

  const handleEdit = () => setEdit(true);

  const handleSave = () => {
    setEdit(false);
  };

  const handleAddRepClick = () => {
    postAxios('company/add-rep', { userId, companyId }, accessToken).then(
      () => {
        getReps();
      }
    );
  };

  const handleOptionClick = rep => {
    setRepName(rep.name);
    setUserId(rep.id);
  };

  const deactivateRep = userId => {
    const payload = {
      userId,
      companyId,
      active: false,
    };
    putAxios('company/deactivate-rep', { payload }, accessToken).then(() => {
      getReps();
    });
  };

  const activateRep = userId => {
    const payload = {
      userId,
      companyId,
    };
    putAxios('company/activate-rep', { payload }, accessToken).then(() => {
      getReps();
    });
  };

  const makeRepMain = userId => {
    const payload = {
      userId,
      companyId,
    };
    putAxios('company/make-main-rep', { payload }, accessToken).then(() => {
      getReps();
    });
  };

  useEffect(() => {
    getReps();
  }, [getReps]);

  return (
    <ShadowCard className={classes.content}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CardHead
            headingText="Customer Reps"
            editable
            action={
              <SaveEditIconButton
                edit={edit}
                onClick={edit ? handleSave : handleEdit}
              />
            }
          />
        </Grid>
        <Grid item xs={12}>
          {edit && (
            <Grid container>
              <Grid item xs={4}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={handleAddRepClick}
                >
                  Add
                </Button>
              </Grid>
              <Grid item xs={8}>
                <Suggest
                  valuesToExclude={companyId}
                  placeholder="Customer Reps"
                  value={repName}
                  edit
                  name="repName"
                  urlString="company/autocomplete/reps"
                  editHook={rep => handleOptionClick(rep)}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      <List>
        {reps &&
          reps.map((rep, i) => (
            <CustomerRepDefault
              key={i}
              repData={rep}
              edit={edit}
              deactivateRep={deactivateRep}
              activateRep={activateRep}
              makeRepMain={makeRepMain}
            />
          ))}
      </List>
    </ShadowCard>
  );
};

const useStyles = makeStyles({
  content: {
    minHeight: 300,
    maxHeight: 400,
    overflow: 'auto',
  },
});

export default CustomerReps;
