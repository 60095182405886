import React from 'react';
import { Grid } from '@material-ui/core';
import {
  CustomsBrokerInfo,
  CustomsBrokerSpecs,
} from '../../components/CustomsBrokerSystem';
import { MainContact } from '../../components/common/Contacts';

const CustomsBrokerMainView = ({ companyId }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <CustomsBrokerInfo companyId={companyId} />
          </Grid>
          <Grid item xs={4}>
            <CustomsBrokerSpecs companyId={companyId} />
          </Grid>
          <Grid item xs={4}>
            <MainContact companyId={companyId} getPath="company/main-contact" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CustomsBrokerMainView;
