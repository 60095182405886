import React from 'react';
import { useAuth0 } from '../../../react-auth0-wrapper';
import userHasScopes from '../../../util/userHasScopes';

const PrivateComponent = ({ children, reqScopes }) => {
  const { scopes } = useAuth0();
  const handleRender = reqScopes => {
    if (reqScopes) {
      if (userHasScopes(reqScopes, scopes)) {
        return <>{children}</>;
      } else {
        return null;
      }
    }
  };

  return handleRender(reqScopes);
};

export default PrivateComponent;
