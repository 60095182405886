import { Input, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';

import { CardItem } from './';
import validator from 'validator';

const Website = ({
  website,
  editHook,
  setValid,
  name,
  label,
  edit,
  errorText,
  error,
  required,
}) => {
  const classes = useStyles();
  setValid = setValid ? setValid : (setValid = () => '');
  editHook = editHook ? editHook : (editHook = () => '');

  const createLink = website => {
    // check if the input contains either http or https
    const re = new RegExp('^(http|https)://', 'i');
    const match = re.test(website);
    // if theres no match then append http to the href value
    const hyperText = match ? '' : 'http://';

    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`${hyperText + website}`}
      >
        {website}
      </a>
    );
  };

  useEffect(() => {
    if (required && website && validator.isURL(website ? website : '')) {
      setValid({ [name]: true });
    } else if (
      !required &&
      (validator.isURL(website ? website : '') || website === '')
    ) {
      setValid({ [name]: true });
    } else {
      setValid({ [name]: false });
    }
  }, [website, setValid, name, required]);

  return (
    <CardItem
      label={label}
      name={name}
      error={error}
      errorText={errorText}
      content={
        edit ? (
          <Input
            className={classes.input}
            onChange={e => editHook(e.target.value, name)}
            value={website}
          />
        ) : (
          <Typography align="right" noWrap>
            {website ? createLink(website) : '-'}
          </Typography>
        )
      }
    />
  );
};

const useStyles = makeStyles({
  input: {
    '& input': {
      padding: 0,
      textAlign: 'right',
    },
  },
});

export default Website;
