import React, { useState, useEffect } from 'react';
import { ShadowCard } from '../../../components/common/Containers';
import { FilterTable, RateCell } from '../../../components/common/Tables';
import { Loader } from '../../../components/common/Loader';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import { getAxios } from '../../../util/axiosHelper';
import { useAuth0 } from '../../../react-auth0-wrapper';
import { mexBorderCrossings } from '../../../util/options';

const RatesView = ({ companyId }) => {
  const [myRatesData, setMyRatesData] = useState(null);
  const { accessToken } = useAuth0();

  useEffect(() => {
    getAxios(
      'pricing/rates-history',
      { params: { companyId } },
      accessToken
    ).then(data => {
      setMyRatesData(data);
    });
  }, [accessToken, companyId]);

  const columns = [
    {
      title: 'Quote Date',
      defaultSort: 'desc',
      field: 'quoteDate',
      render: rowData => moment(rowData.quoteDate).format('MM/DD/YYYY'),
    },
    { title: 'Equip', field: 'eqType' },
    { title: 'Origin', field: 'origin' },
    { title: 'Destination', field: 'destination' },
    {
      title: 'Crossing',
      field: 'crossingId',
      render: rowData =>
        rowData.crossingId && mexBorderCrossings[rowData.crossingId],
    },
    { title: 'Hazmat', field: 'hazmat', type: 'boolean' },
    { title: 'Team', field: 'team', type: 'boolean' },
    {
      title: 'Next Day',
      field: 'nextday',
      render: ({ nextday }) => nextday ? <RateCell { ...nextday } /> : '',

    },
    {
      title: '48-Hour Lead Time',
      field: 'leadtime',
      render: ({ leadtime }) => leadtime ? <RateCell { ...leadtime } /> : '',
    },
    {
      title: 'Weekend',
      field: 'weekend',
      render: ({ weekend }) => weekend ? <RateCell { ...weekend } /> : '',
    },
    {
      title: 'Customer Generated',
      field: 'customerGenerated',
      type: 'boolean',
    },
    {
      title: 'Generated By',
      field: 'generatedBy',
    },
    {
      title: 'Company',
      field: 'companyName',
    },
    {
      title: 'Expire Date',
      field: 'expireDate',
      render: rowData =>
        moment(rowData.quoteDate)
          .add(30, 'days')
          .format('MM/DD/YYYY'),
    },
  ];

  return (
    <ShadowCard noTopPadding>
      {!myRatesData ? (
        <Loader />
      ) : (
        <FilterTable
          columns={columns}
          data={myRatesData && myRatesData}
          title="My Rates"
          showActions={false}
          filtering
          noSearch
        />
      )}
    </ShadowCard>
  );
};

export default RatesView;
