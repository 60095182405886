import {
  FormControl,
  FormHelperText,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect } from 'react';

import { Typeahead } from '../Typeahead';

const StackedSuggest = ({
  label,
  value,
  name,
  edit,
  editHook,
  errorText,
  required,
  setValid,
  error,
  menuCta,
  urlString,
  placeholder,
}) => {
  const classes = useStyles();
  setValid = setValid ? setValid : (setValid = () => '');
  editHook = editHook ? editHook : (editHook = () => '');
  error = error ? error : {};

  // Validate Intial Value
  useEffect(() => {
    if (required && value) {
      setValid({ [name]: true });
    } else if (!required) {
      setValid({ [name]: true });
    } else {
      setValid({ [name]: false });
    }
  }, [name, required, setValid, value]);

  return (
    <FormControl className={classes.formControl}>
      {edit ? (
        <>
          <FormHelperText>{label}</FormHelperText>
          <Typeahead
            menuCta={menuCta}
            urlString={urlString}
            value={value}
            onChange={value => editHook(value, name)}
            placeholder={placeholder}
          />
        </>
      ) : (
        <>
          <FormHelperText>{label}</FormHelperText>
          <Typography align="left" noWrap className={classes.text}>
            {value ? value : '-'}
          </Typography>
        </>
      )}
      <FormHelperText error>{error[name] && errorText}</FormHelperText>
    </FormControl>
  );
};

const useStyles = makeStyles({
  formControl: {
    width: '100%',
  },
  text: {
    height: 29,
  },
  input: {
    height: 24,
    margin: '5px 0 0',
    '& input': {
      padding: 0,
    },
  },
});

export default StackedSuggest;
