import { List, makeStyles } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';

import { CardHead } from '../../common/CardContent';
import { Loader } from '../Loader';
import { Rep } from './';
import { ShadowCard } from '../../common/Containers';
import { getAxios } from '../../../util/axiosHelper';
import { useAuth0 } from '../../../react-auth0-wrapper';

const AccountReps = ({ verifiedCustomerId, isForager }) => {
  const classes = useStyles();
  const { accessToken } = useAuth0();
  const [loading, setLoading] = useState();
  const [accountReps, setAccountReps] = useState([]);

  const getReps = useCallback(() => {
    const params = verifiedCustomerId ? { verifiedCustomerId } : {};
    if (isForager && !verifiedCustomerId) {
      return;
    }
    getAxios('customer-portal/my-reps', { params }, accessToken).then(data => {
      setAccountReps(data);
      setLoading(false);
    });
  }, [accessToken, isForager, verifiedCustomerId]);
 
  useEffect(() => {
    setLoading(true);

    getReps();
  }, [getReps]);

  return (
    <ShadowCard className={classes.content} data-testid="account-reps">
      <CardHead headingText="Account Reps" />
      {loading ? (
        <Loader />
      ) : (
        <List>
          {accountReps && accountReps.length > 0 ? (
            accountReps.map((data, i) => <Rep key={i} {...data} />)
          ) : (
            <div data-testid="no-data-error">Error</div>
          )}
        </List>
      )}
    </ShadowCard>
  );
};

const useStyles = makeStyles({
  content: {
    maxHeight: 420,
    overflow: 'auto',
  },
});

export default AccountReps;
