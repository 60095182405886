import React from 'react';
import { Grid } from '@material-ui/core';
import { AllFacilities } from '../../components/FacilitySystem';

const All = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AllFacilities />
      </Grid>
    </Grid>
  );
};

export default All;
