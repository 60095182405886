import { Input, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';

import { CardItem } from './';

const Email = ({
  email,
  setValid,
  editHook,
  label,
  required,
  error,
  edit,
  name,
  errorText,
}) => {
  const classes = useStyles();
  // Set email default value to empty string if null
  setValid = setValid ? setValid : (setValid = () => '');
  editHook = editHook ? editHook : (editHook = () => '');

  useEffect(() => {
    if (required && email) {
      setValid({ [name]: true });
    } else if (!required) {
      setValid({ [name]: true });
    } else {
      setValid({ [name]: false });
    }
  }, [email, setValid, name, required]);

  useEffect(() => {
    const atTest = /[@]/g;
    // If email is required, a valid email must be entered (no blanks)
    if (required && atTest.test(email)) {
      setValid({ [name]: true });
      // If not required email must be either blank or valid
    } else if (!required && (email === '' || atTest.test(email))) {
      setValid({ [name]: true });
      // Otherwise set error
    } else {
      setValid({ [name]: false });
    }
  }, [email, setValid, name, required]);

  let content = edit ? (
    <Input
      className={classes.input}
      onChange={e => editHook(e.target.value, name)}
      value={email}
    />
  ) : email ? (
    <a href={`mailto:${email}`}>{email}</a>
  ) : (
    '-'
  );

  return (
    <CardItem
      label={label}
      name={name}
      error={error}
      errorText={errorText}
      content={content}
    />
  );
};

const useStyles = makeStyles({
  input: {
    width: '100%',
    height: 24,
    '& input': {
      padding: 0,
      textAlign: 'right',
    },
  },
});

export default Email;
