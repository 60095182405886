import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import {
  CustomerInfo,
  AccountManagement,
  CustomerContractedRate,
  CustomerInformation,
} from '../../../components/CustomerSystem';

import { MainContact } from '../../../components/common/Contacts';
import { CustomerReps } from '../../../components/common/AccountReps';
import CustomerCommodity from '../../../components/CustomerSystem/CustomerCommodity';

const MainView = ({ match }) => {
  const customerId = match.params.id;

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <CustomerInfo customerId={customerId} />
      </Grid>
      <Grid item xs={4}>
        <MainContact companyId={customerId} getPath="company/main-contact" />
      </Grid>
      <Grid item xs={4}>
        <CustomerInformation companyId={customerId} />
      </Grid>

      <Grid item xs={4}>
        <CustomerReps companyId={customerId} />
      </Grid>
      <Grid item xs={4}>
        <AccountManagement customerId={customerId} />
      </Grid>
      <Grid item xs={4}>
        <CustomerCommodity companyId={customerId} />
      </Grid>
      <Grid item xs={12}>
        <CustomerContractedRate companyId={customerId} />
      </Grid>
    </Grid>
  );
};

MainView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default MainView;
