import React, { useEffect, useState } from 'react';

import { Loader } from '../common/Loader';
import PropTypes from 'prop-types';
import { TextAreaCard } from '../common/Notes';
import { connect } from 'formik';
import { getAxios } from '../../util/axiosHelper';
import { useAuth0 } from '../../react-auth0-wrapper';

const Notes = ({ loadId, formik }) => {
  const { accessToken } = useAuth0();
  const [loadNotFound, setLoadNotFound] = useState(false);
  const [loading, setLoading] = useState(true);

  const { setFieldValue } = formik;

  useEffect(() => {
    getAxios('load/notes', { params: { loadId } }, accessToken).then(data => {
      if (data) {
        setFieldValue(`loadNotes`, data.notes);
      } else {
        setLoadNotFound(true);
      }
      setLoading(false);
    });
  }, [accessToken, loadId, setFieldValue]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        !loadNotFound && (
          <TextAreaCard
            name="notes"
            maxCharacters={250}
            minHeight={80}
            maxHeight={350}
            rows={5}
          />
        )
      )}
    </>
  );
};

Notes.propTypes = {
  loadId: PropTypes.string.isRequired,
};

export default connect(Notes);
