import { Grid, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';

import { CardItem } from '.';
import DatePicker from 'react-date-picker';
import momentTz from 'moment-timezone/builds/moment-timezone-with-data';

const DatePick = ({
  label,
  edit,
  editHook,
  name,
  date,
  required,
  setValid,
  error,
  errorText,
  enableCalendar,
  minDate,
  timeZone,
}) => {
  const classes = useStyles();
  // If this component is used as display only, set setValid to dummy function
  setValid = setValid ? setValid : () => '';
  errorText = errorText ? errorText : 'Please enter a valid Date';

  useEffect(() => {
    if (date === null && required) {
      setValid({ [name]: false });
    } else {
      setValid({ [name]: true });
    }
  }, [date, name, required, setValid]);

  const jsDate = date === null ? date : new Date(date);

  const content = edit ? (
    <Grid container justify="flex-end">
      <DatePicker
        value={jsDate}
        required={required}
        data-testid={name}
        disableCalendar={!enableCalendar}
        className={classes.date}
        format="MM-d-y"
        onChange={date => {
          editHook(date && date, name);
        }}
      />
    </Grid>
  ) : (
    <Typography data-testid="datetimedisplay">
      {date
        ? `${momentTz(date).format('MM-DD-YY')} ${momentTz
            .tz(date, timeZone)
            .format('z')}`
        : ' - '}
    </Typography>
  );

  return (
    <CardItem
      label={label}
      name={name}
      error={error}
      errorText={errorText}
      content={content}
      testId={name}
    />
  );
};

const useStyles = makeStyles({
  date: {
    flex: 1,
    fontSize: '1rem',
    marginRight: 2,
    '& .react-date-picker__wrapper': {
      border: 'none',
      borderBottom: '1px solid black',
      height: 24,
      width: '100%',
      '& button': {
        padding: 0,
      },
    },
    '& .react-date-picker__clear-button': {
      backgroundColor: 'white',
      marginLeft: 2,
      outline: 'none',
    },
    '& .react-date-picker__inputGroup': {
      justifyContent: 'flex-end',
    },
  },
});

export default DatePick;
