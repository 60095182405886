// EXAMPLE:

// Data:
// const data = [
//   {"id":200064,"legalName":"Sofies Mexico DC","dbaName":"","name":"Sofies Mexico DC","address":"Patria 999","address2":"","city":"Guadalajara","state":"JAL","postalCode":"44230"},
//   {"id":200066,"legalName":"Sofies Mexico DC","dbaName":"","name":"El Mexico DC","address":"Patria 999","address2":"","city":"Guadalajara","state":"JAL","postalCode":"44230"}
// ]

// Use:
// const data2 = arrayToSelectorObject(data, "id")

// Result:
// {
//   200064: "Sofies Mexico DC",
//   200066: "El Mexico DC"
// }

const arrayToSelectorObject = (array, keyField) =>
  array.reduce((obj, item) => {
    obj[item[keyField]] =
      item.name || item.dbaName || item.legalName || item.commodity;
    return obj;
  }, {});

export default arrayToSelectorObject;
