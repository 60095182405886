import React from 'react';
import { Grid } from '@material-ui/core';
import { MainView } from './';
import { Route, Redirect } from 'react-router-dom';
import { Contacts } from '../../components/common/Contacts';

const FacilityProfile = ({ match }) => {
  const companyId = match.params.id;

  return (
    <Grid container spacing={2}>
      <Route
        path={`${match.path}/main`}
        render={props => <MainView companyId={companyId} {...props} />}
      />
      <Route
        path={`${match.path}/contacts`}
        render={props => (
          <Contacts
            id={companyId}
            getPath="company/contacts"
            putPath="company/contacts"
            rolesFor="facility"
            {...props}
          />
        )}
      />
      <Route
        exact
        path={`${match.path}`}
        render={props => <Redirect to={`${match.url}/main`} {...props} />}
      />
    </Grid>
  );
};

export default FacilityProfile;
