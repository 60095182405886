import {
  Address,
  CardHead,
  Phone,
  Selector,
  Text,
} from '../common/CardContent';
import { Button, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { Profile } from '../../views/CustomerSystem';
import { ShadowCard } from '../common/Containers';
import { catchErrors } from '../../util';
import options from '../../util/options';
import { postAxios } from '../../util/axiosHelper';
import { useAuth0 } from '../../react-auth0-wrapper';

const AddNewCustomer = () => {
  const { accessToken } = useAuth0();
  const [phoneValid, setPhoneValid] = useState(true);
  const [customerId, setCustomerId] = useState(null);

  // Validation
  const [error, setError] = useState({});
  const [legalNameValid, setLegalNameValid] = useState({ legalName: false });
  const [salesRepIdValid, setSalesRepIdValid] = useState({ salesRepId: false });
  const [addressValid, setAddressValid] = useState({ address: false });

  const [newCustomer, setNewCustomer] = useState({
    legalName: '',
    dba: null,
    address: '',
    address2: '',
    city: '',
    postalCode: '',
    state: '',
    phone: '',
    phoneExt: '',
    salesRepId: null,
    customerType: null, // default to first item
  });

  const createCustomer = () => {
    postAxios('customer/new-customer', { ...newCustomer }, accessToken).then(
      data => {
        setCustomerId(data.customerId);
        setError({});
      }
    );
  };

  const handleCreate = () => {
    catchErrors([legalNameValid, phoneValid, salesRepIdValid, addressValid])
      .then(() => {
        createCustomer();
      })
      .catch(errors => {
        setError(errors);
      });
  };

  if (customerId) {
    return (
      <Switch>
        <Redirect
          push
          component={Profile}
          to={`/customers/profile/id/${customerId}`}
        />
      </Switch>
    );
  }

  return (
    <ShadowCard>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CardHead editable={false} headingText="New Customer" />
        </Grid>
        <Grid item xs={12}>
          <Selector
            label="Customer Type"
            edit
            required
            value={newCustomer.customerType}
            name="customerType"
            options={options.customerTypes}
            editHook={(name, value) =>
              setNewCustomer({ ...newCustomer, [value]: name })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Text
            required
            errorText="This field is required"
            error={error}
            editHook={(text, name) =>
              setNewCustomer({ ...newCustomer, [name]: text })
            }
            value={newCustomer.legalName}
            label="Legal Name"
            edit
            name="legalName"
            setValid={setLegalNameValid}
          />
        </Grid>
        <Grid item xs={12}>
          <Phone
            required
            number={newCustomer.phone}
            edit
            name="phone"
            error={error}
            canSms
            label="Phone"
            errorText="Please enter a valid Phone Number"
            setValid={setPhoneValid}
            editHook={(phone, name) =>
              setNewCustomer({ ...newCustomer, [name]: phone })
            }
          />
          <Text
            value={newCustomer.phoneExt}
            edit
            name="phoneExt"
            label="Ext."
            editHook={(text, name) =>
              setNewCustomer({ ...newCustomer, [name]: text })
            }
            type="number"
          />
        </Grid>
        <Grid item xs={12}>
          <Text
            editHook={(text, name) =>
              setNewCustomer({ ...newCustomer, [name]: text })
            }
            value={newCustomer.dba}
            label="DBA"
            edit
            name="dba"
          />
        </Grid>
        <Grid item xs={12}>
          <Selector
            label="Sales Rep"
            edit
            required
            error={error}
            value={newCustomer.salesRepId}
            name="salesRepId"
            options={options.foragers}
            setValid={setSalesRepIdValid}
            editHook={(value, name) =>
              setNewCustomer({ ...newCustomer, [name]: value })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Address
            edit
            label="Address"
            disableSetSearchString
            address={newCustomer.address}
            addressName="address"
            address2={newCustomer.address2}
            address2Name="address2"
            city={newCustomer.city}
            cityName="city"
            postalCode={newCustomer.postalCode}
            postalCodeName="postalCode"
            state={newCustomer.state}
            stateName="state"
            editHook={location =>
              setNewCustomer({ ...newCustomer, ...location })
            }
            error={error}
            required
            errorText="Please enter a valid address"
            setValid={setAddressValid}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} justify="flex-end">
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                size="large"
                onClick={handleCreate}
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ShadowCard>
  );
};

export default AddNewCustomer;
