import { BulkUploader, RateCalculator, PortalRates } from '../views/ToolSystem';

import { PrivateRoute } from '../components/common/Privatize';
import React from 'react';

const ToolSystem = ({ match }) => {
  return (
    <>
      <PrivateRoute
        path={`${match.url}/instant-rate`}
        render={props => <RateCalculator {...props} forager />}
      />
      <PrivateRoute
        path={`${match.url}/bulk-upload`}
        render={props => <BulkUploader {...props} forager />}
        reqScope={['bulk-pricing:read']}
      />
      <PrivateRoute
        path={`${match.url}/portal-rates`}
        render={props => <PortalRates {...props} forager />}
        reqScope={['forager:read']}
      />
    </>
  );
};

export default ToolSystem;
