import {
  AddNew,
  AllCustomsBrokers,
  CustomsBrokerProfile,
} from '../views/CustomsBrokerSystem';

import { PrivateRoute } from '../components/common/Privatize';
import React from 'react';

const CustomsBrokerSystem = ({ match }) => {
  return (
    <>
      <PrivateRoute
        path={`${match.url}/profile/id/:id`}
        render={props => <CustomsBrokerProfile {...props} />}
      />
      <PrivateRoute
        path={`${match.url}/add-new`}
        render={props => <AddNew {...props} />}
        reqScope={['customs-broker:create']}
      />
      <PrivateRoute
        path={`${match.url}/all`}
        render={props => <AllCustomsBrokers {...props} />}
      />
    </>
  );
};

export default CustomsBrokerSystem;
