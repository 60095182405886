import React, { useEffect } from 'react';

import { Route } from 'react-router-dom';
import { useAuth0 } from '../../../react-auth0-wrapper';
import userHasScopes from '../../../util/userHasScopes';

const PrivateRoute = ({
  component: Component,
  path,
  reqScope,
  exact,
  ...rest
}) => {
  const { loading, isAuthenticated, loginWithRedirect, scopes } = useAuth0();
  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: path },
      });
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect, path]);

  const render = props => {
    if (isAuthenticated) {
      if (reqScope) {
        if (userHasScopes(reqScope, scopes)) {
          return <Component {...props} />;
        } else {
          return null;
        }
      }
      return <Component {...props} />;
    } else {
      return null;
    }
  };

  return <Route exact={exact} path={path} render={render} {...rest} />;
};

export default PrivateRoute;
