import React, { useEffect, useState } from 'react';

import { Currency } from '../../components/common/CardContent';
import { FilterTable } from '../../components/common/Tables';
import { Loader } from '../../components/common/Loader';
import { ShadowCard } from '../../components/common/Containers';
import { getAxios } from '../../util/axiosHelper';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import { useAuth0 } from '../../react-auth0-wrapper';
import EyeBallLinkCell from '../common/Tables/EyeBallLinkCell';

const AllLoads = ({ companyId }) => {
  const [loadsData, setLoadsData] = useState();
  const { accessToken } = useAuth0();

  const columns = [
    EyeBallLinkCell('/loads/id', 'loadNumber'),
    {
      title: 'Load #',
      field: 'loadNumber',
      render: ({ loadNumber }) => `FRGW-${loadNumber}`,
    },
    {
      title: 'Pickup Date',
      field: 'pickupDate',
      render: rowData => moment(rowData.pickupDate).format('MM/DD/YYYY'),
    },
    {
      title: 'Pickup',
      field: 'pickup',
    },
    {
      title: 'Delivery',
      field: 'delivery',
    },
    {
      title: 'Rate',
      field: 'rate',
      render: rowData => <Currency value={rowData.rate} tableFormat />,
    },
    {
      title: 'Status',
      field: 'status',
    },
    {
      title: 'State',
      field: 'state',
    },
  ];

  useEffect(() => {
    getAxios('customer/all-loads', { params: { companyId } }, accessToken).then(
      data => {
        if (data) {
          setLoadsData(data);
        }
      }
    );
  }, [accessToken, companyId]);

  return (
    <ShadowCard noTopPadding>
      {!loadsData && loadsData !== undefined ? (
        <Loader />
      ) : (
        <FilterTable
          columns={columns}
          data={loadsData}
          title="Loads"
          filtering
          noFooter
          noSearch
        />
      )}
    </ShadowCard>
  );
};

export default AllLoads;
