import React from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles } from '@material-ui/core';
import { Selector, Currency, Text } from '../../common/CardContent';
import { feeTypes } from '../../../util/options';
import { titleCase, numberToUSD } from '../../../util';
import { DeleteButton } from '../../common/Buttons';

const FeeItem = ({
  id,
  edit,
  rateTypeId,
  value,
  handleChange,
  handleDelete,
  isDeleteable,
}) => {
  const classes = useStyles();
  return edit ? (
    <Grid container spacing={2} alignItems="flex-end">
      <Grid item xs={6}>
        <Selector
          name="rateTypeId"
          value={rateTypeId}
          options={feeTypes}
          edit
          editHook={handleChange}
        />
      </Grid>
      <Grid item xs={4}>
        <Currency name="value" value={value} edit editHook={handleChange} />
      </Grid>
      <Grid item xs={2} className={classes.btnGrid}>
        {isDeleteable && <DeleteButton onClick={() => handleDelete(id)} />}
      </Grid>
    </Grid>
  ) : (
    <Text
      label={rateTypeId ? titleCase(feeTypes[rateTypeId]) : ''}
      value={`${numberToUSD(value || 0)}`}
    />
  );
};

FeeItem.propTypes = {
  edit: PropTypes.bool,
  rateTypeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  handleChange: PropTypes.func.isRequired,
  newFee: PropTypes.bool,
};

FeeItem.defaultProps = {
  edit: false,
  newFee: false,
};

const useStyles = makeStyles({
  btnGrid: { alignSelf: 'center' },
});

export default FeeItem;
