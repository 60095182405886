import { Grid, Typography, makeStyles } from '@material-ui/core';

import { LightDivider } from '../Dividers';
import React from 'react';
import { ShadowCard } from '../Containers';

const PageHeader = ({
  name,
  subHeading,
  subHeadingLocation,
  location,
  chipsContent,
  topRightContent,
  secondRowContent,
}) => {
  const classes = useStyles();

  return (
    <ShadowCard>
      <Grid container spacing={2} alignItems="center" justify="space-between">
        <Grid item md={subHeading ? 4 : 2}>
          <Typography variant="h2" noWrap>
            {name}
            {subHeading && (
              <span className={classes.subHeading}>{` | ${subHeading}`}</span>
            )}
            {subHeadingLocation && (
              <span className={classes.subHeadingLocation}>
                {subHeadingLocation}
              </span>
            )}
          </Typography>
        </Grid>
        {location && (
          <Grid item md={2}>
            <Typography variant="subtitle1" noWrap>
              {location}
            </Typography>
          </Grid>
        )}
        {chipsContent && (
          <Grid item md={4}>
            {chipsContent}
          </Grid>
        )}
        <Grid container item md={6} justify="flex-end" >
          {topRightContent}
        </Grid>
      </Grid>
      {secondRowContent && (
        <>
          <LightDivider className={classes.divider} />
          <Grid container alignItems="center">
            {secondRowContent}
          </Grid>
        </>
      )}
    </ShadowCard>
  );
};

const useStyles = makeStyles(theme => ({
  divider: {
    margin: '5px 0 30px 0',
  },
  subHeading: {
    color: theme.palette.secondary.main,
  },
  subHeadingLocation: {
    color: theme.palette.secondary.main,
    display: 'block',
    fontWeight: 'normal',
    lineHeight: '2rem',
    fontSize: 'initial',
  },
}));

export default PageHeader;
