import React, { useState, useEffect } from 'react';
import { Stepper, Step, StepLabel, Grid } from '@material-ui/core/';
import { ShadowCard } from '../common/Containers';
import { CardHead } from '../common/CardContent';
import { Loader } from '../common/Loader';
import options from '../../util/options';
import { getAxios } from '../../util/axiosHelper';
import { useAuth0 } from '../../react-auth0-wrapper';

const Status = ({ loadId, refetchStatus }) => {
  const { accessToken } = useAuth0();
  const [activeStep, setActiveStep] = useState(0);
  const [loadNotFound, setLoadNotFound] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAxios('load/status', { params: { loadId } }, accessToken).then(data => {
      if (data) {
        setActiveStep(data.loadStatusId);
      } else {
        setLoadNotFound(true);
      }
      setLoading(false);
    });
  }, [accessToken, loadId, refetchStatus]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        !loadNotFound && (
          <ShadowCard>
            <Grid container>
              <Grid item xs={12}>
                <CardHead headingText="Load Status" />
              </Grid>
            </Grid>
            <Stepper activeStep={activeStep} alternativeLabel>
              {options.loadStatus.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </ShadowCard>
        )
      )}
    </>
  );
};

export default Status;
