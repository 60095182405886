import { Box, Button, Fab, Grid, CircularProgress } from '@material-ui/core';
import {
  CardHead,
  Currency,
  Selector,
  Subheader,
  Suggest,
} from '../common/CardContent';
import React, { useEffect, useState } from 'react';
import {
  equipmentTypes,
  mexBorderCrossings,
  mexBorderCrossingsCoordinates,
} from '../../util/options';

import { Add } from '@material-ui/icons';
import { Location } from './';
import { Toggle } from '../common/Switches';
import { catchErrors } from '../../util';
import { postAxios } from '../../util/axiosHelper';
import { useAuth0 } from '../../react-auth0-wrapper';

// https://me.me/i/yours-is-without-a-doubt-the-worst-code-ive-ever-e211566e35334839b048856d989f6bb6

const InstantRateForm = props => {
  const { accessToken } = useAuth0();
  const [isGettingRates, setIsGettingRates] = useState(false);
  const [crossingCity, setCrossingCity] = useState(null);
  const [equipmentType, setEquipmentType] = useState(1);
  const [loadValue, setLoadValue] = useState('');
  const [isMxPresent, setIsMxPresent] = useState(false);
  const [isCaPresent, setIsCaPresent] = useState(false);
  const [isHazmat, setIsHazmat] = useState({ checked: false });
  const [isTeamService, setIsTeamService] = useState({ checked: false });
  const [disabledPickUpLocationBtn, setDisabledPickUpLocationBtn] = useState(
    false
  );
  const [
    disabledDeliveryLocationBtn,
    setDisabledDeliveryLocationBtn,
  ] = useState(false);
  const [pickupAddressInputs, setPickUpAddressInputs] = useState([
    {
      stop: null,
      address: '',
      coordinates: null,
      addressType: 'pickup',
      customerReference: '',
      isForagerScheduled: false,
      dropTrailer: false,
      scheduleType: 2,
      apptOpen: null,
      apptClose: null,
    },
  ]);

  const [deliveryAddressInputs, setDeliveryAddressInputs] = useState([
    {
      stop: null,
      address: '',
      coordinates: null,
      addressType: 'delivery',
      customerReference: '',
      isForagerScheduled: false,
      dropTrailer: false,
      scheduleType: 2,
      apptOpen: null,
      apptClose: null,
    },
  ]);
  const isForager = props.forager;

  const [deliveryCountries, setDeliveryCountries] = useState([]);
  const [pickupCountries, setPickUpCountries] = useState([]);
  const [crossingCityCoordinates, setCrossingCityCoordinates] = useState([]);
  const [pickupCoordinatesArr, setPickUpCoordinatesArr] = useState([]);
  const [deliveryCoordinatesArr, setDeliveryUpCoordinatesArr] = useState([]);
  const [showCrossingCity, setShowCrossingCity] = useState(false);
  const [edit] = useState(true);
  const [customerId, setCustomerId] = useState(null);
  const [customerName, setCustomerName] = useState(null);

  // Error Setter
  const [error, setError] = useState({});

  // Validation
  const [customerIdValid, setCustomerIdValid] = useState({
    customerId: isForager ? false : true,
  });
  const [pu0Valid, setPu0Valid] = useState({ pu0: false });
  const [pu1Valid, setPu1Valid] = useState({
    pu1: pickupAddressInputs[1] ? false : true,
  });
  const [pu2Valid, setPu2Valid] = useState({
    pu2: pickupAddressInputs[2] ? false : true,
  });
  const [pu3Valid, setPu3Valid] = useState({
    pu3: pickupAddressInputs[3] ? false : true,
  });
  const [pu4Valid, setPu4Valid] = useState({
    pu4: pickupAddressInputs[4] ? false : true,
  });

  const [del0Valid, setDel0Valid] = useState({ del0: false });
  const [del1Valid, setDel1Valid] = useState({
    del1: deliveryAddressInputs[1] ? false : true,
  });
  const [del2Valid, setDel2Valid] = useState({
    del2: deliveryAddressInputs[2] ? false : true,
  });
  const [del3Valid, setDel3Valid] = useState({
    del3: deliveryAddressInputs[3] ? false : true,
  });
  const [del4Valid, setDel4Valid] = useState({
    del4: deliveryAddressInputs[4] ? false : true,
  });

  const [loadValueValid, setLoadValueValid] = useState({
    loadValueValid: false,
  });
  const [crossingValid, setCrossingValid] = useState({
    crossing: showCrossingCity ? false : true,
  });

  const delValids = [
    setDel0Valid,
    setDel1Valid,
    setDel2Valid,
    setDel3Valid,
    setDel4Valid,
  ];
  const puValids = [
    setPu0Valid,
    setPu1Valid,
    setPu2Valid,
    setPu3Valid,
    setPu4Valid,
  ];

  const handleSaveClick = () => {
    catchErrors([
      customerIdValid,
      pu0Valid,
      pu1Valid,
      pu2Valid,
      pu3Valid,
      pu4Valid,
      del0Valid,
      del1Valid,
      del2Valid,
      del3Valid,
      del4Valid,
      loadValueValid,
      crossingValid,
    ])
      .then(() => {
        setError({});
        submitForRates();
      })
      .catch(errors => {
        setError(errors);
      });
  };

  const addPickUpLocation = () => {
    setPickUpAddressInputs(pickupAddressInputs =>
      pickupAddressInputs.concat([
        {
          stop: null,
          address: '',
          coordinates: null,
          addressType: 'pickup',
          customerReference: '',
          isForagerScheduled: false,
          dropTrailer: false,
          scheduleType: 2,
          apptOpen: null,
          apptClose: null,
        },
      ])
    );
  };

  const addDeliveryAddress = () => {
    setDeliveryAddressInputs(deliveryAddressInputs =>
      deliveryAddressInputs.concat([
        {
          stop: null,
          address: '',
          coordinates: null,
          addressType: 'delivery',
          customerReference: '',
          isForagerScheduled: false,
          dropTrailer: false,
          scheduleType: 2,
          apptOpen: null,
          apptClose: null,
        },
      ])
    );
  };

  const updatePickUpAddresses = (index, address) => {
    const updatedpickupAddressInputs = pickupAddressInputs.map((input, idx) => {
      if (index !== idx) return input;
      return {
        ...input,
        ...address,
        stop: idx + 1,
        address: address.name,
        coordinates: address.coordinates,
        country: address.countryShortName,
      };
    });

    setPickUpAddressInputs(updatedpickupAddressInputs);
  };

  const pickUpAddressCleanup = index => {
    const updatedpickupAddressInputs = pickupAddressInputs.map((input, idx) => {
      if (index !== idx) return input;
      return {
        ...input,
        stop: null,
        address: '',
        coordinates: [],
        country: '',
      };
    });

    setPickUpAddressInputs(updatedpickupAddressInputs);
  };

  const deliveryAddressCleanup = index => {
    const updatedDeliveryAddressInputs = deliveryAddressInputs.map(
      (input, idx) => {
        if (index !== idx) return input;
        return {
          ...input,
          stop: null,
          address: '',
          coordinates: [],
          country: '',
        };
      }
    );

    setDeliveryAddressInputs(updatedDeliveryAddressInputs);
  };

  const updateDeliveryAddress = (index, address) => {
    const updatedDeliveryAddressInputs = deliveryAddressInputs.map(
      (input, idx) => {
        if (index !== idx) return input;
        return {
          ...input,
          ...address,
          stop: idx + 1,
          address: address.name,
          coordinates: address.coordinates,
          country: address.countryShortName,
        };
      }
    );

    setDeliveryAddressInputs(updatedDeliveryAddressInputs);
  };

  const removePickUpAddress = index => () => {
    setPickUpAddressInputs(pickupAddressInputs =>
      pickupAddressInputs.filter((pickupaddress, sidx) => index !== sidx)
    );
  };

  const removeDeliveryAddress = index => () => {
    setDeliveryAddressInputs(deliveryAddressInputs =>
      deliveryAddressInputs.filter((deliveryAddress, sidx) => index !== sidx)
    );
  };

  const handleCrossingCityChange = value => {
    const crossCityObj = mexBorderCrossingsCoordinates.filter(
      crossingCity => crossingCity.id === value
    );

    setCrossingCity(value);
    setCrossingCityCoordinates([crossCityObj[0].coordinates]);
  };

  const submitForRates = () => {
    let payload = {
      pickupAddressInputs,
      crossingCity,
      deliveryAddressInputs,
      equipmentType: equipmentTypes[equipmentType],
      equipmentTypeId: equipmentType,
      isHazmat,
      isTeamService,
      isMxPresent,
      isCaPresent,
      loadValue,
      crossingCityCoordinates,
      crossingLocation: mexBorderCrossings[crossingCity],
      mexBorderCrossingId: crossingCity,
      delCountry: deliveryCountries[0],
      puCountry: pickupCountries[0],
      customerId,
      isForager,
    };

    setIsGettingRates(true);

    return postAxios('pricing/get-rates', payload, accessToken)
      .then(data => {
        data
          ? props.getInstantRateFormData({
              ...data,
              customerId,
              customerName,
              crossingCityCoordinates,
            })
          : console.log('Error');
      })
      .finally(() => {
        setIsGettingRates(false);
      });
  };

  // Generates Error text for the currency component
  const generateErrorText = (loadValue, loadMax) => {
    const maxLoaderrorText =
      'Please Enter the Value of the Cargo, if over $500k please call';
    const emptyValueErrorText = 'This field is required';
    if (loadValue > loadMax) {
      return maxLoaderrorText;
    }
    if (!loadValue) {
      return emptyValueErrorText;
    }
  };

  useEffect(() => {
    if ([...pickupCountries, ...deliveryCountries].includes('mx')) {
      setShowCrossingCity(true);
      setIsMxPresent(true);
    } else {
      setShowCrossingCity(false);
      // TODO: why is this here???
      // setCrossingCityCoordinates([]);
      setIsMxPresent(false);
    }

    if ([...pickupCountries, ...deliveryCountries].includes('ca')) {
      setIsCaPresent(true);
    } else {
      setIsCaPresent(false);
    }
  }, [pickupCountries, deliveryCountries]);

  useEffect(() => {
    const pkCoordinatesArr = pickupAddressInputs.map(address =>
      [].concat(address.coordinates)
    );

    setPickUpCoordinatesArr(pkCoordinatesArr);

    const updatedPickUpCountries = pickupAddressInputs.reduce(
      (countries, address) => {
        countries.push(address.country);
        return countries;
      },
      []
    );

    setPickUpCountries([...updatedPickUpCountries]);

    if (pickupAddressInputs.length > 4) {
      setDisabledPickUpLocationBtn(true);
    } else {
      setDisabledPickUpLocationBtn(false);
    }
  }, [pickupAddressInputs]);

  useEffect(() => {
    const deliveryCoordinatesArr = deliveryAddressInputs.map(address =>
      [].concat(address.coordinates)
    );

    setDeliveryUpCoordinatesArr(deliveryCoordinatesArr);

    const updatedDeliveryCountries = deliveryAddressInputs.reduce(
      (countries, address) => {
        countries.push(address.country);
        return countries;
      },
      []
    );

    setDeliveryCountries([...updatedDeliveryCountries]);

    if (deliveryAddressInputs.length > 4) {
      setDisabledDeliveryLocationBtn(true);
    } else {
      setDisabledDeliveryLocationBtn(false);
    }
  }, [deliveryAddressInputs]);

  useEffect(() => {
    props.setMarkers([
      ...pickupCoordinatesArr,
      ...crossingCityCoordinates,
      ...deliveryCoordinatesArr,
    ]);
  }, [pickupCoordinatesArr, crossingCityCoordinates, deliveryCoordinatesArr]); // eslint-disable-line

  return (
    <Box p={4}>
      <Grid item xs={12}>
        <CardHead headingText="Instant Rate" />
      </Grid>
      {isForager === true && (
        <>
          <Box pb={4} pt={4}>
            <Grid item xs={12}>
              <Subheader noUnderline headingText="Customer" />
            </Grid>
          </Box>
          <Suggest
            edit="true"
            label="Customer"
            value={customerName || ''}
            placeholder="Select a Customer"
            name="Select a Customer"
            onAddressEmpty={() => {
              setCustomerId(null);
              setCustomerName(null);
            }}
            editHook={customer => {
              setCustomerId(customer.id);
              setCustomerName(customer.dbaName || customer.legalName);
            }}
            urlString="customer/autocomplete/search"
            error={error}
            errorText="Please Select a Customer"
            setValid={setCustomerIdValid}
            required
          />
        </>
      )}
      <Grid container justify="flex-end" alignItems="flex-end">
        <Grid item xs={12}>
          <Box pb={4} pt={4}>
            <Grid item xs={12}>
              <Subheader noUnderline headingText="Locations" />
            </Grid>
          </Box>
          <Box mb={1}>
            {pickupAddressInputs.map((pickuplocation, idx) => (
              <Location
                key={idx}
                index={idx}
                address={pickuplocation.address}
                addressType={pickuplocation.addressType}
                onAddressSelect={updatePickUpAddresses}
                onAddressEmpty={pickUpAddressCleanup}
                onDelete={removePickUpAddress(idx)}
                delCountry={deliveryCountries[0]}
                puCountry={pickupCountries[0]}
                error={error}
                name={`pu${idx}`}
                errorText="Please Enter a Valid Pickup City"
                setValid={puValids[idx]}
              />
            ))}
          </Box>
        </Grid>
        <Grid item>
          <Fab
            variant="extended"
            size="small"
            color="primary"
            onClick={addPickUpLocation}
            disabled={disabledPickUpLocationBtn}
            aria-label="Add Pickup Location"
          >
            <Add />
            Pickup
          </Fab>
        </Grid>

        <Grid item xs={12}>
          <Box mt={3} mb={1}>
            {deliveryAddressInputs.map((deliveryAddress, idx) => (
              <Location
                key={idx}
                index={idx}
                address={deliveryAddress.address}
                addressType={deliveryAddress.addressType}
                onAddressSelect={updateDeliveryAddress}
                onAddressEmpty={deliveryAddressCleanup}
                onDelete={removeDeliveryAddress(idx)}
                delCountry={deliveryCountries[0]}
                puCountry={pickupCountries[0]}
                error={error}
                name={`del${idx}`}
                errorText="Please Enter a Valid Delivery City"
                setValid={delValids[idx]}
              />
            ))}
          </Box>
        </Grid>
        <Grid item>
          <Fab
            variant="extended"
            size="small"
            color="primary"
            onClick={addDeliveryAddress}
            disabled={disabledDeliveryLocationBtn}
            aria-label="Add Delivery Location"
          >
            <Add />
            Delivery
          </Fab>
        </Grid>
        {showCrossingCity && (
          <Grid item xs={12}>
            <Box mt={2} mb={2}>
              <Selector
                options={mexBorderCrossings}
                label="Crossing City"
                value={crossingCity}
                name="crossingCity"
                edit={edit}
                error={error}
                setError={setError}
                required
                editHook={handleCrossingCityChange}
                setValid={setCrossingValid}
              />
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          <Box pb={4} pt={4}>
            <Grid item xs={12}>
              <Subheader noUnderline headingText="Additional Info" />
            </Grid>
          </Box>
          <Box mb={3}>
            <Selector
              options={equipmentTypes}
              label="Equipment Type"
              value={equipmentType}
              name="equipmentType"
              edit={edit}
              error={error}
              setError={setError}
              editHook={value => setEquipmentType(value)}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mb={3}>
            <Currency
              label="Load Value"
              name="loadValue"
              max={500000}
              errorText={generateErrorText(loadValue, 500000)}
              value={loadValue}
              edit={edit}
              error={error}
              required
              editHook={(value, name) => setLoadValue(parseInt(value))}
              setValid={setLoadValueValid}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mt={3} mb={2}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Toggle
                  label="Hazmat"
                  name="hazmat"
                  editHook={(name, value) => setIsHazmat({ checked: value })}
                  checked={isHazmat.checked}
                />
              </Grid>
              <Grid item xs={3}>
                <Toggle
                  label="Team Service"
                  name="teamService"
                  editHook={(name, value) =>
                    setIsTeamService({ checked: value })
                  }
                  checked={isTeamService.checked}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveClick}
              data-testid="instant-rate-btn"
              disabled={isGettingRates}
            >
              {isGettingRates ? (
                <CircularProgress size={16} color="secondary" />
              ) : (
                'Get Rates'
              )}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InstantRateForm;
