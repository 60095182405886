import { CardHead, Selector } from '../common/CardContent';
import React, { useEffect, useState } from 'react';
import { getAxios, putAxios } from './../../util/axiosHelper';

import { Grid } from '@material-ui/core';
import { Loader } from '../common/Loader';
import { SaveEditIconButton } from '../common/Buttons';
import { ShadowCard } from '../common/Containers';
import { Toggle } from './../common/Switches';
import options from '../../util/options';
import { useAuth0 } from '../../react-auth0-wrapper';

const CustomsBrokerSpecs = ({ companyId }) => {
  const { accessToken } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [specs, setSpecs] = useState({});

  const handleEdit = () => setEdit(true);

  const handleSave = () => {
    putAxios('customs-broker/specs', { companyId, specs }, accessToken);
    setEdit(false);
  };

  const handleChange = (value, name) => {
    setSpecs({ ...specs, [name]: value });
  };

  const handleToggle = (name, value) => {
    setSpecs({ ...specs, [name]: value });
  };

  useEffect(() => {
    getAxios(
      'customs-broker/specs',
      { params: { companyId } },
      accessToken
    ).then(data => {
      setSpecs(data);
      setLoading(false);
    });
  }, [accessToken, companyId]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <ShadowCard>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CardHead
                headingText="Customs Broker Specs"
                action={
                  <SaveEditIconButton
                    edit={edit}
                    onClick={edit ? handleSave : handleEdit}
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Toggle
                label="CTPAT"
                name="ctpat"
                labelPlacement="end"
                value={specs.ctpat}
                editHook={handleToggle}
                disabled={!edit}
                checked={specs.ctpat}
              />
            </Grid>
            <Grid item xs={12}>
              <Toggle
                label="Border Drayage Provided"
                name="borderDrayageProvided"
                labelPlacement="end"
                value={specs.borderDrayageProvided}
                editHook={handleToggle}
                disabled={!edit}
                checked={specs.borderDrayageProvided}
              />
            </Grid>
            <Grid item xs={12}>
              <Toggle
                label="Overtime Available"
                name="overtimeAvailable"
                labelPlacement="end"
                value={specs.overtimeAvailable}
                editHook={handleToggle}
                disabled={!edit}
                checked={specs.overtimeAvailable}
              />
            </Grid>
            <Grid item xs={12}>
              <Selector
                label="PaperWork Delivery Method"
                name="paperworkDeliveryMethodId"
                value={specs.paperworkDeliveryMethodId}
                editHook={handleChange}
                options={options.paperworkDeliveryMethods}
                edit={edit}
              />
            </Grid>
            <Grid item xs={12}>
              <Selector
                label="Country Serviced"
                name="countryServicedId"
                value={specs.countryServicedId}
                editHook={handleChange}
                options={options.countriesServiced}
                edit={edit}
              />
            </Grid>
          </Grid>
        </ShadowCard>
      )}
    </>
  );
};

export default CustomsBrokerSpecs;
