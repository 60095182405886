import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { StackedSuggest, StackedText } from './';
import { getAxios, postAxios } from '../../../util/axiosHelper';
import { ModalContainer } from '../Modals';
import { Typeahead } from '../Typeahead';
import { useAuth0 } from '../../../react-auth0-wrapper';
import { catchErrors } from '../../../util';

const Address = ({
  label,
  edit,
  address,
  address2,
  city,
  state,
  postalCode,
  addressName,
  address2Name,
  cityName,
  stateName,
  postalCodeName,
  editHook,
  error,
  errorText,
  required,
  setValid,
}) => {
  const classes = useStyles();
  const { accessToken } = useAuth0();
  const [openAltAddress, setOpenAltAddress] = useState(false);

  // Modal Validation
  const [postalCodeValid, setPostalCodeValid] = useState({ postalCode: false });
  const [addressValid, setAddressValid] = useState({ address: false });
  const [cityValid, setCityValid] = useState({ city: false });
  const [modalError, setModalError] = useState({});

  error = error ? error : {};
  setValid = setValid ? setValid : () => '';

  const handleOptionClick = async (option, name) => {
    const address = option.name.split(',')[0];
    const { city, postalCode, state, stateShortName } = option;
    const params = { city, state };

    let dbCityInfo = await getAxios('geo/city/info', { params }, accessToken);

    !dbCityInfo.cityId &&
      (await getAxios(
        'geo/geocode/perm',
        { params: { city: `${city}, ${state}, ${option.country}` } },
        accessToken
      ).then(
        data =>
          data && postAxios('geo/create/city', { city: data }, accessToken)
      ));

    if (name === addressName) {
      editHook({
        [addressName]: address && address,
        [cityName]: city,
        [postalCodeName]: postalCode,
        [stateName]: state ? state : stateShortName,
      });
    } else if (name === cityName) {
      editHook({
        [cityName]: city,
        [stateName]: state ? state : stateShortName,
      });
    }
  };

  const handleModalClose = () => {
    // Validates Modal Inputs Prios to closing it
    catchErrors([postalCodeValid, addressValid, cityValid])
      .then(() => {
        setOpenAltAddress(false);
      })
      .catch(errors => {
        setModalError(errors);
      });
  };

  // Validates street Address
  useEffect(() => {
    if (required && address) {
      setValid({ [addressName]: true });
    } else {
      setValid({ [addressName]: false });
    }
  }, [required, address, addressName, setValid]);

  const altAddressCta = (
    <Grid container spacing={1} justify="center">
      <Grid item>
        <Typography className={classes.altCopy}>
          Don't see your address?
        </Typography>
      </Grid>
      <Grid item>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={() => setOpenAltAddress(true)}
        >
          Add Manually
        </Button>
      </Grid>
    </Grid>
  );

  const content = edit ? (
    <>
      <FormControl className={classes.formControl}>
        <Typeahead
          value={address}
          urlString="geo/autocomplete/address"
          placeholder="Enter an address"
          onChange={value => handleOptionClick(value, addressName)}
          disableSetSearchString
          menuCta={altAddressCta}
        />
        <FormHelperText error>
          {error[addressName] ? errorText : null}
        </FormHelperText>
      </FormControl>
      <FormControl className={classes.formControl}>
        <Input
          value={address2}
          className={classes.input}
          onChange={e => editHook({ [address2Name]: e.target.value })}
          autoComplete="new-password"
          placeholder="Enter a Suite# or Apt.#"
        />
      </FormControl>
    </>
  ) : (
    <>
      <Typography className={classes.address}>{address}</Typography>
      <Typography className={classes.address}>{address2}</Typography>
    </>
  );

  return (
    <Grid container wrap="nowrap" justify="space-between" alignItems="center">
      <Grid item xs={5}>
        <Typography color="secondary">{label}</Typography>
      </Grid>
      <Grid item xs={7}>
        <Grid container>
          <Grid item xs={12}>
            {content}
            <Grid container justify="flex-end">
              <Grid item>
                <Typography>
                  {!!city && `${city}, `} {!!state && `${state} `}
                  {!!postalCode && `${postalCode}`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <ModalContainer
        open={openAltAddress}
        handleClose={() => setOpenAltAddress(false)}
        modalWidth={'40%'}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <StackedText
              edit
              required
              error={modalError}
              setValid={setAddressValid}
              name={addressName}
              errorText="Enter a valid address"
              label="Enter Address"
              value={address}
              editHook={address => editHook({ [addressName]: address })}
            />
          </Grid>
          <Grid item xs={12}>
            <StackedText
              edit
              label="Enter Suite #, Apt #, etc."
              value={address2}
              editHook={address2 => editHook({ [address2Name]: address2 })}
            />
          </Grid>
          <Grid item xs={12}>
            <StackedSuggest
              edit
              required
              error={modalError}
              errorText="Enter a valid city"
              setValid={setCityValid}
              value={city}
              placeholder=""
              name={cityName}
              label="Enter City"
              editHook={handleOptionClick}
              urlString="geo/autocomplete/city"
            />
          </Grid>
          <Grid item xs={12}>
            <StackedText
              error={modalError}
              edit
              setValid={setPostalCodeValid}
              required
              errorText="Enter valid postal code"
              label="Enter Postal Code"
              value={postalCode}
              name={postalCodeName}
              editHook={postalCode =>
                editHook({ [postalCodeName]: postalCode })
              }
            />
          </Grid>
        </Grid>
        <Grid container justify="flex-end" spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => setOpenAltAddress(false)}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => handleModalClose()}
            >
              Done
            </Button>
          </Grid>
        </Grid>
      </ModalContainer>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  label: {
    fontWeight: 500,
  },
  address: {
    textAlign: 'right',
  },
  formControl: {
    width: '100%',
  },
  input: {
    '& input': {
      textAlign: 'right',
    },
  },
  typeahead: {
    marginTop: -10,
  },
}));

export default Address;
