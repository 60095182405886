import React from 'react';
import {
  Box,
  Button,
  Grid,
  Typography,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import moment from 'moment-timezone';
import { Warning } from '@material-ui/icons';

const displayMap = {
  nextDay: 'Next Day',
  weekendRates: 'Weekend Rates',
  lead48hr: '48-hour Lead Time',
};
const crossingModeIdMap = {
  transload: 1,
  direct: 2,
  transloadCost: 1,
  directCost: 2,
};

const getRateDisplayText = code => displayMap[code] || 'Default Rate';

const getCrossingModeId = code => crossingModeIdMap[code] || 3;

const RateDisplayItem = ({
  rateName,
  ratesObj,
  setLoadRate,
  setSelectedRateType,
  cost,
  setActiveRate,
  setCrossingModeId,
  activeRate,
}) => {
  const rateDisplay = getRateDisplayText(rateName);
  const classes = useStyles();

  const isDisabled =
    rateDisplay === displayMap.nextDay &&
    moment()
      .tz('America/Chicago')
      .format('H') >= 12;

  const displayKeys = ratesObj.transload
    ? Object.entries(ratesObj).filter(([key]) =>
        cost ? key.includes('Cost') : !key.includes('Cost')
      )
    : Object.entries(ratesObj).filter(([key]) =>
        cost ? key === 'cost' : key === 'rate'
      );

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      spacing={2}
    >
      <Grid item>
        <Grid container>
          <Grid item>
            <Typography variant="button" display="block" gutterBottom>
              {rateDisplay}
            </Typography>
          </Grid>
          <Grid item>
            {isDisabled && (
              <Tooltip
                interactive
                title="Next Day Rates Only Available Before Noon CST"
              >
                <Warning color="error" fontSize="small" />
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {displayKeys.map(([key, rate]) => {
          return !rate || rate === Boolean(rate) ? null : (
            <>
              <Box component="span" mr={1} key={`${key}-${rate}`}>
                <Button
                  disabled={isDisabled}
                  data-testid={`${rateName}${key}`}
                  variant={
                    activeRate === `${rateName}${key}`
                      ? 'contained'
                      : 'outlined'
                  }
                  color="primary"
                  className={cost && classes.danger}
                  onClick={() => {
                    setActiveRate(`${rateName}${key}`);
                    setLoadRate(rate);
                    setCrossingModeId(getCrossingModeId(key));
                    setSelectedRateType(rateName);
                  }}
                >
                  <NumberFormat
                    value={rate}
                    name={rateDisplay}
                    prefix="$"
                    thousandSeparator=","
                    displayType="text"
                  />
                </Button>
              </Box>
            </>
          );
        })}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  danger: {
    color: `${theme.palette.danger.main} !important`,
    border: `1px solid ${theme.palette.danger.main} !important`,
  },
  contractedRate: {
    color: `${theme.palette.blueSky.main} !important`,
    border: `1px solid ${theme.palette.blueSky.main} !important`,
  },
  toolTipNoMaxWidth: {
    maxWidth: 'none',
    background: 'none',
  },
}));

export default RateDisplayItem;
