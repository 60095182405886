import React from 'react';
import { Typography, makeStyles, Grid } from '@material-ui/core';
import logo from '../../assets/img/scout.svg';

const Mobile = () => {
  const classes = useStyles();
  return (
    <Grid
      className={classes.mobileBox}
      container
      spacing={2}
      justify="center"
      direction="column"
      alignItems="center"
    >
      <Grid item>
        <img src={logo} alt="Forager Logo" className={classes.logo} />
      </Grid>
      <Grid item>
        <Typography variant="h2">Currently unavailable on mobile.</Typography>
      </Grid>
      <Grid>
        <Typography variant="h2">Check back soon!</Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle1">info@foragerscs.com</Typography>
        <Typography variant="subtitle1">(855) 436-7243</Typography>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  mobileBox: {
    backgroundColor: '#F1F4F6',
    height: '100vh',
    textAlign: 'center',
  },
  logo: {
    minWidth: 250,
  },
});

export default Mobile;
