import { FormControlLabel, Switch, makeStyles } from '@material-ui/core';

import React from 'react';

const Toggle = ({
  editHook,
  name,
  label,
  checked,
  disabled,
  labelPlacement,
}) => {
  const classes = useStyles();

  checked = checked ? checked : false;

  return (
    <FormControlLabel
      onChange={e => editHook(e.target.name, e.target.checked)}
      className={classes.toggle}
      name={name}
      data-testid={name}
      control={<Switch color="primary" />}
      label={label}
      labelPlacement={labelPlacement}
      checked={checked}
      disabled={disabled}
    />
  );
};

const useStyles = makeStyles({
  toggle: {
    marginLeft: 0,
    color: '#6D6E70',
    '& .MuiFormControlLabel-label': {
      fontSize: 14,
    },
  },
});

export default Toggle;
