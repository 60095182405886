import { CardHeader, Typography, makeStyles } from '@material-ui/core';

import { LightDivider } from '../Dividers';
import PropTypes from 'prop-types';
import React from 'react';

const CardHead = ({ headingText, subheader, action, noUnderline }) => {
  const classes = useStyles();

  return (
    <>
      <CardHeader
        className={!noUnderline && classes.header}
        title={
          <Typography variant="h2" className={classes.title}>
            {headingText}
          </Typography>
        }
        action={action}
        subheader={subheader}
      />
      {!noUnderline && <LightDivider />}
    </>
  );
};

CardHead.propTypes = {
  editable: PropTypes.bool,
  headingText: PropTypes.string,
  subheader: PropTypes.string,
};

const useStyles = makeStyles({
  header: {
    padding: '3px 3px 16px 4px',
  },
  title: {
    float: 'left',
    marginRight: '10px',
  },
});

export default CardHead;
