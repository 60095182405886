import {
  InstantRateForm,
  PricingMap,
  RatesDisplay,
} from '../../components/InstantRate';
import React, { useEffect, useState } from 'react';

import { EnterLoadInfo } from '../LoadSystem';
import { Grid } from '@material-ui/core';
import { MyRates } from '../CustomerPortal/Rates';
import { PrivateRoute } from '../../components/common/Privatize';
import { ShadowPaper } from '../../components/common/Containers';

const RateCalculatorView = ({ history, match, forager }) => {
  const [markers, setMarkers] = useState([]);
  const [instantRateFormData, setInstantRateFormData] = useState({});
  const [instantFormDataPresent, setInstantFormDataPresent] = useState(false);
  const [displayRoute, setDisplayRoute] = useState(false);

  const getInstantRateFormData = data => {
    setInstantFormDataPresent(true);
    setDisplayRoute(true);
    setInstantRateFormData(data);
  };

  useEffect(() => {
    instantFormDataPresent && history.push(`${match.url}/display`);
  }, [instantRateFormData]); // eslint-disable-line

  const renderWithMap = instantPricingNode => (
    <ShadowPaper noPadding>
      <Grid container>
        <Grid item xs={8}>
          {instantPricingNode}
        </Grid>
        <Grid item xs={4}>
          <PricingMap markers={markers} displayRoute={displayRoute} />
        </Grid>
      </Grid>
    </ShadowPaper>
  );

  return (
    <>
      <PrivateRoute
        path={match.url}
        render={props =>
          renderWithMap(
            <InstantRateForm
              setMarkers={setMarkers}
              getInstantRateFormData={getInstantRateFormData}
              forager={forager}
              {...props}
            />
          )
        }
        exact
      />
      <PrivateRoute
        path={`${match.url}/display`}
        render={props =>
          renderWithMap(
            <RatesDisplay
              instantRateFormData={instantRateFormData}
              isForager={forager}
              {...props}
            />
          )
        }
        exact
      />
      <PrivateRoute
        path={`${match.url}/enter-load-info`}
        render={props => <EnterLoadInfo {...props} />}
      />
      <PrivateRoute
        path={`${match.url}/history`}
        render={props => <MyRates {...props} />}
      />
    </>
  );
};

export default RateCalculatorView;
