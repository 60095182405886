import { Grid } from '@material-ui/core';
import { LoadsTable } from '../../components/LoadSystem';
import React from 'react';

const LoadBoard = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <LoadsTable isForager table="loadBoard" />
      </Grid>
    </Grid>
  );
};

export default LoadBoard;
