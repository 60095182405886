import { Typography, makeStyles } from '@material-ui/core';

import { FormField } from './index';
import PropTypes from 'prop-types';
import React from 'react';

const FormTextArea = ({
  canEdit,
  error,
  helperText,
  label,
  name,
  onChange,
  value,
  rows
}) => {
  const classes = makeStyles(useStyles);

  return (
    <>
      {canEdit ? (
        <FormField
          canEdit={canEdit}
          className={classes.textArea}
          error={error}
          fullWidth
          helperText={helperText}
          id={name}
          label={label}
          margin="normal"
          multiline
          name={name}
          onChange={onChange}
          value={value}
          variant="filled"
          rows={rows}
        />
      ) : (
        <Typography className={classes.typography}>
          {value ? value : '-'}
        </Typography>
      )}
    </>
  );
};

const useStyles = makeStyles({
  MuiTextField: {
    overflow: 'auto',
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'black',
    },
  },
  typography: { whiteSpace: 'pre-line' },
});

FormTextArea.propTypes = {
  canEdit: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.node,
};

export default FormTextArea;
