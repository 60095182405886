import { useState, useEffect } from 'react';
import { useAuth0 } from '../react-auth0-wrapper';
import { getAxios } from '../util/axiosHelper';

const useTypeahead = (searchString, urlString, valuesToExclude) => {
  const { accessToken } = useAuth0();
  const [typeaheadData, setTypeaheadData] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (urlString && searchString.length > 2) {
      setLoading(true);

      const debounced = setTimeout(() => {
        const params = {
          searchString,
          valuesToExclude,
        };
        getAxios(urlString, { params }, accessToken)
          .then(data => {
            setTypeaheadData(data);
            setLoading(false);
          })
          .catch(e => {
            setLoading(false);
          });
      }, 400);

      return () => {
        clearTimeout(debounced);
      };
    }
  }, [accessToken, searchString, urlString, valuesToExclude]);

  return { typeaheadData, isLoading };
};

export default useTypeahead;
