import React from 'react';
import { Grid } from '@material-ui/core';
import { LoadsTable } from '../../components/LoadSystem';
import { AccountReps } from '../../components/common/AccountReps';

const PlanedLoadsView = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <LoadsTable table="planned" />
      </Grid>
      <Grid item xs={4}>
        <AccountReps />
      </Grid>
    </Grid>
  );
};

export default PlanedLoadsView;
