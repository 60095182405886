import { Address, CardHead, Phone, Text, Website } from '../common/CardContent';
import { Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { getAxios, putAxios } from '../../util';

import { Loader } from '../common/Loader';
import PropTypes from 'prop-types';
import { SaveEditIconButton } from '../common/Buttons';
import { ShadowCard } from '../common/Containers';
import { useAuth0 } from '../../react-auth0-wrapper';

const CustomerInfo = ({ customerId }) => {
  const classes = useStyles();

  const { accessToken } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [customerNotFound, setCustomerNotFound] = useState(false);
  const [edit, setEdit] = useState(false);
  const [info, setInfo] = useState({});

  const handleEdit = () => setEdit(true);

  const handleSave = () => {
    putAxios(
      'customer/info',
      { payload: { companyId: customerId, ...info } },
      accessToken
    );
    setEdit(false);
  };

  const handleChange = (value, key) => setInfo({ ...info, [key]: value });

  const handleAddressChange = addressValues =>
    setInfo({ ...info, ...addressValues });

  useEffect(() => {
    getAxios('customer/info', { params: { customerId } }, accessToken).then(
      data => {
        if (data) {
          setInfo(data);
        } else {
          setCustomerNotFound(true);
        }
        setLoading(false);
      }
    );
  }, [accessToken, customerId]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        !customerNotFound && (
          <ShadowCard>
            <CardHead
              headingText="Company Info"
              action={
                <SaveEditIconButton
                  edit={edit}
                  onClick={edit ? handleSave : handleEdit}
                />
              }
            />
            <Grid container spacing={1} className={classes.form}>
              <Grid item xs={12}>
                <Text
                  label="Legal Name"
                  value={info.legalName}
                  edit={edit}
                  editHook={handleChange}
                  name="legalName"
                />
              </Grid>
              <Grid item xs={12}>
                <Text
                  label="DBA"
                  value={info.dbaName}
                  name="dbaName"
                  edit={edit}
                  editHook={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Address
                  label="Physical Address"
                  address={info.address}
                  addressName="address"
                  address2={info.address2}
                  address2Name="address2"
                  city={info.city}
                  cityName="city"
                  state={info.state}
                  stateName="state"
                  postalCode={info.postalCode}
                  postalCodeName="postalCode"
                  editHook={handleAddressChange}
                  edit={edit}
                />
              </Grid>
              <Grid item xs={12}>
                <Phone
                  label="Phone"
                  name="phone"
                  number={info.phone}
                  edit={edit}
                  editHook={handleChange}
                />
                <Text
                  label="Ext."
                  name="phoneExt"
                  edit={edit}
                  value={info.phoneExt}
                  editHook={handleChange}
                  type="number"
                />
              </Grid>
              <Grid item xs={12}>
                <Website
                  label="Website"
                  name="website"
                  website={info.website}
                  edit={edit}
                  editHook={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Phone
                  label="Fax"
                  name="fax"
                  number={info.fax}
                  edit={edit}
                  editHook={handleChange}
                  isFax
                />
              </Grid>
            </Grid>
          </ShadowCard>
        )
      )}
    </>
  );
};

CustomerInfo.propTypes = {
  customerId: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
  form: {
    marginTop: 15,
  },
});

export default CustomerInfo;
