import {
  Box,
  Button,
  CardActions,
  CardContent,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  makeStyles,
} from '@material-ui/core';
import { CardHead, Suggest } from '../common/CardContent';
import { Delete, RemoveRedEye } from '@material-ui/icons';
import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { ShadowCard } from '../common/Containers';

const RelatedFacilities = ({
  title,
  handleAdd,
  handleRemove,
  handleShowMoreClick,
  facilities,
  companyId,
}) => {
  const classes = useStyles();
  const [facilityId, setFacilityId] = useState(null);
  const [facilityName, setFacilityName] = useState(null);

  const handleAddClick = () => {
    handleAdd(companyId, facilityId);
    setFacilityId(null);
    setFacilityName(null);
  };

  return (
    <ShadowCard>
      <CardHead headingText={title} />
      <Divider />
      <CardContent className={classes.cardContent}>
        <Grid container className={classes.listHeader}>
          <Grid item xs={2} />
          <Grid item xs={4}>
            <Box>Facility</Box>
          </Grid>
          <Grid item xs={5}>
            <Box>Location</Box>
          </Grid>
        </Grid>

        <List padding={{ padding: 0 }}>
          <Divider />
          {facilities.map(item => (
            <ListItem key={item.id} disableGutters>
              <Grid container>
                <Grid item xs={2}>
                  <IconButton
                    onClick={() => handleShowMoreClick(item)}
                    size="small"
                  >
                    <RemoveRedEye />
                  </IconButton>
                </Grid>
                <Grid item xs={4}>
                  <Box>{item.dbaName || item.legalName}</Box>
                </Grid>
                <Grid item xs={5}>
                  <Box>{item.city}</Box>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    size="small"
                    onClick={() => handleRemove(companyId, item)}
                  >
                    <Delete />
                  </IconButton>
                </Grid>
              </Grid>
            </ListItem>
          ))}
        </List>
      </CardContent>
      <CardActions>
        <Grid container justify="space-between">
          <Suggest
            edit
            errorText="This field is required"
            name={title}
            placeholder="Facility Name"
            value={facilityName || ''}
            onAddressEmpty={() => {
              setFacilityId(null);
              setFacilityName(null);
            }}
            editHook={facility => {
              setFacilityId(facility.id);
              setFacilityName(facility.dbaName || facility.legalName);
            }}
            valuesToExclude={(() => {
              const values = facilities.map(facility => facility.facilityId);
              values.push('0');
              return values;
            })()}
            urlString="facility/autocomplete/search"
          />
          <Button
            color="primary"
            size="small"
            variant="contained"
            className={classes.addButton}
            onClick={handleAddClick}
          >
            Add
          </Button>
        </Grid>
      </CardActions>
    </ShadowCard>
  );
};

const useStyles = makeStyles(theme => ({
  cardContent: {
    padding: '0',
  },
  listHeader: {
    color: theme.palette.secondary.main,
    marginTop: 25,
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  addButton: {
    margin: theme.spacing(1),
  },
}));

RelatedFacilities.propTypes = {
  title: PropTypes.string.isRequired,
  handleAdd: PropTypes.func.isRequired,
  handleShowMoreClick: PropTypes.func.isRequired,
  facilities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      facilityName: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default RelatedFacilities;
