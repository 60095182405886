// Takes in a number (10000) and outputs it comma delimted (10,000)
export const numberToCommaDelimited = x => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/, ',');
};

// Takes in a number (10000) and outputs it dollars ($10,000)
export const numberToUSD = number => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  }).format(number);
};
