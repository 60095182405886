import React from 'react';
import { Grid } from '@material-ui/core';
import { AddNewCustomer } from '../../components/CustomerSystem';

const AddNew = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <AddNewCustomer />
      </Grid>
    </Grid>
  );
};

export default AddNew;
