import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { StackedSuggest, StackedText } from '../CardContent';
import { getAxios, postAxios } from '../../../util/axiosHelper';

import { ModalContainer } from '../Modals';
import { Typeahead } from '../Typeahead';
import { connect } from 'formik';
import { useAuth0 } from '../../../react-auth0-wrapper';

const FormAddress = ({
  label,
  edit,
  address,
  address2,
  city,
  state,
  postalCode,
  addressName,
  address2Name,
  cityName,
  stateName,
  postalCodeName,
  editHook,
  error,
  errorText,
  required,
  setValid,
  formik,
  type,
  index,
  loadId = null,
  stopLocationAddress,
}) => {
  const classes = useStyles();
  const [openAltAddress, setOpenAltAddress] = useState(false);
  const { accessToken } = useAuth0();
  error = error ? error : {};
  setValid = setValid ? setValid : () => '';

  const addressValues = loadId
    ? stopLocationAddress
    : formik.values[type][index];

  const handleOptionClick = async option => {
    const address = option.name.split(',')[0];
    const { city, postalCode, state, stateShortName } = option;
    const params = { city, state };
    let dbCityInfo = await getAxios('geo/city/info', { params }, accessToken);

    !dbCityInfo.cityId &&
      (await getAxios(
        'geo/geocode/perm',
        { params: { city: `${city}, ${state}, ${option.country}` } },
        accessToken
      ).then(
        data =>
          data && postAxios('geo/create/city', { city: data }, accessToken)
      ));

    editHook({
      [addressName]: address,
      [cityName]: city,
      [postalCodeName]: postalCode,
      [stateName]: state ? state : stateShortName,
    });
  };

  useEffect(() => {
    if (required && address) {
      setValid({ [addressName]: true });
    } else {
      setValid({ [addressName]: false });
    }
  }, [required, address, addressName, setValid]);

  const altAddressCta = (
    <Grid container spacing={1} justify="center">
      <Grid item>
        <Typography className={classes.altCopy}>
          Don't see your address?
        </Typography>
      </Grid>
      <Grid item>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={() => setOpenAltAddress(true)}
        >
          Add Manually
        </Button>
      </Grid>
    </Grid>
  );
  const content = edit ? (
    <>
      <FormControl className={classes.formControl}>
        <Typeahead
          value={addressValues && addressValues.facilityAddress}
          urlString="geo/autocomplete/address"
          placeholder="Enter an address"
          onChange={handleOptionClick}
          disableSetSearchString
          menuCta={altAddressCta}
        />
        <FormHelperText error>
          {error[addressName] ? errorText : null}
        </FormHelperText>
      </FormControl>
      <FormControl className={classes.formControl}>
        <Input
          value={addressValues && addressValues.facilityAddress2}
          className={classes.input}
          onChange={e => editHook({ [address2Name]: e.target.value })}
          placeholder="Enter a Suite# or Apt.#"
        />
      </FormControl>
    </>
  ) : (
    <>
      <Typography
        className={classes.address}
        data-testid={`${type}.${index}.addressLine1`}
      >
        {addressValues && addressValues.facilityAddress}
      </Typography>
      <Typography
        className={classes.address}
        data-testid={`${type}.${index}.addressLine2`}
      >
        {addressValues && addressValues.facilityAddress2}
      </Typography>
    </>
  );
  return (
    <Grid container wrap="nowrap" justify="space-between" alignItems="center">
      <Grid item xs={5}>
        <Typography color="secondary">{label}</Typography>
      </Grid>
      <Grid item xs={7}>
        <Grid container>
          <Grid item xs={12}>
            {content}
            <Grid container justify="flex-end" spacing={1}>
              <Grid item>
                <Typography>
                  {addressValues && addressValues.facilityCity}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {addressValues && addressValues.facilityState}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>{postalCode}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ModalContainer
        open={openAltAddress}
        handleClose={() => setOpenAltAddress(false)}
        modalWidth={'40%'}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <StackedText
              edit
              label="Enter Address"
              value={address}
              editHook={address => editHook({ [addressName]: address })}
            />
          </Grid>
          <Grid item xs={12}>
            <StackedText
              edit
              label="Enter Suite #, Apt #, etc."
              value={address2}
              editHook={address2 => editHook({ [address2Name]: address2 })}
            />
          </Grid>
          <Grid item xs={12}>
            <StackedSuggest
              edit
              value={city}
              placeholder=""
              name={cityName}
              label="Enter City"
              editHook={handleOptionClick}
              urlString="geo/autocomplete/city"
            />
          </Grid>
          <Grid item xs={12}>
            <StackedText
              edit
              label="Enter Postal Code"
              value={postalCode}
              editHook={postalCode =>
                editHook({ [postalCodeName]: postalCode })
              }
            />
          </Grid>
        </Grid>
        <Grid container justify="flex-end" spacing={2}>
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => setOpenAltAddress(false)}
            >
              Done
            </Button>
          </Grid>
        </Grid>
      </ModalContainer>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  label: {
    fontWeight: 500,
  },
  address: {
    textAlign: 'right',
  },
  formControl: {
    width: '100%',
  },
  input: {
    '& input': {
      textAlign: 'right',
    },
  },
  typeahead: {
    marginTop: -10,
  },
}));

export default connect(FormAddress);
