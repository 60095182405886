import { Divider, Typography, makeStyles } from '@material-ui/core';

import React from 'react';

const Subheader = ({ headingText, noUnderline }) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h4" className={!noUnderline ? classes.title : ''}>
        {headingText}
      </Typography>
      {!noUnderline && <Divider className={classes.divider} />}
    </>
  );
};

const useStyles = makeStyles({
  title: {
    margin: '20px 3px 8px 4px',
  },
  divider: { width: '25%', marginBottom: '19px' },
});

export default Subheader;
