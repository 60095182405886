import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect } from 'react';

import { CardItem } from './';

const Selector = ({
  label,
  name,
  options,
  editHook,
  value,
  edit,
  setValid,
  required,
  error = {},
  stacked = false,
}) => {
  const classes = useStyles();
  setValid = setValid ? setValid : (setValid = () => '');
  editHook = editHook ? editHook : (editHook = () => '');
  const errorText = 'This field is required';

  if (value === null) {
    value = 0;
  }

  // Validate Intial Value
  useEffect(() => {
    if (required && value === 0) {
      setValid({ [name]: false });
    } else {
      setValid({ [name]: true });
    }
  }, [name, required, setValid, value]);

  const renderMenuItems = options =>
    Object.keys(options).map(option => (
      <MenuItem key={option} value={option} data-testid={option}>
        {options[option]}
      </MenuItem>
    ));

  let content = edit ? (
    <>
      <Select
        data-testid={label}
        className={stacked ? classes.select : ''}
        value={value}
        onChange={e => editHook(parseInt(e.target.value), name, e.target.value)}
        inputProps={{
          name: name,
        }}
      >
        {options && renderMenuItems(options)}
      </Select>
    </>
  ) : (
    <Typography
      {...(stacked && { align: 'left', noWrap: true, className: classes.text })}
    >
      {options[value]}
    </Typography>
  );

  return stacked ? (
    <FormControl className={classes.formControl}>
      <FormHelperText>{label}</FormHelperText>
      {content}
      <FormHelperText error>{error[name] && errorText}</FormHelperText>
    </FormControl>
  ) : (
    <CardItem
      label={label}
      name={name}
      error={error}
      errorText={errorText}
      content={content}
    />
  );
};

const useStyles = makeStyles({
  formControl: {
    width: '100%',
  },
  text: {
    height: 29,
    margin: '5px 0 0',
  },
  select: {
    height: 24,
    margin: '5px 0 0',
    '& input': {
      padding: 0,
    },
  },
});

export default Selector;
