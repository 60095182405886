import {
  CardContent,
  FormHelperText,
  Grid,
  makeStyles,
} from '@material-ui/core';
import {
  CardHead,
  DateTime,
  Email,
  Phone,
  Selector,
  Text,
} from '../common/CardContent';
import { ErrorMessage, connect } from 'formik';
import React, { useEffect, useState } from 'react';
import { getAxios, putAxios, titleCase } from '../../util';

import DatePick from '../common/CardContent/DatePick';
import { FormAddress } from '../common/Forms';
import { Loader } from '../common/Loader';
import { PrivateComponent } from '../common/Privatize';
import PropTypes from 'prop-types';
import { SaveEditIconButton } from '../common/Buttons';
import { ShadowCard } from '../common/Containers';
import { Toggle } from '../common/Switches';
import { useAuth0 } from '../../react-auth0-wrapper';

const StopDetailsCard = ({
  headingText,
  subheader,
  loading,
  formik,
  index,
  loadId = null,
  customerId,
  formikType,
  customerReferenceLabel,
  stopDetailsType,
  values,
  setRefetchStatus,
  refetchStatus,
  isForager,
  duplicate = null,
}) => {
  const classes = useStyles();
  const { accessToken } = useAuth0();
  const { setFieldValue } = formik;
  const location = values ? values : formik.values[formikType][index];

  const scheduleOptions = {
    2: 'Appointment',
    3: 'Window',
  };

  const {
    arrival,
    depart,
    pickupId,
    deliveryId,
    stopNumber,
    apptOpen,
    apptClose,
    customerReference,
  } = location;

  // Enter load screen does not have a loadID so automatically set to false
  const [canEdit, setCanEdit] = useState(!loadId);
  const [facilities, setFacilities] = useState();
  const [facilityId, setFacilityId] = useState(location.facilityId || null);
  // Arrival/Depart
  const [newArrival, setNewArrival] = useState(null);
  const [newDepart, setNewDepart] = useState(null);
  // Appt
  const [newApptOpen, setNewApptOpen] = useState(null);
  const [newApptClose, setNewApptClose] = useState(null);
  const [foragerSchedule, setForagerSchedule] = useState(
    location && location.isForagerScheduled
  );
  // Schedule Type
  const [scheduleType, setScheduleType] = useState(0);
  // Ref Nums
  const [refNum, setRefNum] = useState(null);
  // Contacts
  const [facilityContacts, setFacilityContacts] = useState({});
  const [facilityContactsOptions, setFacilityContactsOptions] = useState({});

  // Load update Handlers
  const updateForagerSchedule = isForagerSchedule => {
    setForagerSchedule(isForagerSchedule);
  };

  const updateLocationArrival = () => {
    putAxios(
      'load/arrival',
      { pickupId, deliveryId, newArrival, stopNumber, loadId },
      accessToken
    );
  };

  const updateLocationDepart = () => {
    putAxios(
      'load/depart',
      { pickupId, deliveryId, newDepart, stopNumber, loadId },
      accessToken
    ).then(() => {
      setRefetchStatus(!refetchStatus);
    });
  };

  const updateLocationAppointmentOpen = () => {
    putAxios(
      'load/appointment/open',
      { pickupId, deliveryId, newApptOpen, loadId },
      accessToken
    );
  };

  const updateLocationAppointmentClose = () => {
    putAxios(
      'load/appointment/close',
      { pickupId, deliveryId, newApptClose, loadId },
      accessToken
    );
  };

  const updateCustomerReferenceNum = () => {
    putAxios(
      'load/appointment/ref-num',
      { pickupId, deliveryId, refNum },
      accessToken
    );
  };

  const onSave = () => {
    updateLocationArrival();
    updateLocationDepart();
    updateLocationAppointmentOpen();
    updateLocationAppointmentClose();
    updateCustomerReferenceNum();
    putAxios(
      'load/forager-schedule',
      { foragerSchedule, pickupId, deliveryId },
      accessToken
    );
  };

  const handleScheduleTypeChange = event => {
    setScheduleType(event);
  };

  const handleCanEdit = () => {
    setCanEdit(!canEdit);
    canEdit && onSave();
  };

  const handleFacilityUpdate = facilityId => {
    setFacilityId(facilityId);
    getAxios(
      'facility/contacts/options',
      { params: { facilityId } },
      accessToken
    ).then(res => {
      const options = (() => {
        const optsObj = {};
        for (const id in res) {
          optsObj[id] = res[id].name;
        }
        return optsObj;
      })();
      setFacilityContacts(res);
      setFacilityContactsOptions(options);
    });
  };

  // Onload arrival/depart Setters
  useEffect(() => {
    setNewArrival(arrival);
  }, [arrival]);

  useEffect(() => {
    setNewDepart(depart);
  }, [depart]);

  // Onload Appt open/close setters
  useEffect(() => {
    setNewApptOpen(apptOpen);
  }, [apptOpen]);

  useEffect(() => {
    setNewApptClose(apptClose);
  }, [apptClose]);

  // Set schedule type
  useEffect(() => {
    newApptOpen && newApptClose ? setScheduleType(3) : setScheduleType(2);
  }, [newApptOpen, newApptClose]);

  useEffect(() => {
    setRefNum(customerReference);
  }, [customerReference]);

  const [tz, setTz] = useState();

  useEffect(() => {
    const getTimezone = (lat, lng, timestamp) => {
      return getAxios(
        'geo/timezone',
        { params: { lat, lng, timestamp } },
        accessToken
      );
    };
    getTimezone(
      location.coordinates[1],
      location.coordinates[0],
      Date.now()
    ).then(data => {
      setTz(data);
    });
  }, [accessToken, location, tz]);

  useEffect(() => {
    const { coordinates } = location;

    canEdit &&
      getAxios(
        'customer/facilities-in-radius',
        {
          params: {
            lat: coordinates[1],
            lng: coordinates[0],
            customerId,
            radius: 50,
          },
        },
        accessToken
      ).then(data => {
        if (data) {
          setFacilities(data);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, canEdit, customerId, location, arrival]);

  const getFacilityMetaInfo = facilityId => {
    getAxios(
      'facility/meta-info',
      { params: { facilityId } },
      accessToken
    ).then(data => {
      handleFacilityUpdate(facilityId);

      const facilityName = data && data.dbaName ? data.dbaName : data.legalName;

      const normalizedData = Object.assign({
        facilityId,
        facilityName,
        facilityAddress: data.address,
        facilityAddress2: data.address2,
        facilityCity: data.city,
        facilityState: data.state,
        facilityZip: data.postalcode,
        contactEmail: data.email,
        contactPhone: data.phone,
      });

      Object.keys(normalizedData).forEach(value => {
        setFieldValue(
          `[${formikType}][${index}].${value}`,
          normalizedData[value]
        );
      });
    });
  };

  useEffect(() => {
    if (duplicate) getFacilityMetaInfo(facilityId);
  }, []);

  const handleArrivalChange = date => {
    setNewArrival(date);
  };

  const handleDepartChange = date => {
    setNewDepart(date);
  };

  const handleApptOpenChange = date => {
    setNewApptOpen(date);
    // Accounts for enter load screen
    !loadId && setFieldValue(`[${formikType}][${index}].apptOpen`, date);
  };

  const handleApptCloseChange = date => {
    setNewApptClose(date);
    // Accounts for enter load screen
    !loadId && setFieldValue(`[${formikType}][${index}].apptClose`, date);
  };

  const handleReferenceNum = num => {
    setRefNum(num);
    // Accounts for enter load screen
    !loadId &&
      setFieldValue(`[${formikType}][${index}].customerReference`, num);
  };

  const renderFieldArrayErrorMessage = (name, fieldName) => (
    <ErrorMessage name={name}>
      {msg => <FormHelperText error={true}>{msg[fieldName]}</FormHelperText>}
    </ErrorMessage>
  );

  const ForagerScheduleDatePickers =
    scheduleType !== 3 && location.scheduleType === 2 ? (
      <DatePick
        timeZone={tz}
        date={newApptOpen}
        edit={canEdit}
        label={`${titleCase(stopDetailsType)} Date`}
        name={`location.${index}.apptOpen`}
        enableCalendar
        editHook={(name, text) => {
          handleApptOpenChange(name, text);
        }}
        testId={`${formikType}.${index}.apptOpen`}
      />
    ) : (
      <>
        <Grid item xs={12}>
          <DatePick
            timeZone={tz}
            date={newApptOpen}
            edit={canEdit}
            label={`${titleCase(stopDetailsType)} Date`}
            name={`location.${index}.apptOpen`}
            enableCalendar
            editHook={(name, text) => {
              handleApptOpenChange(name, text);
            }}
            testId={`${formikType}.${index}.apptOpen`}
          />
        </Grid>
        <Grid item xs={12}>
          <DatePick
            timeZone={tz}
            date={newApptClose}
            edit={canEdit}
            label="Req. Close"
            name={`location.${index}.apptClose`}
            enableCalendar
            editHook={(name, text) => {
              handleApptCloseChange(name, text);
            }}
            testId={`${formikType}.${index}.apptClose`}
          />
        </Grid>
      </>
    );

  const nonForagerScheduleDateTimePickers =
    scheduleType !== 3 && location.scheduleType === 2 ? (
      <DateTime
        enableCalendar
        dateTime={newApptOpen}
        edit={canEdit}
        label={`${titleCase(stopDetailsType)} Date`}
        name={`location.${index}.apptOpen`}
        editHook={(name, text) => handleApptOpenChange(name, text)}
        testId={`${formikType}.${index}.apptOpen`}
        timeZone={tz}
      />
    ) : (
      <>
        <Grid item xs={12}>
          <DateTime
            enableCalendar
            dateTime={newApptOpen}
            edit={canEdit}
            label={`Req. Open ${titleCase(stopDetailsType)} Date`}
            name={`location.${index}.apptOpen`}
            timeZone={tz}
            editHook={(name, text) => handleApptOpenChange(name, text)}
            testId={`${formikType}.${index}.apptOpen`}
          />
        </Grid>
        <Grid item xs={12}>
          <DateTime
            enableCalendar
            dateTime={newApptClose}
            edit={canEdit}
            label={`Req. Close ${titleCase(stopDetailsType)} Date`}
            name={`location.${index}.apptClose`}
            timeZone={tz}
            editHook={(name, text) => handleApptCloseChange(name, text)}
            testId={`${formikType}.${index}.apptClose`}
          />
        </Grid>
      </>
    );

  const PrivateEditIcon = (
    <PrivateComponent reqScopes={['stop-details:edit']}>
      <SaveEditIconButton edit={canEdit} onClick={handleCanEdit} />
    </PrivateComponent>
  );

  const ForagerViewFields = (
    <>
      <PrivateComponent reqScopes={['forager:read']}>
        <Grid item>
          <DateTime
            dateTime={newArrival}
            edit={canEdit}
            label="Arrival"
            name={`location.${index}.arrival`}
            timeZone={tz}
            editHook={(name, text) => handleArrivalChange(name, text)}
          />
          <DateTime
            dateTime={newDepart}
            edit={canEdit}
            label="Depart"
            name={`location.${index}.depart`}
            timeZone={tz}
            editHook={(name, text) => handleDepartChange(name, text)}
          />
        </Grid>
      </PrivateComponent>
      {!canEdit && (
        <PrivateComponent reqScopes={['customer-portal:read']}>
          <Grid item>
            <DateTime
              dateTime={arrival}
              label="Arrival"
              name={`location.${index}.arrival`}
              timeZone={tz}
              editHook={(name, text) =>
                setFieldValue(`[${formikType}][${index}].arrival`, name)
              }
            />
            <DateTime
              dateTime={depart}
              label="Depart"
              name={`location.${index}.depart`}
              timeZone={tz}
              editHook={(name, text) =>
                setFieldValue(`[${formikType}][${index}].depart`, name)
              }
            />
          </Grid>
        </PrivateComponent>
      )}
    </>
  );

  return (
    <ShadowCard
      className={classes.content}
      data-testid={`${formikType}${index}`}
    >
      <CardHead
        action={PrivateEditIcon}
        headingText={headingText}
        subheader={subheader}
      />
      {loading ? (
        <Loader />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <CardContent>
              <Grid container spacing={1}>
                {loadId ? (
                  <Grid item xs={12}>
                    <Text
                      label="Facility"
                      value={
                        location.facilityDbaName || location.facilityLegalName
                      }
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <Selector
                      label="Facility Name"
                      edit={canEdit && !loadId}
                      value={facilityId || location.facilityId || ''}
                      name={facilityId}
                      options={facilities}
                      errorText="This field is required"
                      placeholder="Choose a Facility"
                      editHook={(text, name) => {
                        getFacilityMetaInfo(text);
                      }}
                    />
                    {renderFieldArrayErrorMessage(
                      `[${formikType}][${index}]`,
                      'facilityName'
                    )}
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FormAddress
                    label="Address"
                    index={index}
                    type={formikType}
                    loadId={loadId}
                    stopLocationAddress={location}
                  />
                </Grid>
                {(facilityId || location.facilityId) && (
                  <>
                    {loadId ? (
                      <Grid item xs={12}>
                        <Text
                          value={`${location.contactFirstName} ${location.contactLastName}`}
                          label="Contact Name"
                        />
                      </Grid>
                    ) : (
                      <>
                        <Selector
                          label="Facility Contact"
                          edit={canEdit && !loadId}
                          value={location.contactId || ''}
                          name={`location.${index}.contactId`}
                          options={facilityContactsOptions}
                          errorText="This field is required"
                          placeholder="Choose a Facility Contact"
                          editHook={(text, name) => {
                            setFieldValue(
                              `[${formikType}][${index}].contactId`,
                              text
                            );
                            setFieldValue(
                              `[${formikType}][${index}].contactEmail`,
                              facilityContacts[text].email
                            );
                            setFieldValue(
                              `[${formikType}][${index}].contactPhone`,
                              facilityContacts[text].phone
                            );
                          }}
                        />
                        {renderFieldArrayErrorMessage(
                          `[${formikType}][${index}]`,
                          'contactId'
                        )}
                      </>
                    )}
                    <Grid item xs={12}>
                      <Phone
                        number={
                          canEdit
                            ? location.contactId &&
                              facilityContacts[location.contactId] &&
                              facilityContacts[location.contactId].phone
                            : location.contactPhone ||
                              location.contactOfficePhone
                        }
                        label="Phone"
                        name="phone"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Email
                        email={
                          canEdit
                            ? location.contactId &&
                              facilityContacts[location.contactId] &&
                              facilityContacts[location.contactId].email
                            : location.contactEmail
                        }
                        label="Email"
                        name="email"
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </CardContent>
          </Grid>
          <Grid item xs={6}>
            <CardContent>
              <>
                <Text
                  edit={canEdit}
                  label={customerReferenceLabel}
                  name={`location.${index}.customerReference`}
                  value={refNum}
                  editHook={(text, name) => {
                    handleReferenceNum(text);
                  }}
                />
                {renderFieldArrayErrorMessage(
                  `[${formikType}][${index}]`,
                  'customerReference'
                )}
              </>
              <Grid container>
                <Grid item xs={4}>
                  <Toggle
                    label="Forager Schedule"
                    name={`location.${index}.isForagerScheduled`}
                    value={
                      loadId
                        ? foragerSchedule
                        : location && location.isForagerScheduled
                    }
                    editHook={(name, text) =>
                      !loadId
                        ? setFieldValue(
                            `[${formikType}][${index}].isForagerScheduled`,
                            text
                          )
                        : updateForagerSchedule(text, pickupId, deliveryId)
                    }
                    checked={
                      loadId
                        ? foragerSchedule
                        : location && location.isForagerScheduled
                    }
                    disabled={!canEdit}
                  />
                </Grid>
                <Grid item xs={8}>
                  <Selector
                    label="Sched. Type"
                    edit={canEdit}
                    value={
                      scheduleType
                        ? scheduleType
                        : location && location.scheduleType
                    }
                    name={`location.${index}.scheduleType`}
                    options={scheduleOptions}
                    errorText="This field is required"
                    placeholder="Pick Schedule type"
                    editHook={
                      isForager
                        ? handleScheduleTypeChange
                        : text => {
                            setFieldValue(
                              `[${formikType}][${index}].scheduleType`,
                              text
                            );
                          }
                    }
                  />
                </Grid>
              </Grid>
              <Grid container>
                {loadId
                  ? foragerSchedule
                    ? ForagerScheduleDatePickers
                    : nonForagerScheduleDateTimePickers
                  : location && location.isForagerScheduled
                  ? ForagerScheduleDatePickers
                  : nonForagerScheduleDateTimePickers}

                {renderFieldArrayErrorMessage(
                  `[${formikType}][${index}]`,
                  'apptOpen'
                )}
              </Grid>
              {ForagerViewFields}
              <Toggle
                label="Drop Trailer Required"
                name={`location.${index}.dropTrailer`}
                value={location && location.dropTrailer}
                checked={location && location.dropTrailer}
                editHook={(name, text) =>
                  setFieldValue(`[${formikType}][${index}].dropTrailer`, text)
                }
              />
            </CardContent>
          </Grid>
        </Grid>
      )}
    </ShadowCard>
  );
};

StopDetailsCard.propTypes = {
  loadId: PropTypes.string,
};

const useStyles = makeStyles({
  content: {
    maxHeight: 450,
    overflow: 'none',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export default connect(StopDetailsCard);
