// /////// BACKSTORY /////////////////
// This file was created to provide dropdown options.
// Each category and it's options mirror respective tables/rows that have been set up in the database
// For now, it's up to us to keep this file synched up with the db but at
// at one point we will create request pings to the db that will sync this up for us

/////// HOW TO USE THIS FILE /////////
// 1. If you don't see the category represented, created it with the options in a similar fashion as below
// 2. Import the category from this file
// 3. You'll most likely be using these options in conjuntion with a selector. "Dropdown" and "CardEditListItemSelectBox" will work
// well with this set up
// 4. Reference one of the components that use this file for further guidance.

exports.carrierStatus = {
  '0': 'Select a Status',
  '1': 'Cold Lead',
  '2': 'Active',
  '3': 'Probation',
  '4': 'Do Not Use',
  '5': 'Qualified Lead',
  '6': 'Disqualified',
};

exports.customerTypes = {
  1: 'Beneficial Cargo Owner',
  2: '3PL/Broker',
};

exports.equipmentTypes = {
  1: 'Dry Van',
  2: 'Reefer',
  4: 'Flatbed',
};

exports.equipmentTypesId = {
  'Dry Van': 1,
  Reefer: 2,
  Flatbed: 4,
};

exports.mexBorderCrossings = {
  1: 'Laredo, TX / Nuevo Laredo, TAM',
  2: 'El Paso, TX / Juárez, CHH',
  3: 'Eagle Pass, TX / Piedras Negras, COA',
  4: 'Del Rio, TX / Ciudad Acuña, COA',
  5: 'Pharr, TX / Reynosa, TAM',
  6: 'Brownsville, TX / Matamoros, TAM',
  7: 'San Diego, CA / Tijuana, BCN',
  8: 'Tecate, CA / Tecate, BCN',
  9: 'Calexico, CA / Mexicali, BCN',
  10: 'San Luis, AZ / San Luis Río Colorado, SON',
  11: 'Nogales, AZ / Nogales, SON',
  12: 'Douglas, AZ / Agua Prieta, SON',
};

exports.mexBorderCrossingsCoordinates = [
  {
    id: 1,
    crossCity: 'Laredo, TX / Nuevo Laredo, TAM',
    coordinates: [-99.50791991102031, 27.496105003432362],
  },
  {
    id: 2,
    crossCity: 'El Paso, TX / Juárez, CHH',
    coordinates: [-106.35456792574604, 31.772988499811934],
  },
  {
    id: 3,
    crossCity: 'Eagle Pass, TX / Piedras Negras, COA',
    coordinates: [-100.53377784981781, 28.715664321118254],
  },
  {
    id: 4,
    crossCity: 'Del Rio, TX / Ciudad Acuña, COA',
    coordinates: [-100.91423829561792, 29.343436133161706],
  },
  {
    id: 5,
    crossCity: 'Pharr, TX / Reynosa, TAM',
    coordinates: [-98.20848394027414, 26.105737131934223],
  },
  {
    id: 6,
    crossCity: 'Brownsville, TX / Matamoros, TAM',
    coordinates: [-97.45953949492262, 25.872002991861137],
  },
  {
    id: 7,
    crossCity: 'San Diego, CA / Tijuana, BCN',
    coordinates: [-117.09132558691499, 32.624135200478115],
  },
  {
    id: 8,
    crossCity: 'Tecate, CA / Tecate, BCN',
    coordinates: [-116.62565488322906, 32.57818001655886],
  },
  {
    id: 9,
    crossCity: 'Calexico, CA / Mexicali, BCN',
    coordinates: [-115.48333864421384, 32.6711159600912],
  },
  {
    id: 10,
    crossCity: 'San Luis, AZ / San Luis Río Colorado, SON',
    coordinates: [-114.77234943471512, 32.48183538356329],
  },
  {
    id: 11,
    crossCity: 'Nogales, AZ / Nogales, SON',
    coordinates: [-110.95305091860075, 31.335101113665743],
  },
  {
    id: 12,
    crossCity: 'Douglas, AZ / Agua Prieta, SON',
    coordinates: [-109.54709517901425, 31.335215012489513],
  },
];

exports.loadStatus = [
  'At Shipper',
  'Picked Up',
  'At Border',
  'Crossed',
  'Dispatched to Delivery',
  'At Delivery',
  'Completed',
];

exports.equipmentLength = {
  1: "20'",
  2: "40'",
  3: "45'",
  4: "48'",
  5: "53'",
};

exports.modes = {
  1: 'TL',
  2: 'LTL',
  3: 'PTL',
  4: 'Ocean',
  5: 'Air',
  6: 'Drayage',
  7: 'Border Drayage',
  8: 'IMDL',
};

exports.crossingModeOptions = {
  1: 'Transload',
  2: 'Direct',
};

// WIP Selector is being changed to work with array of objects
exports.foragers = {
  2: 'Brian Koonsvitsky',
  23: 'Philipp Albrecht',
  3: 'Matt Silver',
  4: 'Matt Ayo',
  32: 'Chloé Vezy',
  19: 'Chris Waldenberger',
  17: 'Chris Karr',
  15: 'Cole Faierstain',
  21: 'Juan Evereteze',
  89: 'Harry Martin',
};

exports.updateTypes = {
  0: 'Update Type',
  4: 'Dispatched',
  5: 'At Shipper',
  6: 'At Shipper – Detention Begin',
  7: 'Picked Up',
  8: 'At Border, Pending Paperwork',
  9: 'At Border, Paperwork Ready',
  10: 'At US Customs Broker, Pending Paperwork',
  11: 'At US Customs Broker, Paperwork Ready',
  12: 'At CAN Customs Broker, Pending Paperwork',
  13: 'At CAN Customs Broker, Paperwork Ready',
  14: 'At Transload Facility',
  15: 'Transload in Process',
  16: 'Transload Complete',
  17: 'Crossed',
  18: 'In Transit',
  19: 'At Consignee',
  20: 'At Consignee – Detention Begin',
  21: 'Delivered',
};

exports.countriesServiced = {
  1: 'CAN',
  2: 'USA',
  3: 'MEX',
};

exports.paperworkDeliveryMethods = {
  1: 'Email',
  2: 'In Person',
};

exports.feeTypes = {
  1: 'Border Crossing Fee',
  2: 'Chains',
  3: 'Chassis',
  4: 'Chassis Split',
  5: 'Claim',
  6: 'Deadhead',
  7: 'Demurrage',
  8: 'Detention at Border',
  9: 'Detention at Delivery',
  10: 'Detention at Pickup',
  11: 'Driver tailgate',
  12: 'Driver unload',
  13: 'Escorts',
  14: 'Expedited Service',
  15: 'Extra Stop',
  16: 'Flat Rate',
  17: 'Fuel Surcharge',
  18: 'Hazardous Materials',
  19: 'Inside delivery',
  20: 'Late Fee',
  21: 'Layover',
  22: 'Liftgate',
  23: 'Linehaul',
  24: 'Lumper',
  25: 'Mileage',
  26: 'MX Linehaul',
  27: 'Out of Route Miles',
  28: 'Overdimensional',
  29: 'Overweight',
  30: 'Permits',
  31: 'Pier Pass',
  32: 'Port Congestion',
  33: 'Pre-pull',
  34: 'Pump Fee',
  35: 'Reclassification',
  36: 'Re-delivery',
  37: 'Reweigh',
  38: 'Split',
  39: 'Storage',
  40: 'Straps',
  41: 'Tanker Endorsement',
  42: 'Tarps',
  43: 'Team Service',
  44: 'Tolls',
  45: 'TONU (Truck Order Not Used)',
  46: 'Trailer Detention',
  47: 'Trailer Wash',
  48: 'Transload',
  49: 'Tri Axel Chassis',
  50: 'Tri-axle',
  51: 'TWIC Card',
  52: 'Yard Storage',
  53: 'Transportation Charges',
};

exports.currencies = {
  0: 'Currency',
  1: 'CAD',
  2: 'USD',
  3: 'MXN',
};

exports.countriesServiced = {
  1: 'CAN',
  2: 'USA',
  3: 'MEX',
};

exports.paperworkDeliveryMethods = {
  1: 'Email',
  2: 'In Person',
};
