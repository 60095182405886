import { Paper } from '@material-ui/core';
import React from 'react';
import { styled } from '@material-ui/styles';

// Creates a styled variant of Material UI Paper
const ShadowPaper = styled(({ noPadding, noTopPadding, ...other }) => (
  <Paper {...other} />
))({
  backgroundColor: '#fff',
  border: 'none',
  borderRadius: '.35rem',
  boxShadow:
    '0 0.46875rem 2.1875rem rgba(90,97,105,.1), 0 0.9375rem 1.40625rem rgba(90,97,105,.1), 0 0.25rem 0.53125rem rgba(90,97,105,.12), 0 0.125rem 0.1875rem rgba(90,97,105,.1)',
  padding: props =>
    props.noTopPadding ? '0px 20px 20px 20px' : props.noPadding ? 0 : '20px',
  width: '100vw',
  maxWidth: '100%',
  // Max Height minus the paddings and top bar
  minHeight: 'calc(100vh - 64px - 64px)',
});

export default ShadowPaper;
