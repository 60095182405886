import React, { useEffect } from 'react';

import { CardItem } from './';
import NumberFormat from 'react-number-format';
import { makeStyles } from '@material-ui/core';

const Percent = ({
  value,
  label,
  edit,
  editHook,
  name,
  required,
  error,
  errorText,
  setValid,
  tableFormat,
}) => {
  const classes = useStyles();
  setValid = setValid ? setValid : (setValid = () => '');
  editHook = editHook ? editHook : (editHook = () => '');

  // Validate Intial Value
  useEffect(() => {
    if (required) {
      if (value) {
        setValid({ [name]: true });
      } else {
        setValid({ [name]: false });
      }
    }
  }, [name, required, setValid, value]);

  let content = edit ? (
    <NumberFormat
      className={classes.input}
      value={Math.round(value * 100)}
      name={name}
      thousandSeparator={true}
      suffix="%"
      onValueChange={values => {
        const { value } = values;
        editHook(value / 100, name);
      }}
    />
  ) : (
    <NumberFormat
      value={Math.round(value * 100)}
      thousandSeparator={true}
      suffix="%"
      displayType="text"
      renderText={value => (
        <span className={tableFormat ? classes.tableFont : classes.text}>
          {value ? value : '-'}{' '}
        </span>
      )}
    />
  );

  return (
    <CardItem
      label={label}
      name={name}
      error={error}
      errorText={errorText}
      content={content}
    />
  );
};

const useStyles = makeStyles({
  input: {
    height: 24,
    border: 'none',
    borderBottom: '1px solid black',
    fontSize: '1rem',
    padding: 0,
    textAlign: 'right',
    width: '100%',
    outline: 'none',
  },
  text: {
    fontSize: '1rem',
    textAlign: 'right',
    height: 24,
  },
  tableFont: {
    fontSize: '12px',
    textAlign: 'center',
  },
});

export default Percent;
