import React from 'react';
import { Grid } from '@material-ui/core';
import { AddNewFacility } from '../../components/FacilitySystem';

const AddNew = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <AddNewFacility />
      </Grid>
    </Grid>
  );
};

export default AddNew;
