import 'mapbox-gl/dist/mapbox-gl.css';

import { Grid, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import axios from 'axios';
import mapboxgl from 'mapbox-gl';

const PricingMap = props => {
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);
  const [coordinates, setCoordinates] = useState([]);
  const [geoJson, setGeoJson] = useState({
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          properties: {},
          coordinates: [],
        },
      },
    ],
  });

  const [displayMarkers, setDisplayMarkers] = useState([]);

  const getMapboxGeoJson = useCallback(() => {
    if (
      Array.isArray(props.markers[0]) &&
      Array.isArray(props.markers[1]) &&
      (!props.markers[0].includes(null) && !props.markers[1].includes(null))
    ) {
      const coordinates = props.markers.join(';').slice(0, -1); // cleanup lat/long for Mapbox

      const url = `https://api.mapbox.com/directions/v5/mapbox/driving/${coordinates}.json?geometries=geojson&overview=full&access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`;

      axios.get(url).then(res => {
        setCoordinates(res.data.routes[0].geometry.coordinates);

        setGeoJson({
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: { ...res.data.routes[0].geometry },
            },
          ],
        });
      });
    }
  }, [props.markers]);

  const pinMarkers = useCallback(() => {
    if (
      ((Array.isArray(props.markers[0]) && !props.markers[0].includes(null)) ||
        (Array.isArray(props.markers[1]) &&
          !props.markers[1].includes(null))) &&
      map
    ) {
      let mapmarkers = [];

      props.markers
        .filter(marker => !marker.includes(null) && marker.length !== 0)
        .map((marker, idx) => {
          const el = document.createElement('div');
          el.setAttribute(
            'style',
            'background-color: #F79520; color:#32489D; border: 2px solid #32489D; width: 20px; height: 20px; border-radius: 100px; text-align: center; vertical-align: middle;'
          );
          el.innerHTML = idx + 1;

          const mapmarker = new mapboxgl.Marker(el)
            .setLngLat(marker)
            .addTo(map);

          mapmarkers.push(mapmarker);

          return mapmarkers;
        });

      setDisplayMarkers(mapmarkers);
    }
  }, [map, props.markers]);

  useEffect(() => {
    if (map && props.displayRoute) {
      map.getSource('geojsonData').setData(geoJson);
    }
  }, [geoJson, map, props.displayRoute]);

  useEffect(() => {
    if (map) {
      let bounds;

      bounds = coordinates.reduce(function(bounds, coord) {
        return bounds.extend(coord);
      }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));

      map.fitBounds(bounds, {
        padding: 20,
      });
    }
  }, [coordinates]); // eslint-disable-line

  useEffect(() => {
    getMapboxGeoJson();
  }, [getMapboxGeoJson]);

  useEffect(() => {
    pinMarkers();
  }, [pinMarkers]);

  useEffect(() => {
    displayMarkers.forEach(item => item.remove());
  }, [props.markers]); // eslint-disable-line

  useEffect(() => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

    const initializeMap = ({ setMap, mapRef }) => {
      const map = new mapboxgl.Map({
        container: mapRef.current,
        style: 'mapbox://styles/mapbox/streets-v10',
        center: [-102.1638, 40.1155],
        zoom: 3,
      });

      map.on('load', () => {
        setMap(map);

        map.addSource('geojsonData', {
          type: 'geojson',
          data: geoJson,
        });

        map.addLayer({
          id: 'geojsonData',
          type: 'line',
          source: 'geojsonData',
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#32489D',
            'line-width': 3,
          },
        });

        map.resize();
      });
    };

    if (!map) initializeMap({ setMap, mapRef });
  }, [geoJson, map]);

  return (
    <Grid container direction="column" justify="flex-end" alignItems="flex-end">
      <div
        ref={el => (mapRef.current = el)}
        style={{
          position: 'relative',
          width: '100%',
          height: 'calc(100vh - 125px)',
        }}
      />
      <Typography variant="caption">
        Map is for display purposes only. Actual route may vary slightly.
      </Typography>
    </Grid>
  );
};

PricingMap.defaultProps = {
  markers: [],
  displayMarkers: [],
};

export default PricingMap;
