import React, { useState, useEffect } from 'react';
import { useAuth0 } from '../../../react-auth0-wrapper';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { ShadowCard } from '../../../components/common/Containers';
import { FilterTable } from '../../../components/common/Tables';
import { Loader } from '../../../components/common/Loader';
import { getAxios } from '../../../util';
import { AccountReps } from '../../../components/common/AccountReps';
import { PhoneLink } from '../../../components/common/Utility';

const MyFacilities = () => {
  const classes = useStyles();
  const { accessToken } = useAuth0();
  const [facilities, setFacilities] = useState();

  const columns = [
    { title: 'Facility', field: 'legalName' },
    { title: 'Street Address', field: 'address' },
    { title: 'Location', field: 'location' },
    { title: 'Main POC', field: 'mainPoc' },
    {
      title: 'Phone',
      field: 'officePhone',
      render: rowData => (
        <PhoneLink number={rowData.officePhone} alignment="center" />
      ),
    },
  ];

  useEffect(() => {
    const getFacilities = () => {
      getAxios('customer-portal/my-facilities', {}, accessToken).then(data => {
        setFacilities(data);
      });
    };
    getFacilities();
  }, [accessToken]);

  return (
    <Grid data-testid="my-facilities" container spacing={2}>
      <Grid item xs={8}>
        <ShadowCard noTopPadding>
          {!facilities && facilities !== undefined ? (
            <Loader />
          ) : (
            <FilterTable
              columns={columns}
              data={facilities}
              title="My Facilities"
              showActions={false}
              filtering
              noFooter
              noSearch
            />
          )}
          <Typography className={classes.message}>
            Contact your team to add new facilities to your account.
          </Typography>
        </ShadowCard>
      </Grid>
      <Grid item xs={4}>
        <AccountReps />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  message: {
    margin: '30px 0 0',
    textAlign: 'center',
    fontSize: 11,
    color: theme.palette.primary.main,
  },
}));

export default MyFacilities;
