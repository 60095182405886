import {
  Address,
  CardHead,
  Email,
  Phone,
  Text,
  Website,
} from '../common/CardContent';
import React, { useEffect, useState } from 'react';
import { getAxios, putAxios } from '../../util';

import { Grid } from '@material-ui/core';
import { Loader } from '../common/Loader';
import { SaveEditIconButton } from '../common/Buttons';
import { ShadowCard } from '../common/Containers';
import { useAuth0 } from '../../react-auth0-wrapper';

const FacilityMeta = ({ companyId }) => {
  const { accessToken } = useAuth0();
  const [edit, setEdit] = useState(false);
  const [info, setInfo] = useState({
    legalName: '',
    dbaName: '',
    address: '',
    address2: '',
    city: '',
    postalCode: '',
    state: '',
    phone: '',
    phoneExt: '',
    email: '',
    fax: '',
    website: '',
  });
  const [loading, setLoading] = useState(false);

  const handleEdit = () => setEdit(true);

  const handleSave = () => {
    putAxios(
      'facility/meta-info',
      { payload: { companyId, ...info } },
      accessToken
    );
    setEdit(false);
  };

  const handleChange = (value, key) => setInfo({ ...info, [key]: value });

  const handleAddressChange = addressValues =>
    setInfo({ ...info, ...addressValues });

  useEffect(() => {
    getAxios(
      'facility/meta-info',
      { params: { facilityId: companyId } },
      accessToken
    ).then(data => {
      if (data) {
        setInfo(data);
        setLoading(false);
      }
    });
  }, [companyId, accessToken]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <ShadowCard>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12}>
              <CardHead
                headingText="Facility"
                editable
                action={
                  <SaveEditIconButton
                    edit={edit}
                    onClick={edit ? handleSave : handleEdit}
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Text
                label="Legal Name"
                value={info.legalName}
                edit={edit}
                editHook={handleChange}
                name="legalName"
              />
            </Grid>
            <Grid item xs={12}>
              <Text
                label="DBA"
                value={info.dbaName}
                edit={edit}
                editHook={handleChange}
                name="dbaName"
              />
            </Grid>
            <Grid item xs={12}>
              <Address
                label="Physical Address"
                address={info.address}
                addressName="address"
                address2={info.address2}
                address2Name="address2"
                city={info.city}
                cityName="city"
                state={info.state}
                stateName="state"
                postalCode={info.postalCode}
                postalCodeName="postalCode"
                editHook={handleAddressChange}
                edit={edit}
              />
            </Grid>
            <Grid item xs={12}>
              <Phone
                label="Phone"
                name="phone"
                number={info.phone}
                edit={edit}
                editHook={handleChange}
              />
              <Text
                label="Ext."
                name="phoneExt"
                edit={edit}
                value={info.phoneExt}
                editHook={handleChange}
                type="number"
              />
            </Grid>
            <Grid item xs={12}>
              <Email
                label="Email"
                name="email"
                email={info.email}
                edit={edit}
                editHook={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Phone
                label="Fax"
                name="fax"
                number={info.fax}
                edit={edit}
                editHook={handleChange}
                isFax
              />
            </Grid>
            <Grid item xs={12}>
              <Website
                label="Website"
                name="website"
                website={info.website}
                edit={edit}
                editHook={handleChange}
              />
            </Grid>
          </Grid>
        </ShadowCard>
      )}
    </>
  );
};

export default FacilityMeta;
