import React from 'react'
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { Currency } from '../../common/CardContent'


const RateCell = ({ transload, standard, direct }) => {
  const classes = useStyles();

  return (
    <>
    {
      transload &&
      <Grid container>
        <Grid item xs={6}>
          <Typography className={classes.tableContent}>Trans:</Typography>
        </Grid>
        <Grid item xs={6}>
          <Currency value={transload} tableFormat />
        </Grid>
      </Grid>
    }
    {
      standard &&
      <Grid container>
        <Grid item xs={6}>
          <Typography className={classes.tableContent}>Rate:</Typography>
        </Grid>
        <Grid item xs={6}>
          <Currency value={standard} tableFormat />
        </Grid>
      </Grid>
    }
    {
      direct &&
      <Grid container>
        <Grid item xs={6}>
          <Typography className={classes.tableContent}>Direct:</Typography>
        </Grid>
        <Grid item xs={6}>
          <Currency value={direct} tableFormat />
        </Grid>
      </Grid>
    }
    </>
  )
}

const useStyles = makeStyles({
  tableContent: {
    fontSize: '12px',
  },
});

export default RateCell