import { Button, Grid, makeStyles } from '@material-ui/core';
import { Email, Phone, Text } from '../CardContent';
import React, { useCallback, useEffect, useState } from 'react';
import { getAxios, postAxios, putAxios } from '../../../util';

import { AddButton } from '../Buttons';
import ContactItem from './ContactItem';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { Loader } from '../../common/Loader';
import { ModalContainer } from '../Modals';
import PropTypes from 'prop-types';
import { catchErrors } from '../../../util';
import { useAuth0 } from '../../../react-auth0-wrapper';

const Contacts = ({ id, getPath, putPath, rolesFor, slug }) => {
  const classes = useStyles();

  const { accessToken } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [dataNotFound, setDataNotFound] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newContact, setNewContact] = useState({
    firstName: '',
    lastName: '',
    title: '',
    phone: '',
    officePhone: '',
    officePhoneExt: '',
    email: '',
    cellPhone: '',
  });
  // Validation
  const [error, setError] = useState({});
  const [firstNameValid, setFirstNameValid] = useState({ firstName: false });
  const [lastNameValid, setLastNameValid] = useState({ lastName: false });
  const [emailValid, setEmailValid] = useState({ email: false });
  const [officePhoneValid, setOfficePhoneValid] = useState();

  const handleGetContacts = useCallback(() => {
    getAxios(getPath, { params: { companyId: id } }, accessToken).then(data => {
      if (data) {
        setContacts(data);
      } else {
        setDataNotFound(true);
      }
      setLoading(false);
    });
  }, [accessToken, id, getPath]);

  const updateContact = ccId => {
    putAxios(
      putPath,
      {
        payload: {
          ...contacts.find(contact => contact.id === ccId),
          companyId: id,
        },
      },
      accessToken
    );
  };

  const handleChange = (id, value, key) => {
    setContacts(
      contacts.map(contact =>
        contact.id === id ? { ...contact, [key]: value } : contact
      )
    );
  };

  const handleMainChange = (id, value) =>
    setContacts(
      contacts.map(contact =>
        contact.id === id
          ? { ...contact, main: value }
          : { ...contact, main: value ? false : contact.main }
      )
    );

  const handleToggleModal = () => setIsModalOpen(!isModalOpen);

  const createContact = () => {
    postAxios('company/add-contact', { id, newContact }, accessToken).then(
      () => {
        handleGetContacts();
      }
    );
  };

  const handleSubmit = () => {
    catchErrors([firstNameValid, lastNameValid, emailValid, officePhoneValid])
      .then(() => {
        createContact();
        handleToggleModal();
        setNewContact({
          firstName: '',
          lastName: '',
          title: '',
          phone: '',
          officePhone: '',
          officePhoneExt: '',
          email: '',
        });
        setError({});
      })
      .catch(error => {
        setError(error);
      });
  };

  useEffect(() => {
    handleGetContacts();
  }, [handleGetContacts]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        !dataNotFound && (
          <Grid container spacing={2}>
            <Grid item>
              <Button
                to={'main'}
                component={Link}
                variant="contained"
                className={classes.backButton}
              >
                Back
              </Button>
            </Grid>
            <Grid item>
              <AddButton onClick={handleToggleModal} />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Formik initialValues={contacts}>
                  <>
                    {contacts.map(contact => (
                      <Grid item xs={4} key={contact.id}>
                        <ContactItem
                          {...contact}
                          updateContact={updateContact}
                          handleChange={handleChange}
                          handleMainChange={handleMainChange}
                          handleGetContacts={handleGetContacts}
                          rolesFor={rolesFor}
                        />
                      </Grid>
                    ))}
                  </>
                </Formik>
              </Grid>
            </Grid>
          </Grid>
        )
      )}

      <ModalContainer
        open={isModalOpen}
        onClose={setIsModalOpen}
        modalWidth={675}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Text
              setValid={setFirstNameValid}
              required
              errorText="Enter a valid first name"
              label="First Name"
              name="firstName"
              edit
              error={error}
              value={newContact.firstName}
              editHook={(text, name) =>
                setNewContact({ ...newContact, [name]: text })
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Text
              label="Last Name"
              name="lastName"
              edit
              required
              error={error}
              setValid={setLastNameValid}
              errorText="Enter a valid last name"
              value={newContact.lastName}
              editHook={(text, name) =>
                setNewContact({ ...newContact, [name]: text })
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Text
              label="Title"
              edit
              name="title"
              value={newContact.title}
              editHook={(text, name) =>
                setNewContact({ ...newContact, [name]: text })
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Email
              label="Email"
              edit
              required
              setValid={setEmailValid}
              errorText="Enter a valid email"
              error={error}
              name="email"
              email={newContact.email}
              editHook={(text, name) =>
                setNewContact({ ...newContact, [name]: text })
              }
            />
          </Grid>
          <Grid item xs={10}>
            <Phone
              label="Office Phone"
              edit
              required
              error={error}
              errorText="Enter a valid office phone"
              setValid={setOfficePhoneValid}
              name="officePhone"
              number={newContact.officePhone}
              editHook={(phone, name) =>
                setNewContact({ ...newContact, [name]: phone })
              }
            />
          </Grid>
          <Grid item xs={2}>
            <Text
              label="Ext."
              edit
              name="officePhoneExt"
              value={newContact.officePhoneExt}
              editHook={(phone, name) =>
                setNewContact({ ...newContact, [name]: phone })
              }
              type="number"
            />
          </Grid>
          <Grid item xs={10}>
            <Phone
              label="Cell Phone"
              edit
              name="cellPhone"
              canSms
              number={newContact.phone}
              editHook={(text, name) =>
                setNewContact({ ...newContact, [name]: text })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  onClick={handleToggleModal}
                  variant="contained"
                  color="secondary"
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ModalContainer>
    </>
  );
};

Contacts.propTypes = {
  id: PropTypes.string.isRequired,
  getPath: PropTypes.string.isRequired,
  putPath: PropTypes.string.isRequired,
  rolesFor: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
  backButton: {
    marginBottom: 15,
  },
});

export default Contacts;
