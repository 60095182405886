import React from 'react';
import PropTypes from 'prop-types';
import {
  FormHelperText,
  Typography,
  makeStyles,
  TextField,
} from '@material-ui/core';

const FormField = ({
  autoFocus,
  canEdit,
  children,
  error,
  innerRef,
  isStacked,
  label,
  fullWidth,
  filled,
  helperText,
  margin,
  multiline,
  name,
  onChange,
  InputProps,
  select,
  tooltip,
  type,
  value,
  variant,
  options,
  rows
}) => {
  const classes = useStyles();

  const stackedInputComponent = canEdit ? (
    <>
      <FormHelperText>{label}</FormHelperText>
      <TextField
        autoFocus={autoFocus}
        className={classes.stackedInput}
        error={error}
        fullWidth={fullWidth}
        helperText={helperText}
        id={name}
        innerRef={innerRef}
        InputProps={InputProps}
        margin={margin}
        multiline={multiline}
        name={name}
        data-testid={name}
        onChange={onChange}
        select={select}
        value={value || ''}
        variant={variant}
        type={type}
        rows={rows}
      >
        {children}
      </TextField>
    </>
  ) : (
    <>
      <FormHelperText>{label}</FormHelperText>
      <Typography align="left" noWrap className={classes.text}>
        {value ? value : '-'}
      </Typography>
    </>
  );

  let inputComponent = canEdit ? (
    <TextField
      type={type}
      autoFocus={autoFocus}
      className={classes.input}
      data-testid={name}
      error={error}
      filled={filled}
      fullWidth={fullWidth}
      helperText={helperText}
      id={name}
      innerRef={innerRef}
      InputProps={InputProps}
      label={label}
      margin={margin}
      multiline={multiline}
      name={name}
      onChange={onChange}
      select={select}
      value={value || ''}
      variant={variant}
      rows={rows}
    >
      {children}
    </TextField>
  ) : (
    <Typography align="right">
      {select ? options[value] : value ? value : '-'} {tooltip ? tooltip : ''}
    </Typography>
  );

  return <>{isStacked ? stackedInputComponent : inputComponent}</>;
};

FormField.propTypes = {
  canEdit: PropTypes.bool,
  children: PropTypes.node,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  margin: PropTypes.string,
  multiline: PropTypes.bool,
  variant: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  select: PropTypes.bool,
  styleClasses: PropTypes.object,
  tooltip: PropTypes.string,
  value: PropTypes.node,
};

const useStyles = makeStyles({
  formControl: {
    width: '100%',
  },
  text: {
    height: 29,
    margin: '5px 0 0',
  },
  stackedInput: {
    margin: '5px 0 0',
    '& input': {
      padding: 0,
    },
  },
  input: {
    height: 24,
    margin: '5px 0 0',
    '& input': {
      padding: 0,
      height: '25px',
    },
  },
});

export default FormField;
