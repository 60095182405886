import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth0 } from '../../react-auth0-wrapper';

import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
} from '@material-ui/core';

const NavAvatar = () => {
  const { user } = useAuth0();
  const classes = useStyles();
  const { name, picture } = user;

  return (
    // NavLink Allows for Active Class as Opposed to Link
    <NavLink
      activeClassName={classes.active}
      className={classes.root}
      to="/my-profile"
    >
      <ListItem button className={classes.navItem}>
        <ListItemAvatar>
          <Avatar className={classes.avatar} alt={name} src={picture} />
        </ListItemAvatar>
        <ListItemText className={classes.name} primary={name} />
      </ListItem>
    </NavLink>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    textDecoration: 'none',
    color: '#fff',
    fontSize: 18,
    display: 'flex',
  },
  navItem: {
    borderRadius: '5px',
    height: '54px',
    marginBottom: '10px',
    marginLeft: -8,
  },
  avatar: {
    border: '2px solid #fff',
    height: '48px',
    width: '48px',
  },
  active: {
    color: theme.palette.blueSky.main,
  },
  name: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export default NavAvatar;
