import { DeleteForever } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const DeleteButton = ({ onClick }) => (
  <IconButton onClick={onClick} size="small">
    <DeleteForever />
  </IconButton>
);

DeleteButton.propTypes = {
  onClick: PropTypes.func,
};

export default DeleteButton;
