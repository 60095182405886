import { AddNew, All, FacilityProfile } from '../views/FacilitySystem';

import { PrivateRoute } from '../components/common/Privatize';
import React from 'react';

const FacilitySystem = ({ match }) => {
  return (
    <>
      <PrivateRoute
        path={`${match.url}/all-facilities`}
        render={props => <All {...props} />}
      />
      <PrivateRoute
        path={`${match.url}/add-new`}
        render={props => <AddNew {...props} />}
        reqScope={['facility:create']}
      />
      <PrivateRoute
        path={`${match.url}/profile/id/:id`}
        render={props => <FacilityProfile {...props} />}
      />
    </>
  );
};

export default FacilitySystem;
