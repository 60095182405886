import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect } from 'react';

const Dropdown = ({
  label,
  name,
  options,
  editHook,
  value,
  setValid,
  required,
  error,
  errorText,
}) => {
  const classes = useStyles();
  value = value ? value : 0;
  setValid = setValid ? setValid : () => '';

  useEffect(() => {
    if (required && (value === 0 || value === '0')) {
      setValid({ [name]: false });
    } else {
      setValid({ [name]: true });
    }
  }, [value, required, setValid, name]);

  const menuItems = options => {
    const items = [];
    for (let option in options) {
      items.push(
        <MenuItem key={option} value={option}>
          {options[option]}
        </MenuItem>
      );
    }
    return items;
  };

  return (
    <FormControl>
      <InputLabel value={value}>{label}</InputLabel>
      <Select
        className={classes.select}
        value={value}
        onChange={e => editHook(e.target.value, name)}
        inputProps={{
          name: name,
        }}
      >
        {menuItems(options)}
      </Select>
      <FormHelperText className={classes.formHelper} error>
        {error[name] ? errorText : null}
      </FormHelperText>
    </FormControl>
  );
};

const useStyles = makeStyles({
  formControl: {
    width: '100%',
    textAlign: 'right',
  },
});

export default Dropdown;
