import { parsePhoneNumberFromString } from 'libphonenumber-js';

const formatPhoneNumber = phoneNumber => {
  // Format number to be easier to read
  const formattedPhoneNumber = parsePhoneNumberFromString(phoneNumber);

  //   Formate US numbers
  if (phoneNumber.substring(0, 2) === '+1') {
    return formattedPhoneNumber.formatNational();
  }
  //   Format MX numbers
  if (phoneNumber.substring(0, 3) === '+52') {
    return formattedPhoneNumber.formatInternational();
  }
};
export default formatPhoneNumber;
