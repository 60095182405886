import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, makeStyles } from '@material-ui/core';
import { Selector } from '../../common/CardContent';
import { Typeahead } from '../../common/Typeahead';

import { updateTypes } from '../../../util/options';

const AddUpdateForm = ({ onAdd }) => {
  const classes = useStyles();

  const [updateType, setUpdateType] = useState('0');
  const [searchString, setSearchString] = useState('');
  const [cityName, setCityName] = useState('');
  const [cityState, setCityState] = useState('');

  const handleTypeChange = type => setUpdateType(type);

  const handleSearchStringChange = ({
    city,
    stateShortName,
    stateLongName,
  }) => {
    setSearchString(`${city}, ${stateShortName}`);
    setCityName(city);
    setCityState(stateLongName);
  };

  const handleAddUpdate = () => {
    const newUpdate = {
      updateTypeId: updateType,
      cityName,
      cityState,
    };

    onAdd(newUpdate);
    setUpdateType('0');
    setSearchString(' ');
    setCityName('');
    setCityState('');
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typeahead
          urlString="geo/autocomplete/city"
          value={searchString}
          placeholder=""
          onChange={handleSearchStringChange}
          noRecordsFoundMsg="No cities found!"
        />
      </Grid>
      <Grid item xs={6}>
        <Selector
          value={updateType}
          options={updateTypes}
          editHook={handleTypeChange}
          edit
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="flex-end">
          <Button
            color="primary"
            variant="contained"
            size="small"
            className={classes.button}
            onClick={handleAddUpdate}
            disabled={!(updateType !== '0' && searchString)}
          >
            Add
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

AddUpdateForm.propTypes = {
  onAdd: PropTypes.func.isRequired,
};

const useStyles = makeStyles({
  button: { color: '#fff' },
});

export default AddUpdateForm;
