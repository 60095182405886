import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles, Grid, StepConnector, Typography } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import { numberToCommaDelimited } from '../../util/numberUtils';

const DistanceDivider = ({ distance }) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center" className={classes.container}>
      <StepConnector />
      <Grid className={classes.distance}>
        <ArrowDownwardIcon
          color="primary"
          fontSize="small"
          className={classes.icon}
        />
        <Typography color="secondary">
          {numberToCommaDelimited(distance)} mi
        </Typography>
      </Grid>
      <StepConnector />
    </Grid>
  );
};

DistanceDivider.propTypes = {
  distance: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  container: {
    margin: '20px 0',
    '& .MuiStepConnector-line': {
      borderColor: theme.palette.secondary.main,
    },
  },
  distance: {
    padding: '0 25px',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: 10,
  },
}));

export default DistanceDivider;
