import { FormField } from '../common/Forms';
import { Grid } from '@material-ui/core';
import { PageHeader } from '../common/PageHeader';
import PropTypes from 'prop-types';
import React from 'react';
import { StackedText } from '../common/CardContent';
import { connect } from 'formik';
import { crossingModeOptions } from '../../util/options';
import { debounce } from 'lodash';

const EnterLoadHeader = ({ initialLoadData, formik }) => {
  const {
    shipMode,
    equipmentType,
    crossingModeId,
    crossingLocation,
    startingPickupLocation,
    endingDeliveryLocation,
  } = initialLoadData;

  const { handleChange, setFieldTouched, values } = formik;
  const { customerReference, bolNumber, trailerNumber } = values;

  const onSyncChange = e => debounce.bind(null, handleChange(e), 2000);

  const handleFieldChange = (fieldName, e) => {
    if (e.persist) {
      e.persist();
    }
    setFieldTouched(fieldName, true, false);
    return onSyncChange(e);
  };

  const secondRowContent = (
    <Grid container spacing={4} alignItems="flex-start">
      <Grid item>
        <StackedText label="Mode" value={shipMode} name="mode" />
      </Grid>
      <Grid item>
        <StackedText label="EQ Type" value={equipmentType} name="eqType" />
      </Grid>
      <Grid item>
        <StackedText label="EQ Length" value="53" name="eqLength" />
      </Grid>
      {crossingLocation && (
        <>
          <Grid item>
            <StackedText
              label="Crossing Mode"
              value={crossingModeOptions[crossingModeId]}
              name="type"
            />
          </Grid>
          <Grid item>
            <StackedText
              label="Crossing City"
              value={crossingLocation}
              name="crossingCity"
            />
          </Grid>
        </>
      )}
      <Grid item xs={2}>
        <StackedText
          label="Pickup Location"
          value={startingPickupLocation && startingPickupLocation}
          name="pickupLocation"
        />
      </Grid>
      <Grid item xs={2}>
        <StackedText
          label="Delivery Location"
          value={endingDeliveryLocation && endingDeliveryLocation}
          name="deliveryLocation"
        />
      </Grid>
      <Grid item>
        <FormField
          label="Reference #"
          name="customerReference"
          value={customerReference}
          onChange={handleFieldChange.bind(null, 'customerReference')}
          isStacked={true}
          canEdit={true}
        />
      </Grid>
      <Grid item>
        <FormField
          label="BOL #"
          name="bolNumber"
          value={bolNumber}
          onChange={handleFieldChange.bind(null, 'bolNumber')}
          isStacked={true}
          canEdit={true}
        />
      </Grid>
    </Grid>
  );

  return <PageHeader name="New Shipment" secondRowContent={secondRowContent} />;
};

EnterLoadHeader.propTypes = {
  initialLoadData: PropTypes.object,
  formik: PropTypes.object,
};

export default connect(EnterLoadHeader);
