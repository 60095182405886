import React, { useState, useEffect, useCallback } from 'react';
import { ShadowCard } from '../../common/Containers';
import { CardHead } from '../../common/CardContent';
import { PrivateComponent } from '../../common/Privatize';
import { Grid, Button, makeStyles } from '@material-ui/core';
import { useAuth0 } from '../../../react-auth0-wrapper';
import { getAxios } from '../../../util';
import IncidentCard from './IncidentCard';
import CreateIncident from './CreateIncident';

const LoadIncidents = ({ loadId, isForager }) => {
  const classes = useStyles();
  const { accessToken } = useAuth0();
  const [edit, setEdit] = useState(false);
  const [existingIncidents, setExistingIncidents] = useState([]);

  const PrivateAddButton = (
    <PrivateComponent reqScopes={['forager:read']}>
      <Button
        color="primary"
        variant="contained"
        onClick={setEdit}
        size="small"
        className={classes.addButton}
      >
        Add
      </Button>
    </PrivateComponent>
  );

  // get incidents by load id
  const getLoadIncidents = useCallback(() => {
    getAxios('load/load-incidents', { params: { loadId } }, accessToken)
      .then(res => res && setExistingIncidents(res))
      .catch(err => console.log(err));
  }, [loadId, accessToken]);

  useEffect(() => {
    !edit && getLoadIncidents();
  }, [loadId, accessToken, getLoadIncidents, edit]);

  const mappedIncidents = existingIncidents.map(incident => {
    return (
      <IncidentCard
        {...incident}
        key={incident.incidentId}
        getLoadIncidents={getLoadIncidents}
        accessToken={accessToken}
      />
    );
  });

  return (
    <ShadowCard>
      <CardHead headingText="Incidents" />
      {!edit ? (
        // view incidents
        <>
          <Grid className={classes.list}>{mappedIncidents}</Grid>
          <Grid item xs={12} container justify="flex-end">
            {PrivateAddButton}
          </Grid>
        </>
      ) : (
        // create a new incident
        <CreateIncident
          accessToken={accessToken}
          edit={edit}
          setEdit={setEdit}
          loadId={loadId}
        />
      )}
    </ShadowCard>
  );
};

export default LoadIncidents;

const useStyles = makeStyles({
  list: {
    maxHeight: '370px',
    overflow: 'auto',
    margin: '0px 0px',
  },
  addButton: {
    marginTop: '20px',
  },
});
