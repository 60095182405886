import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import React from 'react';

const AddButton = ({ onClick }) => {
  return (
    <Button
      onClick={onClick}
      variant="contained"
      component={Link}
      color="primary"
    >
      Add
    </Button>
  );
};

export default AddButton;
