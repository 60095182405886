import {
  AppBar,
  Badge,
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  CustomerPortal,
  CustomerSystem,
  CustomsBrokerSystem,
  FacilitySystem,
  LoadSystem,
  ToolSystem,
} from './routers';
import {
  MoreHoriz,
  MoreVert,
  Notifications as NotificationsIcon,
} from '@material-ui/icons';
import React, { useEffect, useState } from 'react';

import AnnounceKit from 'announcekit-react';
import CacheBuster from './components/common/Utility/CacheBuster';
import { LightDivider } from './components/common/Dividers';
import { Loader } from './components/common/Loader';
import Mobile from './views/Mobile/Mobile';
import { MyProfile } from './views';
import { NavDropdown } from './components/Nav';
import { PrivateRoute } from './components/common/Privatize';
import clsx from 'clsx';
import { getAxios } from './util';
import history from './util/history';
import { hotjar } from 'react-hotjar';
import logo from './assets/img/logo-white.svg';
import { useAuth0 } from './react-auth0-wrapper';

hotjar.initialize(
  process.env.REACT_APP_HOTJAR_ID,
  process.env.REACT_APP_HOTJAR_SNIPPET_VERSION
);

const App = () => {
  const classes = useStyles();
  const {
    isAuthenticated,
    loginWithRedirect,
    logout,
    loading,
    user,
    accessToken,
  } = useAuth0();

  const [userProfile, setUserProfile] = useState();
  const [latestVersion, setLatestVersion] = useState(null);

  useEffect(() => {
    fetch(`/meta.json?${new Date().getTime()}`, { cache: 'no-cache' })
      .then(response => response.json())
      .then(meta => setLatestVersion(meta.version));
  }, []);

  useEffect(() => {
    // Wait for authenticated and access token before fetching profile
    isAuthenticated &&
      accessToken &&
      getAxios('profile/my-profile', {}, accessToken).then(data => {
        setUserProfile(data);
      });
  }, [accessToken, isAuthenticated]);

  const [open, setOpen] = useState(true);
  const [mobile, setMoble] = useState(false);

  // Renders Mobile view if app is viewed on devices with a width of below 1200px
  useEffect(() => {
    const width = window.innerWidth;
    if (width <= 1200) {
      setMoble(true);
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated && userProfile && history.location.pathname === '/') {
      if (userProfile.companyId !== 1) {
        history.push(`/customer-portal/instant-rate`);
      }
      if (userProfile.companyId === 1) {
        history.push(`/loads/load-board`);
      }
    }
  }, [isAuthenticated, userProfile]);

  return (
    <CacheBuster>
      {({ cacheLoading, isLatestVersion, refreshCacheAndReload }) => {
        if (cacheLoading) return null;
        if (!cacheLoading && !isLatestVersion) {
          refreshCacheAndReload();
        }

        return loading ? (
          <Grid
            className={classes.loader}
            container
            justify="center"
            alignItems="center"
          >
            <Loader />
          </Grid>
        ) : (
          <Grid container>
            <AppBar
              color="default"
              className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
              })}
            >
              <Toolbar>
                <IconButton edge="start" onClick={() => setOpen(!open)}>
                  {open ? <MoreVert /> : <MoreHoriz />}
                </IconButton>
                <Typography variant="h3" className={classes.title}>
                  Welcome
                  {!isAuthenticated
                    ? ' to SCOUT by Forager'
                    : userProfile &&
                      `, ${user.name} | ${
                        userProfile.dbaName
                          ? userProfile.dbaName
                          : userProfile.legalName
                      }`}
                </Typography>
                {isAuthenticated && userProfile && (
                  <Box mr={3}>
                    <IconButton
                      className="ak-trigger"
                      aria-label="notifications"
                    >
                      <Badge
                        badgeContent={
                          <AnnounceKit
                            catchClick=".ak-trigger"
                            widget="https://announcekit.app/widget/2uqSXe"
                            userData={user.email}
                          />
                        }
                      >
                        <NotificationsIcon />
                      </Badge>
                    </IconButton>
                  </Box>
                )}
                <Button
                  className={classes.authBtn}
                  onClick={() => {
                    !isAuthenticated ? loginWithRedirect({}) : logout();
                  }}
                >
                  {!isAuthenticated ? 'Log in' : 'Log out'}
                </Button>
              </Toolbar>
            </AppBar>

            <Drawer
              className={classes.drawer}
              variant="persistent"
              anchor="left"
              open={open}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <div className={classes.drawerHeader}>
                <img src={logo} alt="Forager Logo" width="200px" />
              </div>
              <LightDivider variant="middle" />
              {isAuthenticated && <NavDropdown />}
              <a
                href="https://foragerscs.com/scout-terms-conditions/"
                target="_blank"
                className={classes.terms}
                rel="noopener noreferrer"
              >
                Terms & Conditions
                <Typography component="div" variant="overline" gutterBottom>
                  {`SCOUT v${latestVersion}`}
                </Typography>
              </a>
            </Drawer>
            <main
              className={clsx(classes.content, {
                [classes.contentShift]: open,
              })}
            >
              {mobile ? (
                <Mobile />
              ) : (
                <>
                  <PrivateRoute
                    path="/my-profile"
                    component={MyProfile}
                    reqScope={['forager:read', 'customer-portal:read']}
                  />

                  <PrivateRoute
                    path="/tools"
                    component={ToolSystem}
                    reqScope={['forager:read']}
                  />
                  <PrivateRoute
                    path="/customers"
                    component={CustomerSystem}
                    reqScope={['forager:read']}
                  />
                  <PrivateRoute
                    path="/loads"
                    component={LoadSystem}
                    reqScope={['forager:read']}
                  />
                  <PrivateRoute
                    path="/facilities"
                    component={FacilitySystem}
                    reqScope={['forager:read']}
                  />
                  <PrivateRoute
                    path="/customs-brokers"
                    component={CustomsBrokerSystem}
                    reqScope={['forager:read']}
                  />

                  <PrivateRoute
                    path="/customer-portal"
                    component={CustomerPortal}
                    reqScope={['customer-portal:read']}
                  />
                </>
              )}
            </main>
          </Grid>
        );
      }}
    </CacheBuster>
  );
};

const drawerWidth = 215;

const useStyles = makeStyles(theme => ({
  loader: {
    height: '100vh',
  },
  authBtn: {
    color: theme.palette.primary.main,
    marginRight: 8,
    border: '2px solid #37B4F2',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundImage: theme.palette.background.dark,
    boxShadow: '7px 0 60px rgba(0,0,0,.05)',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    // Keeps logo container same size as AppBar
    ...theme.mixins.toolbar,
    justifyContent: 'center',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow:
      '0 0.46875rem 2.1875rem rgba(8,10,37,.03), 0 0.9375rem 1.40625rem rgba(8,10,37,.03), 0 0.25rem 0.53125rem rgba(8,10,37,.05), 0 0.125rem 0.1875rem rgba(8,10,37,.03)',
    backgroundColor: '#fff',
    zIndex: 9999,
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    marginTop: 64,
    minHeight: 'calc(100vh - 64px - 48px)',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 'calc(100vw - 24px)',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginTop: 64,
    marginLeft: 0,
    minHeight: 'calc(100vh - 64px - 48px)',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 'calc(100vw - 215px - 24px)',
  },
  title: {
    flexGrow: 1,
  },
  terms: {
    textAlign: 'center',
    color: 'darkgrey',
    position: 'absolute',
    textDecoration: 'none',
    bottom: 20,
    width: '100%',
  },
}));

export default App;
