import React, { useEffect, useState } from 'react';

import { FilterTable } from '../common/Tables';
import { Grid } from '@material-ui/core';
import { Loader } from '../common/Loader';
import { ShadowCard } from '../common/Containers';
import formatPhoneNumber from '../../util/formatPhoneNumber';
import { getAxios } from '../../util/axiosHelper';
import { useAuth0 } from '../../react-auth0-wrapper';
import EyeBallLinkCell from '../common/Tables/EyeBallLinkCell';

const AllFacilities = () => {
  const [facilities, setFacilities] = useState([]);
  const [loading, setLoading] = useState(true);
  const { accessToken } = useAuth0();

  useEffect(() => {
    getAxios('facility/all', {}, accessToken).then(data => {
      // make sure data is defined, else will break when 
      // db call fails
      if (data) {
        const facilityData = data.map(facility => {
          return {
            ...facility,
            facility: facility.dbaName ? facility.dbaName : facility.legalName,
          };
        });
        setFacilities(facilityData);
      }
      setLoading(false);
    });
  }, [accessToken]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {loading ? (
          <Loader />
        ) : (
          <ShadowCard noTopPadding>
            <FilterTable
              title="All Facilities"
              columns={columns}
              data={facilities}
              filtering
            />
          </ShadowCard>
        )}
      </Grid>
    </Grid>
  );
};

const columns = [
  EyeBallLinkCell('/facilities/profile/id', 'companyId'),
  {
    title: 'Facility',
    field: 'facility',
  },
  {
    title: 'Address',
    field: 'address',
  },
  { title: 'City', field: 'city' },
  {
    title: 'Main POC',
    field: 'pocName',
  },
  {
    title: 'POC Phone',
    field: 'pocPhone',
    render: ({ pocPhone }) => pocPhone && formatPhoneNumber(pocPhone),
  },
];

export default AllFacilities;
