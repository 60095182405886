import { CardHead, TimeRange } from '../CardContent';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { getAxios, putAxios } from '../../../util';

import { LightDivider } from '../Dividers';
import { Loader } from '../Loader';
import PropTypes from 'prop-types';
import { SaveEditIconButton } from '../Buttons';
import { ShadowCard } from '../Containers';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import { useAuth0 } from '../../../react-auth0-wrapper';

const weekdays = [
  {
    name: 'mon',
    label: 'Monday',
  },
  {
    name: 'tue',
    label: 'Tuesday',
  },
  {
    name: 'wed',
    label: 'Wednesday',
  },
  {
    name: 'thu',
    label: 'Thursday',
  },
  {
    name: 'fri',
    label: 'Friday',
  },
  {
    name: 'sat',
    label: 'Saturday',
  },
  {
    name: 'sun',
    label: 'Sunday',
  },
];

const Hours = ({ params, title, getPath, putPath }) => {
  const classes = useStyles();

  const { accessToken } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [dataNotFound, setDataNotFound] = useState(false);
  const [edit, setEdit] = useState(false);
  const [hours, setHours] = useState({});

  const handleEdit = () => setEdit(true);

  const handleSave = () => {
    putAxios(putPath, { payload: { ...params, ...hours } }, accessToken);
    setEdit(false);
  };

  const handleChange = (key, value) => {
    setHours({ ...hours, [`${key}Open`]: value[0], [`${key}Close`]: value[1] });
  };

  useEffect(() => {
    getAxios(getPath, { params }, accessToken).then(data => {
      if (data) {
        setHours(data);
      } else {
        setDataNotFound(true);
      }
      setLoading(false);
    });
  }, [accessToken, params, getPath]);

  const timeZone = moment.tz.guess();

  const timeRanges = {
    mon: [hours.monOpen, hours.monClose],
    tue: [hours.tueOpen, hours.tueClose],
    wed: [hours.wedOpen, hours.wedClose],
    thu: [hours.thuOpen, hours.thuClose],
    fri: [hours.friOpen, hours.friClose],
    sat: [hours.satOpen, hours.satClose],
    sun: [hours.sunOpen, hours.sunClose],
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        !dataNotFound && (
          <ShadowCard>
            <CardHead
              headingText={title}
              action={
                <SaveEditIconButton
                  edit={edit}
                  onClick={edit ? handleSave : handleEdit}
                />
              }
            />
            {hours.name && hours.city && hours.state && (
              <>
                <Grid container className={classes.head}>
                  <Grid item xs={6}>
                    <Typography color="secondary">{hours.name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography color="secondary">
                      {hours.city}, {hours.state}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid>
                  <LightDivider />
                </Grid>
              </>
            )}

            <Grid container spacing={1} className={classes.form}>
              {weekdays.map(({ name, label }) => (
                <Grid item xs={12} key={name}>
                  <TimeRange
                    label={label}
                    name={name}
                    timeZone={timeZone}
                    timeRange={timeRanges[name]}
                    edit={edit}
                    editHook={handleChange}
                    setValid={() => null} // TODO Will remove this when we add proper validation
                  />
                </Grid>
              ))}
            </Grid>
          </ShadowCard>
        )
      )}
    </>
  );
};

Hours.propTypes = {
  params: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired,
  getPath: PropTypes.string.isRequired,
  putPath: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
  head: {
    margin: '10px 0 5px 0',
  },
  form: {
    marginTop: 15,
  },
});

export default Hours;
