import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { CustomsBrokersTable } from '../../components/CustomsBrokerSystem';

const AllCustomsBrokers = ({ history }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CustomsBrokersTable history={history} />
      </Grid>
    </Grid>
  );
};

AllCustomsBrokers.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default AllCustomsBrokers;
