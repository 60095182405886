import React, { useState } from 'react';

import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core';

const ModalContainer = props => {
  const classes = useStyles();

  const {
    open,
    handleClose,
    modalWidth,
    dataTestId,
    noPadding = false,
    paperView = true,
  } = props;

  const [modalStyle] = useState(getModalStyle(modalWidth, noPadding));

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={handleClose}
      data-testid={dataTestId}
    >
      <div style={modalStyle} className={paperView ? classes.paper : null}>
        {props.children}
      </div>
    </Modal>
  );
};

function getModalStyle(width, noPadding) {
  const top = 50;
  const left = 50;
  const newWidth = width ? width : '400px';

  const modalStyles = {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    width: newWidth,
    position: 'absolute',
  };

  if (noPadding) {
    modalStyles.padding = '0';
  }
  return modalStyles;
}

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
    borderRadius: '10px',
  },
}));

export default ModalContainer;
