import React from 'react';
import moment from 'moment';
import {
  Grid,
  Typography,
  Divider,
  Button,
  makeStyles,
} from '@material-ui/core';
import { PrivateComponent } from '../../common/Privatize';
import { putAxios } from '../../../util';

const IncidentCard = ({
  description,
  createdByFirst,
  createdByLast,
  type,
  timeLogged,
  timeOccurred,
  incidentId,
  isResolved,
  timeResolved,
  getLoadIncidents,
  accessToken,
}) => {
  const classes = useStyles();

  const normalizeTime = time => {
    return moment(time).format('MM-DD-YY HH:mm');
  };

  // update resolve status
  const updateResolved = () => {
    const resolvedTime = new Date();
    putAxios('load/load-incidents', { incidentId, resolvedTime }, accessToken)
      .then(res => {
        getLoadIncidents();
      })
      .catch(err => console.log(err));
  };

  const PrivateResolvedButton = (
    <PrivateComponent reqScopes={['forager:read']}>
      <Button
        color="primary"
        variant="contained"
        onClick={updateResolved}
        size="small"
      >
        Resolved
      </Button>
    </PrivateComponent>
  );

  return (
    <Grid container>
      <Grid item xs={11}>
        <Typography className={classes.incidentText}>{type}</Typography>
      </Grid>
      <Grid item xs={11}>
        <Typography variant="body2" color="textSecondary">
          {`By ${createdByFirst} ${createdByLast}`}
        </Typography>
      </Grid>
      <Grid item>
        <Typography color="textSecondary" variant="body2">
          {timeLogged ? `${normalizeTime(timeLogged)}` : ''}
        </Typography>
      </Grid>
      <Grid item xs={11}>
        <Typography className={classes.description}>{description}</Typography>
      </Grid>
      {timeOccurred && (
        <Grid container spacing={1}>
          <Grid item>
            <Typography>Incident Date:</Typography>
          </Grid>
          <Grid item>
            <Typography>{`${normalizeTime(timeOccurred)}`}</Typography>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} className={classes.resolved}>
        {isResolved ? (
          <Grid container spacing={1}>
            <Grid item>
              <Typography variant="body2" color="textSecondary">
                Resolved:
              </Typography>
            </Grid>
            <Grid item>
              <Typography color="textSecondary" variant="body2">
                {timeResolved ? `${normalizeTime(timeResolved)}` : ''}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          PrivateResolvedButton
        )}
      </Grid>
      <Grid item xs={12}>
        <Divider className={classes.divider} />
      </Grid>
    </Grid>
  );
};

export default IncidentCard;

const useStyles = makeStyles({
  incidentText: {
    marginTop: '10px',
  },
  description: {
    marginTop: '10px',
  },
  resolved: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  divider: {
    marginBottom: '10px',
  },
});
