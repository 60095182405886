import 'react-phone-number-input/style.css';

import React, { useEffect } from 'react';

import { CardItem } from './';
import PhoneInput from 'react-phone-number-input';
import { PhoneLink } from '../Utility';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { makeStyles } from '@material-ui/core';

const Phone = ({
  number,
  editHook,
  setValid,
  label,
  name,
  edit,
  required,
  error,
  canSms,
  errorText,
}) => {
  const classes = useStyles();
  // set Phone Number to empty string if enters as null
  number = number ? number : '';
  setValid = setValid ? setValid : (setValid = () => '');
  editHook = editHook ? editHook : (editHook = () => '');

  useEffect(() => {
    if (required && isValidPhoneNumber(number)) {
      setValid({ [name]: true });
      // if not required the number must either be valid or empty string
    } else if (!required && (isValidPhoneNumber(number) || number === '')) {
      setValid({ [name]: true });
    } else {
      setValid({ [name]: false });
    }
  }, [number, name, setValid, required]);

  let content = edit ? (
    <PhoneInput
      className={classes.phone}
      value={number}
      country="US"
      international={false}
      countries={['US', 'CA', 'MX']}
      onChange={phone => editHook(phone === undefined ? null : phone, name)}
    />
  ) : (
    <PhoneLink canSms={canSms} number={number} alignment="right" />
  );

  return (
    <CardItem
      label={label}
      name={name}
      error={error}
      errorText={errorText}
      content={content}
    />
  );
};

const useStyles = makeStyles({
  phone: {
    flex: 1,
    minHeight: 25,
    '& .react-phone-number-input__icon': {
      border: 0,
    },
  },
  phoneInput: {
    textAlign: 'right',
    fontSize: '1rem',
    height: 24,
  },
  phoneLink: {
    fontSize: 15,
  },
});

export default Phone;
