import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { CustomerHeader } from '../../components/CustomerSystem';
import { Route, Redirect } from 'react-router-dom';
import { MainView, RatesView, OperationsView } from './CustomerViews';
import { Contacts } from '../../components/common/Contacts';
import { AllLoads } from '../../components/CustomerSystem';

const CustomerView = ({ match }) => {
  const companyId = match.params.id;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CustomerHeader customerId={companyId} />
      </Grid>

      <Grid item xs={12}>
        <Route
          path={`${match.path}/main`}
          render={props => <MainView {...props} />}
        />
        <Route
          path={`${match.path}/contacts`}
          render={props => (
            <Contacts
              {...props}
              id={companyId}
              getPath="company/contacts"
              putPath="company/contacts"
              rolesFor="customer"
              slug="customer"
            />
          )}
        />
        <Route
          path={`${match.path}/rates`}
          render={props => <RatesView {...props} companyId={companyId} />}
        />
        <Route
          path={`${match.path}/ops`}
          render={props => <OperationsView {...props} companyId={companyId} />}
        />
        <Route
          path={`${match.path}/loads`}
          render={props => <AllLoads companyId={companyId} {...props} />}
        />
        {/* Redirects if missing /main */}
        <Route
          exact
          path={`${match.path}`}
          render={props => <Redirect to={`${match.url}/main`} {...props} />}
        />
      </Grid>
    </Grid>
  );
};

CustomerView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default CustomerView;
