import { Hours } from '../common/Hours';
import { ModalContainer } from '../common/Modals';
import PropTypes from 'prop-types';
import React from 'react';

const OperationHoursModal = ({ isOpen, handleClose, details }) => {
  const { facilityId } = details;

  return (
    <ModalContainer
      noPadding
      open={isOpen}
      modalWidth="40%"
      handleClose={handleClose}
    >
      <Hours
        params={{ companyId: facilityId }}
        title="Hours of Operation"
        getPath="facility/hours"
        putPath="facility/hours"
      />
    </ModalContainer>
  );
};

OperationHoursModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

OperationHoursModal.defaultProps = {
  customsBroker: null,
};

export default OperationHoursModal;
