import React, { useState, useCallback, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { getAxios, numberToUSD, deleteAxios } from '../../util';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Loader } from '../common/Loader';

import { ShadowCard } from '../common/Containers';
import { SaveEditIconButton, DeleteButton } from '../common/Buttons';
import { CardHead } from '../common/CardContent';
import ContractedRatesModal from './ContractedRatesModal';
import { useAuth0 } from '../../react-auth0-wrapper';

const CustomerContractedRate = ({ companyId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [canEdit, setCanEdit] = useState(true);
  const [currentContractRate, setCurrentContractRate] = useState({});
  const [contractedRates, setContractedRates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { accessToken } = useAuth0();

  const handleModal = () => setIsModalOpen(!isModalOpen);

  const getContractedRates = useCallback(() => {
    getAxios(
      'customer/get-contracted-rates',
      { params: { companyId } },
      accessToken
    ).then(contractedRatesRes => {
      setContractedRates(contractedRatesRes);
      setIsLoading(false);
    });
  }, [accessToken, companyId]);

  useEffect(() => {
    setIsLoading(true);

    getContractedRates();
  }, [getContractedRates]);

  const handleContractedRatesModal = currentContractedRate => {
    setCurrentContractRate(currentContractedRate);
    setCanEdit(false);
    handleModal();
  };

  const createNewContractedRate = () => {
    setCurrentContractRate({});
    setCanEdit(true);
    handleModal();
  };

  const deleteContractedRate = id => {
    deleteAxios(
      'customer/remove-contract-rate',
      { contractedRateId: id },
      accessToken
    ).then(getContractedRates);
  };

  return (
    <>
      <ShadowCard>
        <CardHead
          headingText="Rate Overrides"
          action={
            <Button
              variant="contained"
              color="primary"
              onClick={createNewContractedRate}
            >
              Add Rate Override
            </Button>
          }
        />
        {isLoading ? (
          <Loader />
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Origin</TableCell>
                <TableCell>Destination</TableCell>
                <TableCell>Crossing</TableCell>
                <TableCell>Eq Type</TableCell>
                <TableCell>Rate</TableCell>
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {contractedRates &&
                contractedRates.length >= 1 &&
                contractedRates.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{`${row.originCity}, ${row.originState}`}</TableCell>
                    <TableCell>{`${row.deliveryCity}, ${row.deliveryState}`}</TableCell>
                    <TableCell>
                      {row.crossingCity
                        ? `${row.crossingCity}, ${row.crossingState}`
                        : '---'}
                    </TableCell>
                    <TableCell>{row.eq_type}</TableCell>
                    {row.directRate ? (
                      <TableCell>{`Direct - ${numberToUSD(
                        row.directRate
                      )}`}</TableCell>
                    ) : null}
                    {row.transloadRate ? (
                      <TableCell>{` Transload - ${numberToUSD(
                        row.transloadRate
                      )}`}</TableCell>
                    ) : null}
                    <TableCell>
                      <SaveEditIconButton
                        edit={false}
                        onClick={() => handleContractedRatesModal(row)}
                      />
                    </TableCell>
                    <TableCell>
                      <DeleteButton
                        onClick={() =>
                          deleteContractedRate(row.contractedRateId)
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        )}
      </ShadowCard>
      {isModalOpen && (
        <ContractedRatesModal
          isModalOpen={isModalOpen}
          handleModal={handleModal}
          customerId={companyId}
          currentContractedRate={currentContractRate}
          canEdit={canEdit}
          getContractedRates={getContractedRates}
        />
      )}
    </>
  );
};

export default CustomerContractedRate;
