import React from 'react';
import { Grid } from '@material-ui/core';
import { AddNewCustomsBroker } from '../../components/CustomsBrokerSystem';

const AddNew = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <AddNewCustomsBroker />
      </Grid>
    </Grid>
  );
};

export default AddNew;
