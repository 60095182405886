const userHasScopes = (reqScopes, userScopes) => {
  // If they have no Scopes Return False
  if (!userScopes) {
    return false;
  }
  // Convert Users Scopes to an Arrayg
  const usersGrantedScopes = userScopes.split(' ');
  // Checks users Granted Scopes against the Scope(s) Passed in via Argument
  return reqScopes.some(scope => usersGrantedScopes.includes(scope));
};

export default userHasScopes;
