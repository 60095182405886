import React, { useEffect, useState } from 'react';

import { PageHeader } from '../common/PageHeader';
import { getAxios } from '../../util/axiosHelper';
import { useAuth0 } from '../../react-auth0-wrapper';

const CustomsBrokerProfileHeader = ({ companyId }) => {
  const { accessToken } = useAuth0();
  const [info, setInfo] = useState({
    chbName: '',
    chbCity: '',
    chbState: '',
  });

  useEffect(() => {
    getAxios(
      'customs-broker/header',
      { params: { companyId } },
      accessToken
    ).then(data => {
      const chbInfo = {
        ...data,
        name: data.dbaName ? data.dbaName : data.legalName,
      };
      setInfo(chbInfo);
    });
  }, [companyId, accessToken]);

  return <PageHeader name={info.name} location={info.city} />;
};

export default CustomsBrokerProfileHeader;
