import { Edit, Save } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import React from 'react';

const SaveEditIconButton = ({ onClick, edit }) => {
  return (
    <IconButton onClick={onClick} size="small">
      {edit ? <Save /> : <Edit />}
    </IconButton>
  );
};

export default SaveEditIconButton;
