import {
  Grid,
  Box,
  Button,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, { useState } from 'react';
import {
  equipmentTypesId,
  mexBorderCrossings,
  mexBorderCrossingsCoordinates,
} from '../../util/options';
import { getAxios, postAxios } from '../../util/axiosHelper';
import { numberToCommaDelimited, numberToUSD } from '../../util/numberUtils';

import { CSVLink } from 'react-csv';
import CSVReader from 'react-csv-reader';
import { CardHead } from '../../components/common/CardContent';
import { Loader } from '../../components/common/Loader';
import NumberFormat from 'react-number-format';
import { ShadowCard } from '../../components/common/Containers';
import { useAuth0 } from '../../react-auth0-wrapper';

const BulkUploader = () => {
  const { accessToken } = useAuth0();
  const classes = useStyles();

  const [progressBar, setProgressBar] = useState(0);
  const [progressBarIncrement, setProgressBarIncrement] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [rateRequests, setRateRequests] = useState();
  const [normalizedRateRequests, setNormalizedRateRequests] = useState([]);
  const [priceRates, setPriceRates] = useState([]);

  //These are the headers used to generate outgoing CSV file
  const csvHeaders = [
    { label: 'Origin', key: 'origin' },
    { label: 'Destination', key: 'destination' },
    { label: 'Crossing', key: 'crossingName' },
    { label: 'Eq Type', key: 'equipmentType' },
    { label: 'Load Value', key: 'loadValue' },
    { label: 'Hazmat', key: 'isHazmat' },
    { label: 'Team Service', key: 'isTeamService' },
    { label: 'Direct', key: 'direct' },
    { label: 'Rate', key: 'rate' },
    { label: 'Transload', key: 'transload' },
  ];

  const normalizedCSVData = ratesToCsv =>
    ratesToCsv.map(rate => ({
      origin: rate.pickupAddressInputs[0].address,
      destination: rate.deliveryAddressInputs[0].address,
      crossingName: rate.crossingName,
      equipmentType: rate.equipmentType,
      loadValue: numberToUSD(rate.loadValue),
      isHazmat: rate.isHazmat && rate.isHazmat.checked ? 'Y' : 'N',
      isTeamService:
        rate.isTeamService && rate.isTeamService.checked ? 'Y' : 'N',
      direct: rate.direct ? numberToUSD(rate.direct) : '-',
      rate: rate.rate ? numberToUSD(rate.rate) : '-',
      transload: rate.transload ? numberToUSD(rate.transload) : '-',
      totalMileage: rate.totalMileage
        ? `${numberToCommaDelimited(rate.totalMileage)} MI`
        : '-',
    }));

  //This CSV reader uses PapaParse, link to possible configs: https://www.papaparse.com/docs#config
  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: header => header.toLowerCase().replace(/\W/g, '_'),
  };

  const normalizeStringToBoolean = string => ({
    checked: string.toUpperCase() === 'Y' ? true : false,
  });

  async function normalizeRatePayload(rates) {
    setIsLoading(true);
    const payloadPromises = rates.map(async rate => {
      const [pickupCity, pickupState] = rate.origin.split(',');
      const pickUpCityGeo = await getAxios(
        'geo/geocode/temp',
        { params: { city: pickupCity, state: pickupState.trim() } },
        accessToken
      );
      const pickupCityInfo = await getAxios(
        'geo/city/info',
        {
          params: {
            city: pickUpCityGeo.city,
            state: pickUpCityGeo.stateShortName,
          },
        },
        accessToken
      );

      if (!pickupCityInfo.cityId) {
        const pickupCityPermInfo = await getAxios(
          'geo/geocode/perm',
          {
            params: {
              city: pickUpCityGeo.city,
              state: pickUpCityGeo.stateLongName,
            },
          },
          accessToken
        );

        if (pickupCityPermInfo) {
          postAxios(
            'geo/create/city',
            { city: pickupCityPermInfo },
            accessToken
          );
        }
      }

      const [destinationCity, destinationState] = rate.destination.split(',');

      const deliveryCityGeo = await getAxios(
        'geo/geocode/temp',
        { params: { city: destinationCity, state: destinationState } },
        accessToken
      );

      const deliveryCityInfo = await getAxios(
        'geo/city/info',
        {
          params: {
            city: deliveryCityGeo.city,
            state: deliveryCityGeo.stateLongName,
          },
        },
        accessToken
      );

      if (!deliveryCityInfo.cityId) {
        const deliveryCityPermInfo = await getAxios(
          'geo/geocode/perm',
          {
            params: {
              city: deliveryCityGeo.city,
              state: deliveryCityGeo.stateLongName,
            },
          },
          accessToken
        );

        if (deliveryCityPermInfo) {
          postAxios(
            'geo/create/city',
            { city: deliveryCityPermInfo },
            accessToken
          );
        }
      }

      const handlesIsMexCrossing = currentCrossingCity => {
        const normalizedMXCrossingOptions = Object.values(
          mexBorderCrossings
        ).map(crossing => crossing.split(' /').splice(0, 1)[0]);
        return normalizedMXCrossingOptions.includes(currentCrossingCity);
      };

      const renderLocationInfo = location => {
        if (location) {
          return {
            stop: 1,
            address: location.cityName
              ? `${location.cityName}, ${location.state} ${location.country}`
              : 'No matching cities found for the state provided. Please make sure that the state code is valid.',
            coordinates: [location.lng, location.lat],
            regionId: location.regionId,
            marketId: location.marketId,
            cityId: location.cityId,
            lat: location.lat,
            lng: location.lng,
            country: location.shortCountry,
            countryShortName:
              location.shortCountry &&
              typeof location.shortCountry === 'string' &&
              location.shortCountry.toLowerCase(),
          };
        }
        return null;
      };

      const pickupAddressInput = renderLocationInfo(pickupCityInfo);

      const deliveryAddressInput = renderLocationInfo(deliveryCityInfo);

      const returnMxBorderCoordinates = crossing => {
        let mxCordinatesMeta = mexBorderCrossingsCoordinates.filter(
          borderCrossing => {
            let normalizedCrossCity = borderCrossing.crossCity.split(' /')[0];
            if (normalizedCrossCity === crossing) {
              return borderCrossing.coordinates;
            }

            return false;
          }
        );
        return mxCordinatesMeta[0];
      };

      const crossingCity = rate.crossing
        ? returnMxBorderCoordinates(rate.crossing)
        : null;

      return {
        customerId: 1,
        loadValue: rate.load_value.toString(),
        isHazmat: normalizeStringToBoolean(rate.hazmat),
        isTeamService: normalizeStringToBoolean(rate.team_service),
        equipmentType: rate.eq_type,
        equipmentTypeId: equipmentTypesId[rate.eq_type],
        isMxPresent: rate.crossing && handlesIsMexCrossing(rate.crossing),
        crossingName: rate.crossing && rate.crossing,
        crossingCity: rate.crossing && crossingCity.id,
        crossingCityCoordinates: rate.crossing
          ? [crossingCity.coordinates]
          : [],
        pickupAddressInputs: pickupAddressInput && [pickupAddressInput],
        deliveryAddressInputs: deliveryAddressInput && [deliveryAddressInput],
      };
    });

    const resolvedResults = await Promise.all(payloadPromises);
    setIsLoading(false);
    return resolvedResults;
  }

  const handleGetRatesThrottled = (requests, index) => {
    const throttleTime = 2000; // in milliseconds
    const currentRequest = requests[index];
    if (index < requests.length) {
      postAxios('pricing/get-rates', currentRequest, accessToken).then(
        rateResponse => {
          // using rateResponse.[propertyName] when checking whether rateResponse exists, otherwise
          // this will break on errors (the error object becomes rateResponse, so rateResponse
          // evaluates to truthy, but the error object does not contain any of the listed
          // properties - pricing, totalMileage, etc.)
          setPriceRates(prevRates => [
            ...prevRates,
            {
              ...currentRequest,
              rate:
                rateResponse.pricing &&
                rateResponse.pricing.rates.lead48hr.rate,
              transload:
                rateResponse.pricing &&
                rateResponse.pricing.rates.lead48hr.transload,
              direct:
                rateResponse.pricing &&
                rateResponse.pricing.rates.lead48hr.direct,
              totalMileage:
                rateResponse.totalMileage && rateResponse.totalMileage,
              // !rateResponse alone will never evaluate to true, because errors are
              // also considered a valid "rateResponse" object, hence the .pricing
              noRateInfoAvailable: !rateResponse.pricing,
            },
          ]);
        }
      );
    }

    if (index === requests.length) {
      setIsLoading(false);
    }

    index++;

    setProgressBar(
      prevProgressBar => (prevProgressBar += progressBarIncrement)
    );
    setTimeout(handleGetRatesThrottled, throttleTime, requests, index);
  };

  const handleSubmitRateRequests = () => {
    Promise.resolve(normalizedRateRequests).then(response => {
      setIsLoading(true);
      handleGetRatesThrottled(response, 0);
    });
  };

  const handleRateRequestNormalization = csvData => {
    const increment = 100 / csvData.length;
    setProgressBarIncrement(increment);
    setRateRequests(csvData);
    setNormalizedRateRequests(normalizeRatePayload(csvData));
  };

  const csvDataToSend = normalizedCSVData(priceRates);
  return (
    <ShadowCard data-testid="bulk-upload" className={classes.content}>
      <CardHead headingText="Bulk Upload Rates" />
      {isLoading && (
        <>
          <LinearProgress variant="determinate" value={progressBar} />
          <Box mt={2}>
            <Typography variant="h4">Generating Rates...</Typography>
          </Box>
        </>
      )}
      <Grid className={classes.cssInput}>
        <Typography>Select CSV with Rate Request Information</Typography>
        <CSVReader
          cssClass={classes.cssInput}
          onFileLoaded={handleRateRequestNormalization}
          parserOptions={papaparseOptions}
        />
      </Grid>
      <Grid className={classes.cssInput}>
        <a
          className={classes.downloadLink}
          href={require('../../assets/sample.csv')}
          download
        >
          <Typography>Sample CSV</Typography>
        </a>
      </Grid>
      {rateRequests && (
        <>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Origin</TableCell>
                <TableCell>Destination</TableCell>
                <TableCell>Crossing</TableCell>
                <TableCell>Eq Type</TableCell>
                <TableCell>Load Value</TableCell>
                <TableCell>Hazmat</TableCell>
                <TableCell>Team Service</TableCell>
                {priceRates.length >= 1 && (
                  <>
                    <TableCell>Direct</TableCell>
                    <TableCell>Rate</TableCell>
                    <TableCell>Transload</TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {priceRates.length === 0 &&
                rateRequests &&
                rateRequests.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.origin}</TableCell>
                    <TableCell>{row.destination}</TableCell>
                    <TableCell>{row.crossing}</TableCell>
                    <TableCell>{row.eq_type}</TableCell>
                    <TableCell>
                      <NumberFormat
                        value={row.load_value}
                        thousandSeparator={true}
                        prefix="$"
                        displayType="text"
                      />
                    </TableCell>
                    <TableCell>{row.hazmat}</TableCell>
                    <TableCell>{row.team_service}</TableCell>
                  </TableRow>
                ))}

              {priceRates &&
                priceRates.map((row, index) => (
                  <TableRow
                    key={index}
                    className={
                      row.noRateInfoAvailable ? classes.errorRow : null
                    }
                  >
                    <TableCell>
                      {row.pickupAddressInputs &&
                        row.pickupAddressInputs[0].address}
                    </TableCell>
                    <TableCell>
                      {row.deliveryAddressInputs &&
                        row.deliveryAddressInputs[0].address}
                    </TableCell>
                    <TableCell>
                      {row.crossingName && row.crossingName}
                    </TableCell>
                    <TableCell>
                      {row.equipmentType && row.equipmentType}
                    </TableCell>
                    <TableCell>
                      <NumberFormat
                        value={row.loadValue && row.loadValue}
                        thousandSeparator={true}
                        prefix="$"
                        displayType="text"
                      />
                    </TableCell>
                    <TableCell>{row.isHazmat.checked ? 'Y' : 'N'}</TableCell>
                    <TableCell>
                      {row.isTeamService.checked ? 'Y' : 'N'}
                    </TableCell>
                    <TableCell>
                      <NumberFormat
                        value={row.direct}
                        thousandSeparator={true}
                        prefix="$"
                        displayType="text"
                      />
                    </TableCell>
                    <TableCell>
                      <NumberFormat
                        value={row.rate}
                        thousandSeparator={true}
                        prefix="$"
                        displayType="text"
                      />
                    </TableCell>
                    <TableCell>
                      <NumberFormat
                        value={row.transload}
                        thousandSeparator={true}
                        prefix="$"
                        displayType="text"
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {isLoading ? (
            <Box mt={2}>
              <Loader />
            </Box>
          ) : (
            <Button
              disabled={priceRates.length >= 1}
              onClick={handleSubmitRateRequests}
              variant="contained"
              color="primary"
              className={classes.getRatesButton}
            >
              Get Rates
            </Button>
          )}
          {!isLoading && priceRates.length >= 1 && (
            <Button
              variant="outlined"
              color="secondary"
              className={classes.getRatesButton}
            >
              <CSVLink data={csvDataToSend} headers={csvHeaders}>
                Download me
              </CSVLink>
            </Button>
          )}
        </>
      )}
    </ShadowCard>
  );
};

const useStyles = makeStyles({
  content: {
    minHeight: 420,
    overflow: 'auto',
  },
  table: {
    minWidth: '620px',
  },
  cssInput: {
    padding: '10px',
    display: 'block',
    margin: '15px auto',
    border: '1px solid #ccc',
    borderRadius: '5px',
  },
  downloadLink: {
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  getRatesButton: {
    marginTop: '15px',
    width: '150px',
  },
  errorRow: {
    backgroundColor: '#d9534f',
  },
});

export default BulkUploader;
