import { InputAdornment, makeStyles } from '@material-ui/core';

import { FormField } from './index';
import NumberFormat from 'react-number-format';
import React from 'react';
import { connect } from 'formik';

const FormCurrency = ({
  canEdit,
  helperText,
  error,
  name,
  onChange,
  value,
}) => {
  const classes = useStyles();

  return (
    <>
      {canEdit ? (
        <FormField
          id={name}
          name={name}
          error={error}
          canEdit={canEdit}
          helperText={helperText}
          type="number"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          value={value}
          onChange={onChange}
        />
      ) : (
        <NumberFormat
          value={value}
          thousandSeparator={true}
          prefix="$"
          displayType="text"
          renderText={value => (
            <span className={classes.text}>{value ? value : '-'} </span>
          )}
        />
      )}
    </>
  );
};

const useStyles = makeStyles({
  input: {
    height: 24,
    border: 'none',
    borderBottom: '1px solid black',
    fontSize: '1rem',
    padding: 0,
    textAlign: 'right',
    width: '100%',
    outline: 'none',
  },
  text: {
    fontSize: '1rem',
    textAlign: 'right',
    height: 24,
  },
});

export default connect(FormCurrency);
