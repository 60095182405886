import {
  FormControl,
  FormHelperText,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';

import React from 'react';

const CardItem = ({ label, name, error, errorText, content }) => {
  const classes = useStyles();
  // Error is an optional prop (object), if undefined it defaults to empty object
  error = error ? error : {};

  const formControl = (
    <FormControl className={classes.formControl}>
      {content}
      <FormHelperText error data-testid={`errorText.${name}`}>
        {error[name] ? errorText : null}
      </FormHelperText>
    </FormControl>
  );

  return !label ? (
    formControl
  ) : (
    <Grid container wrap="nowrap" justify="space-between">
      <Grid item xs={5}>
        <Typography component="span" color="secondary">
          {label}
        </Typography>
      </Grid>
      <Grid item xs={7}>
        {formControl}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
    textAlign: 'right',
    '& a': {
      textDecoration: 'none',
      minHeight: 24,
      color: theme.palette.blueSky.main,
    },
  },
}));

export default CardItem;
