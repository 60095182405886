import React from 'react';
import PropTypes from 'prop-types';
import { PrivateRoute } from '../components/common/Privatize';
import { LoadBoard, LoadOverview, PastLoads } from '../views/LoadSystem';

const LoadSystem = ({ match }) => {
  return (
    <>
      <PrivateRoute
        path={`${match.url}/load-board`}
        render={props => <LoadBoard {...props} />}
      />
      <PrivateRoute
        path={`${match.url}/past-loads`}
        render={props => <PastLoads {...props} />}
      />
      <PrivateRoute
        path={`${match.url}/id/:id`}
        render={props => <LoadOverview {...props} isForager />}
      />
    </>
  );
};
LoadSystem.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};
export default LoadSystem;
