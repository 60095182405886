import { Grid, Typography, makeStyles } from '@material-ui/core';

import { CardItem } from './';
import React from 'react';
import TimeRangePicker from '@wojtekmaj/react-timerange-picker';
import { TripOrigin } from '@material-ui/icons';
import moment from 'moment-timezone/builds/moment-timezone-with-data';

const TimeRange = ({
  setValid,
  label,
  edit,
  error,
  editHook,
  name,
  timeRange,
  timeZone,
  errorText,
}) => {
  const classes = useStyles();

  const destructedTimeRange = timeRange => {
    return timeRange
      ? [
          timeRange[0] ? moment(timeRange[0]).format('HH:mm') : timeRange[0],
          timeRange[1] ? moment(timeRange[1]).format('HH:mm') : timeRange[1],
        ]
      : null;
  };

  const constructedTimeRange = timeRange => [
    timeRange[0]
      ? new Date(Date.parse(`${moment().format('MM DD YYYY')} ${timeRange[0]}`))
      : null,
    timeRange[1]
      ? new Date(Date.parse(`${moment().format('MM DD YYYY')} ${timeRange[1]}`))
      : null,
  ];

  const handleChange = timeRange => {
    if (timeRange === null) {
      editHook(name, [null, null]);
      setValid({ [name]: true });
    } else {
      if (timeRange[0] > timeRange[1]) {
        setValid({ [name]: false });
      } else {
        editHook(name, constructedTimeRange(timeRange));
        setValid({ [name]: true });
      }
    }
  };

  const setTo24Hours = () => {
    editHook(name, constructedTimeRange(['00:00', '24:00']));
    setValid({ [name]: true });
  };

  const createTimeZone = timeZone => {
    return timeZone ? moment.tz(new Date(), timeZone).format('z') : '';
  };

  const text = timeRange => {
    if (timeRange && timeRange[0] && timeRange[1]) {
      if (
        destructedTimeRange(timeRange)[0] === destructedTimeRange(timeRange)[1]
      ) {
        return '24 hours';
      } else {
        return `${destructedTimeRange(timeRange)[0]} - ${
          destructedTimeRange(timeRange)[1]
        } ${createTimeZone(timeZone)}`;
      }
    } else {
      return 'Closed';
    }
  };

  let content = edit ? (
    <Grid container justify="flex-end" alignItems="center">
      <TimeRangePicker
        className={classes.timeRange}
        value={destructedTimeRange(timeRange)}
        disableClock
        format="HH:mm"
        onChange={handleChange}
      />
      <TripOrigin
        fontSize="small"
        className={classes.icon}
        onClick={setTo24Hours}
      />
    </Grid>
  ) : (
    <Typography>{text(timeRange)}</Typography>
  );

  return (
    <CardItem
      label={label}
      name={name}
      error={error}
      errorText={errorText}
      content={content}
    />
  );
};

const useStyles = makeStyles(theme => ({
  timeRange: {
    flex: 1,
    fontSize: '1rem',
    marginRight: 2,
    '& .react-timerange-picker__wrapper': {
      border: 'none',
      borderBottom: '1px solid black',
      height: 24,
      width: '100%',
      '& button': {
        padding: 0,
      },
    },
    '& .react-timerange-picker__range-divider': {
      paddingTop: 2,
      paddingLeft: 2,
      paddingRight: 2,
      backgroundColor: 'white',
    },
    '& .react-timerange-picker__inputGroup': {
      justifyContent: 'flex-end',
    },
    '& .react-timerange-picker__button': {
      backgroundColor: 'white',
      marginLeft: 2,
      outline: 'none',
    },
  },
  icon: {
    cursor: 'pointer',
    '&:hover': {
      color: '#0078d7',
    },
    '&:focus': {
      color: '#0078d7',
    },
  },
}));

export default TimeRange;
