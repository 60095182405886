import React, { useEffect, useState } from 'react';

import { FilterTable } from '../common/Tables';
import { Grid } from '@material-ui/core';
import formatPhoneNumber from '../../util/formatPhoneNumber';
import { Loader } from '../../components/common/Loader';
import PropTypes from 'prop-types';
import { ShadowCard } from '../common/Containers';
import { getAxios } from '../../util';
import { useAuth0 } from '../../react-auth0-wrapper';
import EyeBallLinkCell from '../common/Tables/EyeBallLinkCell';

const CustomsBrokersTable = () => {
  const { accessToken } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [customsBrokers, setCustomsBrokers] = useState();

  useEffect(() => {
    getAxios('customs-broker/all', {}, accessToken).then(data => {
      if (data) {
        const chbData = data.map(chb => {
          return {
            ...chb,
            chb: chb.dbaName || chb.legalName,
          };
        });
        setCustomsBrokers(chbData);
        setLoading(false);
      }
    });
  }, [accessToken]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ShadowCard noTopPadding>
              <FilterTable
                title="All Customs Brokers"
                columns={columns}
                data={customsBrokers}
              />
            </ShadowCard>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const columns = [
  EyeBallLinkCell('/customs-brokers/profile/id', 'companyId'),
  {
    title: 'Customs Broker',
    field: 'chb',
  },
  { title: 'City', field: 'city' },
  { title: 'Main POC', field: 'pocName' },
  {
    title: 'Phone',
    field: 'office_phone',
    render: ({ office_phone }) =>
      formatPhoneNumber(String(office_phone))
  },
  {
    title: 'C-TPAT',
    field: 'ctpat',
    type: 'boolean',
  },
];

CustomsBrokersTable.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default CustomsBrokersTable;
