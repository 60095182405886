import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '../../../react-auth0-wrapper';
import {
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  makeStyles,
  Typography,
  LinearProgress,
} from '@material-ui/core';
import { PrivateComponent } from '../Privatize';
import { Email, Phone, Text } from '../../common/CardContent';
import { ShadowCard } from '../../common/Containers';
import { ModalContainer } from '../Modals';
import { catchErrors } from '../../../util';
import { postAxios } from '../../../util';

const contactRoles = {
  customer: [
    {
      name: 'decisionMaker',
      label: 'Decision Maker',
    },
    {
      name: 'loadPlanner',
      label: 'Load Planner',
    },
    {
      name: 'accountsPayable',
      label: 'Accounts Payable',
    },
    {
      name: 'exec',
      label: 'Exec',
    },
  ],
  facility: [
    {
      name: 'decisionMaker',
      label: 'Decision Maker',
    },
    {
      name: 'accountsPayable',
      label: 'Accounts Payable',
    },
    {
      name: 'exec',
      label: 'Exec',
    },
  ],
  chb: [
    {
      name: 'decisionMaker',
      label: 'Decision Maker',
    },
    {
      name: 'accountsPayable',
      label: 'Accounts Payable',
    },
    {
      name: 'exec',
      label: 'Exec',
    },
  ],
};

const ContactItem = ({
  updateContact,
  handleMainChange,
  handleChange,
  handleGetContacts,
  rolesFor,
  formik,
  ...contact
}) => {
  const classes = useStyles();
  const { accessToken } = useAuth0();

  // Validation
  const [error, setError] = useState({});
  const [firstNameValid, setFirstNameValid] = useState({ firstName: false });
  const [lastNameValid, setLastNameValid] = useState({ lastName: false });
  const [emailValid, setEmailValid] = useState({ email: false });
  const [officePhoneValid, setOfficePhoneValid] = useState({
    officePhone: false,
  });

  const [edit, setEdit] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [createdUserData, setCreatedUserData] = useState(null);
  const [createUserError, setCreateUserError] = useState(null);
  const [
    creatingAccountLoadingStatus,
    setCreatingAccountLoadingStatus,
  ] = useState(false);

  const handleEdit = () => setEdit(true);
  const handleToggleModal = () => setIsModalOpen(!isModalOpen);

  const handleSave = () => {
    catchErrors([firstNameValid, lastNameValid, emailValid, officePhoneValid])
      .then(() => {
        updateContact(contact.id);
        setEdit(false);
        setError({});
      })
      .catch(error => {
        setError(error);
      });
  };

  const handleCreateAccount = () => {
    setCreatingAccountLoadingStatus(true);

    return postAxios('user-management/users', { contact }, accessToken).then(
      data => {
        setCreatingAccountLoadingStatus(false);

        if (data.statusCode >= 400) {
          setCreateUserError(data);
        } else {
          setCreatedUserData(data);
          handleToggleModal();
          handleGetContacts();
        }
      }
    );
  };

  const onChange = (value, key) => handleChange(contact.id, value, key);

  const onCheckboxChange = key => e => {
    onChange(e.target.checked, key);
  };

  const onMainChange = () => handleMainChange(contact.id, !contact.main);

  const roles = contactRoles[rolesFor];
  return (
    <ShadowCard>
      <Grid container spacing={1}>
        <Grid item xs={12} className={classes.head}>
          <Grid container justify="space-between">
            <Grid>
              <Button
                variant="contained"
                onClick={edit ? handleSave : handleEdit}
                className={classes.button}
                size="small"
                color="secondary"
              >
                {edit ? 'Save' : 'Edit'}
              </Button>
              <PrivateComponent reqScopes={['customer-to-user:create']}>
                {!contact.userProfileId && (
                  <Button
                    variant="contained"
                    onClick={handleCreateAccount}
                    className={classes.button}
                    size="small"
                    color="secondary"
                    disabled={createUserError}
                  >
                    {createUserError
                      ? createUserError.message
                      : 'Create an Account'}
                  </Button>
                )}
                {creatingAccountLoadingStatus && (
                  <LinearProgress variant="query" color="secondary" />
                )}
              </PrivateComponent>
            </Grid>
            {(contact.main || edit) && (
              <Grid>
                <Chip
                  clickable={edit}
                  onClick={edit ? onMainChange : null}
                  size="small"
                  label="Main"
                  className={contact.main ? classes.activeChip : null}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Text
            label="First Name"
            value={contact.firstName}
            name="firstName"
            edit={edit}
            editHook={onChange}
            required
            error={error}
            errorText="Enter a valid first name"
            setValid={setFirstNameValid}
          />
        </Grid>
        <Grid item xs={12}>
          <Text
            label="Last Name"
            value={contact.lastName}
            name="lastName"
            edit={edit}
            editHook={onChange}
            required
            error={error}
            errorText="Enter a valid last name"
            setValid={setLastNameValid}
          />
        </Grid>
        <Grid item xs={12}>
          <Text
            label="Title"
            value={contact.title}
            name="title"
            edit={edit}
            editHook={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Email
            label="Email"
            email={contact.email}
            name="email"
            edit={edit}
            editHook={onChange}
            required
            error={error}
            errorText="Enter a valid email"
            setValid={setEmailValid}
          />
        </Grid>
        <Grid item xs={12}>
          <Phone
            label="Office Phone"
            number={contact.officePhone}
            name="officePhone"
            edit={edit}
            editHook={onChange}
            required
            error={error}
            errorText="Enter a valid office phone"
            setValid={setOfficePhoneValid}
          />
        </Grid>
        <Grid item xs={12}>
          <Text
            label="Office Phone Ext"
            value={contact.officePhoneExt}
            name="officePhoneExt"
            edit={edit}
            editHook={onChange}
            type="number"
          />
        </Grid>
        <Grid item xs={12}>
          <Phone
            label="Сell Phone"
            number={contact.phone}
            name="phone"
            edit={edit}
            editHook={onChange}
          />
        </Grid>
        <Grid item xs={12}>
          {roles.map(({ name, label }) => (
            <FormControlLabel
              key={name}
              className={classes.checkbox}
              control={
                <Checkbox
                  checked={contact[name]}
                  onChange={edit ? onCheckboxChange(name) : null}
                  value={name}
                  color="primary"
                  disabled={!edit}
                />
              }
              label={label}
            />
          ))}
        </Grid>
      </Grid>
      <ModalContainer
        open={isModalOpen}
        onClose={setIsModalOpen}
        modalWidth={675}
      >
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid>
            <Typography variant="h5" component="h2" display="block">
              <span role="img" aria-label="user-data">
                ⚠️
              </span>{' '}
              User Data{' '}
              <span role="img" aria-label="user-data">
                ⚠️
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Text
              label="Email"
              value={createdUserData && createdUserData.email}
              name="userEmail"
            />
            <Text
              label="Password"
              value={createdUserData && createdUserData.password}
              name="userPassword"
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={handleToggleModal}
              variant="contained"
              color="secondary"
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </ModalContainer>
    </ShadowCard>
  );
};

ContactItem.propTypes = {
  updateContact: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleMainChange: PropTypes.func.isRequired,
  rolesFor: PropTypes.string.isRequired,
};

const useStyles = makeStyles(theme => ({
  head: {
    marginBottom: 20,
  },
  button: {
    color: '#fff',
    marginRight: 5,
  },
  activeChip: {
    backgroundColor: theme.palette.vastField.main,
    color: '#fff',
  },
  checkbox: {
    '& .MuiFormControlLabel-label.Mui-disabled': {
      color: 'initial',
    },
  },
}));

export default ContactItem;
