import {
  BorderOperations,
  LoadCharges,
  LoadOverviewHeader,
  LoadUpdates,
  Notes,
  Requirements,
  Status,
  LoadIncidents,
} from '../../components/LoadSystem';
import { Box, Grid } from '@material-ui/core';
import { Formik, withFormik } from 'formik';
import React, { useEffect, useState } from 'react';

import { AccountReps } from '../../components/common/AccountReps';
import { Loader } from '../../components/common/Loader';
import { ProductInfoView } from '../LoadSystem';
import { StopDetailsCard } from '../../components/LoadSystem';
import { useAuth0 } from '../../react-auth0-wrapper';
import getLoadDataById from './utils/getLoadDataById';
import deriveBorderOperationsRoute from './utils/deriveBorderOperationsRoute';

const LoadOverview = props => {
  const { accessToken } = useAuth0();
  const [loading, setLoading] = useState(false);

  const [loadCargo, setLoadCargo] = useState([]);
  const [pickups, setPickups] = useState([]);
  const [deliveries, setDeliveries] = useState([]);
  const [borderOperations, setBorderOperations] = useState({});
  const [verifiedCustomerId, setVerifiedCustomerId] = useState(null);
  const [refetchStatus, setRefetchStatus] = useState(false);

  const { match, isForager } = props;

  const loadId = match.params.id;

  useEffect(() => {
    setLoading(true);

    getLoadDataById(loadId, accessToken).then(data => {
      const {
        borderOperationsData,
        pickUpsData,
        deliveriesData,
        verifiedCustomerData,
        cargoData,
      } = data;

      if (borderOperationsData) setBorderOperations(borderOperationsData);
      if (pickUpsData) setPickups(pickUpsData);
      if (deliveriesData) setDeliveries(deliveriesData);
      if (verifiedCustomerData)
        setVerifiedCustomerId(verifiedCustomerData.customer_id);
      if (cargoData) setLoadCargo(cargoData);

      setLoading(false);
    });
  }, [accessToken, loadId]);

  const firstPickup = pickups[0] ? pickups[0] : {};
  const lastDelivery = deliveries.length
    ? deliveries[deliveries.length - 1]
    : {};

  const borderOperationsRoute = deriveBorderOperationsRoute(
    firstPickup.country,
    lastDelivery.country
  );

  return (
    <Formik>
      {() => (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LoadOverviewHeader loadId={loadId} isForager={isForager} />
          </Grid>
          <Grid item xs={12}>
            <Status loadId={loadId} refetchStatus={refetchStatus} />
          </Grid>
          <Grid item xs={8}>
            <Grid container spacing={2}>
              {loading && (
                <Grid item xs={12}>
                  <Loader />
                </Grid>
              )}
              {pickups &&
                pickups.map((pickup, index) => (
                  <Grid item xs={12} key={index}>
                    <StopDetailsCard
                      index={index}
                      loadId={loadId}
                      formikType="pickups"
                      headingText={`${pickup.facilityCity}, ${pickup.facilityState}`}
                      subheader={`Pickup #${index + 1}`}
                      customerId={verifiedCustomerId}
                      customerReferenceLabel="Pickup #"
                      stopDetailsType="pickup"
                      values={pickup}
                      setRefetchStatus={setRefetchStatus}
                      refetchStatus={refetchStatus}
                      isForager={isForager}
                    />
                  </Grid>
                ))}
              <Grid item xs={12}>
                <BorderOperations
                  borderOperationsRoute={borderOperationsRoute}
                  borderOperations={borderOperations}
                  loadId={loadId}
                  refetchStatus={refetchStatus}
                  setRefetchStatus={setRefetchStatus}
                  isForager={isForager}
                  verifiedCustomerId={verifiedCustomerId}
                />
              </Grid>
              {loading && (
                <Grid item xs={12}>
                  {' '}
                  <Loader />{' '}
                </Grid>
              )}
              {deliveries &&
                deliveries.map((delivery, index) => (
                  <Grid item xs={12} key={index}>
                    <StopDetailsCard
                      index={index}
                      loadId={loadId}
                      formikType="deliveries"
                      headingText={`${delivery.facilityCity}, ${delivery.facilityState}`}
                      subheader={`Delivery #${index + 1}`}
                      customerId={verifiedCustomerId}
                      customerReferenceLabel="Delivery #"
                      stopDetailsType="delivery"
                      values={delivery}
                      setRefetchStatus={setRefetchStatus}
                      refetchStatus={refetchStatus}
                      isForager={isForager}
                    />
                  </Grid>
                ))}
              <Grid item xs={12}>
                <ProductInfoView
                  loadId={loadId}
                  loadCargo={loadCargo}
                  isForager={isForager}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={4}>
            <Box mb={2}>
              <AccountReps
                verifiedCustomerId={verifiedCustomerId}
                isForager={isForager}
              />
            </Box>
            <Box mb={2}>
              <Notes loadId={loadId} />
            </Box>
            <Box mb={2}>
              <Requirements loadId={loadId} isForager={isForager} />
            </Box>
            <Box mb={2}>
              <LoadUpdates
                loadId={loadId}
                isForager={isForager}
                mexBorderCrossingId={borderOperations.mexBorderCrossingId}
              />
            </Box>
            <Box mb={2}>
              <LoadIncidents loadId={loadId} isForager={isForager} />
            </Box>
            <Box mb={2}>
              <LoadCharges loadId={loadId} isForager={isForager} />
            </Box>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};

export default withFormik({
  mapPropsToValues: props => {
    return {
      loadNotes: '',
    };
  },
})(LoadOverview);
