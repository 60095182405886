import { Grid, Link, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { FilterTable } from '../common/Tables';
import { Loader } from '../common/Loader';
import { Link as RouterLink } from 'react-router-dom';
import { ShadowCard } from '../common/Containers';
import { getAxios } from '../../util/axiosHelper';
import moment from 'moment';
import { numberToUSD } from '../../util/numberUtils';
import { useAuth0 } from '../../react-auth0-wrapper';
import DuplicateLoadButton from './DuplicateLoadButton';
import EyeBallLinkCell from '../common/Tables/EyeBallLinkCell';

const LoadsTable = ({ table, limit, requestParams = {}, isForager }) => {
  const classes = useStyles();
  const { accessToken } = useAuth0();
  const [loads, setLoads] = useState();
  const [loading, setLoading] = useState(true);

  const baseUrl = isForager ? '/loads/id' : '/customer-portal/loads/id';

  const loadsOptionsByType = {
    inTransit: {
      title: 'Loads in Transit',
      columns: [
        EyeBallLinkCell(baseUrl, 'loadId'),
        {
          title: 'Load #',
          field: 'loadId',
          render: ({ loadId }) => `${loadId}`,
        },
        {
          title: 'Pickup Date',
          field: 'pickupDate',
          defaultSort: 'desc',
          render: ({ pickupDate }) =>
            pickupDate && moment(pickupDate).format('MM/DD/YY'),
        },
        {
          title: 'Pickup',
          field: 'pickupCityWithState',
        },
        { title: 'Delivery', field: 'deliveryCityWithState' },
        // {
        //   title: 'Rate',
        //   field: 'rate',
        //   render: ({ rate }) => rate && numberToUSD(rate),
        // },
        { title: 'Trailer #', field: 'trailerNumber' },
        { title: 'Status', field: 'loadStatus' },
        { title: 'Last Location', field: 'lastLocation' },
        { title: 'Distance to Next Stop', field: 'milesToNextStop' },
        {
          title: '',
          render: ({ loadId }) => <DuplicateLoadButton loadId={loadId} />,
        },
      ],
      apiPath: 'customer-portal/loads-in-transit',
      linkPath: '/customer-portal/loads/in-transit',
    },
    planned: {
      title: 'Planned Loads',
      columns: [
        EyeBallLinkCell(baseUrl, 'loadId'),
        {
          title: 'Load #',
          field: 'loadId',
          render: ({ loadId }) => `FRGW-${loadId}`,
        },
        {
          title: 'Pickup Date',
          field: 'pickupDate',
          defaultSort: 'desc',
          render: ({ pickupDate }) =>
            pickupDate && moment(pickupDate).format('MM/DD/YY'),
        },
        {
          title: 'Pickup',
          field: 'pickupCity',
          render: ({ pickupCity, pickupState }) =>
            `${pickupCity}, ${pickupState}`,
        },
        {
          title: 'Delivery',
          field: 'deliveryCity',
          render: ({ deliveryCity, deliveryState }) =>
            `${deliveryCity}, ${deliveryState}`,
        },
        {
          title: 'Rate',
          field: 'rate',
          render: ({ rate }) => rate && numberToUSD(rate),
        },
        {
          title: '',
          render: ({ loadId }) => <DuplicateLoadButton loadId={loadId} />,
        },
      ],
      apiPath: 'customer-portal/loads-planned',
      linkPath: '/customer-portal/loads/planned',
    },
    history: {
      title: 'Load History',
      columns: [
        EyeBallLinkCell(baseUrl, 'loadId'),
        {
          title: 'Load #',
          field: 'loadId',
          defaultSort: 'desc',
          render: ({ loadId }) => `FRGW-${loadId}`,
        },
        {
          title: 'Pickup Date',
          field: 'pickupDate',
          render: ({ pickupDate }) =>
            pickupDate && moment(pickupDate).format('MM/DD/YY'),
        },
        {
          title: 'Pickup',
          field: 'pickupCity',
          render: ({ pickupCity, pickupState }) =>
            `${pickupCity}, ${pickupState}`,
        },
        {
          title: 'Delivery',
          field: 'deliveryCity',
          render: ({ deliveryCity, deliveryState }) =>
            `${deliveryCity}, ${deliveryState}`,
        },
        {
          title: 'Rate',
          field: 'rate',
          render: ({ rate }) => rate && numberToUSD(rate),
        },
        {
          title: '',
          render: ({ loadId }) => <DuplicateLoadButton loadId={loadId} />,
        },
        // { title: 'Status', field: 'status' }, Not needed until invoicing system
      ],
      apiPath: 'customer-portal/load-history',
      linkPath: '/customer-portal/loads/history',
    },
    loadBoard: {
      title: 'Load Board',
      columns: [
        EyeBallLinkCell(baseUrl, 'loadId'),
        {
          title: 'Load#',
          field: 'loadId',
          render: ({ loadId }) => `FRGW-${loadId}`,
        },
        { title: 'Customer', field: 'customer' },
        { title: 'Equip. Type', field: 'equipType' },
        {
          title: 'Pickup Date',
          field: 'pickupDate',
          defaultSort: 'desc',
          render: ({ pickupDate }) =>
            pickupDate && moment(pickupDate).format('MM/DD/YY'),
        },
        { title: 'Pickup', field: 'pickupLocation' },
        {
          title: 'Delivery Date',
          field: 'deliveryDate',
          render: ({ deliveryDate }) =>
            deliveryDate && moment(deliveryDate).format('MM/DD/YY'),
        },
        { title: 'Delivery', field: 'deliveryLocation' },

        { title: 'Crossing', field: 'crossing' },
        { title: 'Commodity', field: 'commodity' },
        {
          title: 'Rate',
          field: 'rate',
          render: ({ rate }) => rate && numberToUSD(rate),
        },
        { title: 'Load Status', field: 'status' },
        { title: 'Ascend Load# ', field: 'ascendNumber' },
        { title: 'Customer Reference#', field: 'referenceNumber' },
        { title: 'Created By', field: 'createdBy' },
      ],
      apiPath: 'load/load-board',
    },
    pastLoads: {
      title: 'Past Loads',
      columns: [
        EyeBallLinkCell(baseUrl, 'loadId'),
        {
          title: 'Load#',
          field: 'loadId',
          defaultSort: 'desc',
          render: ({ loadId }) => `FRGW-${loadId}`,
        },
        { title: 'Customer', field: 'customer' },
        {
          title: 'Pickup Date',
          field: 'pickupDate',
          render: ({ pickupDate }) =>
            pickupDate && moment(pickupDate).format('MM/DD/YY'),
        },
        { title: 'Pickup', field: 'pickupLocation' },
        { title: 'Delivery', field: 'deliveryLocation' },
        { title: 'Crossing', field: 'crossing' },
        { title: 'Commodity', field: 'commodity' },
        {
          title: 'Rate',
          field: 'rate',
          render: ({ rate }) => rate && numberToUSD(rate),
        },
        { title: 'Ascend Load# ', field: 'ascendNumber' },
        // { title: 'Load Status', field: 'status' }, not needed until we have invoicing
      ],
      apiPath: 'load/load-board',
    },
  };

  const options = loadsOptionsByType[table];
  useEffect(() => {
    const params = {
      ...requestParams,
      limit,
    };
    getAxios(options.apiPath, { params }, accessToken).then(data => {
      setLoads(data);
      setLoading(false);
    });
  }, [accessToken, limit, options.apiPath]); //eslint-disable-line

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ShadowCard noTopPadding>
              <FilterTable
                title={options.title}
                filtering={!limit}
                noFooter={limit}
                noSearch={limit}
                columns={options.columns}
                data={loads}
              />

              {limit && options.linkPath && (
                <Grid container justify="flex-end" className={classes.viewAll}>
                  <Link
                    variant="body2"
                    component={RouterLink}
                    to={options.linkPath}
                  >
                    View All
                  </Link>
                </Grid>
              )}
            </ShadowCard>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  viewAll: {
    marginTop: 15,
    '& a': {
      color: theme.palette.blueSky.main,
    },
  },
}));

export default LoadsTable;
