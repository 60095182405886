import {
  Box,
  FormHelperText,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  CardHead,
  CardItem,
  Suggest,
  Selector,
} from '../../components/common/CardContent';
import { ErrorMessage, connect } from 'formik';
import {
  FormCurrency,
  FormField,
  FormSelect,
} from '../../components/common/Forms';
import React, { useEffect, useState } from 'react';
import { arrayToSelectorObject, getAxios, putAxios } from '../../util';

import { ShadowCard } from '../../components/common/Containers';
import { Toggle } from '../../components/common/Switches';
import { useAuth0 } from '../../react-auth0-wrapper';
import { PrivateComponent } from '../../components/common/Privatize';
import { SaveEditIconButton } from '../../components/common/Buttons';

const ProductInfoForm = ({
  index,
  formik,
  isHazmat = { checked: false },
  equipmentType,
  productValues,
  loadId,
}) => {
  const classes = useStyles();
  const { values, setFieldTouched, handleChange, setFieldValue } = formik;

  const { accessToken } = useAuth0();

  const product = productValues || values.products[index];

  const [hazmatUNName, setHazmatUNName] = useState(null);
  const [hazmatClasses, setHazmatClasses] = useState({});
  const [packagingOptions, setPackagingOptions] = useState({});
  const [packagingGroup, setPackagingGroup] = useState({});
  const [commodityOptions, setCommodityOptions] = useState({});
  const [canEdit, setCanEdit] = useState(!loadId);
  const [localProduct, setLocalProduct] = useState({});

  const change = (name, e) => {
      const localNameArr = name.split('.');
      const localName = localNameArr[localNameArr.length - 1];
      const localValue = e.target.value;
      setLocalProduct({ ...localProduct, [localName]: localValue });

      // Handle formik editing
      if (handleChange) handleChange(e);
      if (setFieldTouched) setFieldTouched(name, true, false);
  };

  // Need a handler that allows to handle non formik components (e.g Selectors)
  const handleTheChange = (value, name) => {
    if (canEdit) {
      setLocalProduct({ ...localProduct, [name]: value });
    }
  };

  const setHazmatUNObj = hazmatUnObj => {
    setFieldValue(
      `products.${index}.hazmatUnId`,
      hazmatUnObj ? hazmatUnObj.id : null
    );
    setHazmatUNName(hazmatUnObj ? hazmatUnObj.name : null);
  };

  const onSave = () => {
    putAxios('load/product', { payload: { ...localProduct } }, accessToken);
  };

  const handleCanEdit = () => {
    setCanEdit(!canEdit);
    canEdit && onSave();
  };

  useEffect(() => {
    getAxios('load/hazmat-classes', {}, accessToken).then(data => {
      const hasmatClassesObj = arrayToSelectorObject(data, 'id');
      setHazmatClasses(hasmatClassesObj);
    });
  }, [accessToken]);

  useEffect(() => {
    getAxios('load/packaging-options', {}, accessToken).then(data => {
      const packagingOptionsObj = arrayToSelectorObject(data, 'id');
      setPackagingOptions(packagingOptionsObj);
    });
  }, [accessToken]);

  useEffect(() => {
    getAxios('load/packaging-group-options', {}, accessToken).then(data => {
      const packagingGroupObj = arrayToSelectorObject(data, 'id');
      setPackagingGroup(packagingGroupObj);
    });
  }, [accessToken]);

  useEffect(() => {
    getAxios('load/commodity-options', {}, accessToken).then(data => {
      const normalizedCommodityObj = arrayToSelectorObject(data, 'id');
      setCommodityOptions(normalizedCommodityObj);
    });
  }, [accessToken]);

  useEffect(() => {
    if (!canEdit && productValues.hazmatUnId) {
      getAxios(
        'load/un-number',
        { params: { id: productValues.hazmatUnId } },
        accessToken
      ).then(data => {
        data && setHazmatUNName(data.name);
      });
    }
  }, [accessToken, canEdit]); //eslint-disable-line

  useEffect(() => {
    setLocalProduct(product);
  }, [product]);

  const refeerOptions = {
    1: 'Cycle',
    2: 'Continous',
  };

  const PrivateEditIcon = (
    <PrivateComponent reqScopes={['stop-details:edit']}>
      <SaveEditIconButton edit={canEdit} onClick={handleCanEdit} />
    </PrivateComponent>
  );

  const renderFieldArrayErrorMessage = (name, fieldName, margin) => (
    <Box mt={margin}>
      <ErrorMessage name={name}>
        {msg => <FormHelperText error={true}>{msg[fieldName]}</FormHelperText>}
      </ErrorMessage>
    </Box>
  );

  return (
    <ShadowCard>
      <Grid item xs={12} className={classes.cardHeadSpacer}>
        <CardHead action={PrivateEditIcon} headingText="Product Information" />
      </Grid>
      <Grid container spacing={4}>
        <Grid item md={6} xs={12}>
          {/* Having to do this cause FormSelect only works with formik  */}
          {loadId ? (
            <Selector
              label="Commodity Type"
              edit={canEdit}
              required
              value={localProduct.cargoCommodityId}
              name="cargoCommodityId"
              options={commodityOptions}
              editHook={handleTheChange}
            />
          ) : (
            <CardItem
              label="Commodity Type"
              content={
                <>
                  <FormSelect
                    canEdit={canEdit}
                    name={`products.${index}.cargoCommodityId`}
                    onChange={handleChange(
                      `products.${index}.cargoCommodityId`
                    )}
                    options={commodityOptions}
                    value={product.cargoCommodityId}
                  />
                  {renderFieldArrayErrorMessage(
                    `products[${index}]`,
                    'cargoCommodityId',
                    2
                  )}
                </>
              }
            />
          )}
          <CardItem
            label="Commodity Description"
            content={
              <FormField
                canEdit={canEdit}
                name={`products.${index}.description`}
                onChange={change.bind(null, 'description')}
                value={localProduct.description}
              />
            }
          />
          <CardItem
            label="Total Weight"
            content={
              <>
                <FormField
                  type="number"
                  canEdit={canEdit}
                  name={`products.${index}.weight`}
                  onChange={change.bind(null, 'weight')}
                  value={localProduct.weight}
                />
                {renderFieldArrayErrorMessage(
                  `products[${index}]`,
                  'weight',
                  1
                )}
              </>
            }
          />
        </Grid>
        <Grid item md={6} xs={12}>
          {/* Having to do this cause FormSelect only works with formik  */}
          {loadId ? (
            <Selector
              label="Packaging Type"
              edit={canEdit}
              required
              value={localProduct.packagingId}
              name="packagingId"
              options={packagingOptions}
              editHook={handleTheChange}
            />
          ) : (
            <CardItem
              label="Packaging Type"
              content={
                <>
                  <FormSelect
                    canEdit={canEdit}
                    name={`products.${index}.packagingId`}
                    onChange={handleChange(`products.${index}.packagingId`)}
                    options={packagingOptions}
                    value={product.packagingId}
                  />
                  {renderFieldArrayErrorMessage(
                    `products[${index}]`,
                    'packagingId',
                    2
                  )}
                </>
              }
            />
          )}

          <CardItem
            label="Packaging QTY"
            content={
              <>
                <FormField
                  type="number"
                  canEdit={canEdit}
                  name={`products.${index}.quantity`}
                  onChange={change.bind(null, `products.${index}.quantity`)}
                  value={localProduct.quantity}
                />
                {renderFieldArrayErrorMessage(
                  `products[${index}]`,
                  'quantity',
                  1
                )}
              </>
            }
          />
          <CardItem
            label="Amount (USD)"
            content={
              <>
                <FormCurrency
                  canEdit={canEdit}
                  name={`products.${index}.value`}
                  onChange={change.bind(null, `value`)}
                  value={localProduct.value}
                />
                {renderFieldArrayErrorMessage(`products[${index}]`, 'value', 1)}
                <ErrorMessage name="loadValue" />
              </>
            }
          />
        </Grid>
        <Grid item xs={12}>
          {(product.hazmat ||
            product.hazmatClassId !== null ||
            product.hazmatUnId !== null ||
            isHazmat.checked) && (
            <Toggle
              label="Hazmat"
              name={`products.${index}.hazmat`}
              value={product && product.hazmat}
              checked={
                (product && product.hazmat) ||
                (product && product.hazmatClassId !== null) ||
                (product && product.hazmatUnId !== null) ||
                isHazmat.checked
              }
              editHook={(name, text) =>
                setFieldValue(`products.${index}.hazmat`, text)
              }
            />
          )}
          {(equipmentType === 'Reefer' || product.reeferOptionId > 0) && (
            <Toggle
              label="Reefer"
              name={`products.${index}.reefer`}
              value={product && product.reefer}
              checked={
                (product && product.reefer) ||
                equipmentType === 'Reefer' ||
                product.reeferOptionId > 0
              }
              editHook={(name, text) => {
                setFieldValue(`products.${index}.reefer`, text);
              }}
            />
          )}
        </Grid>

        {product.hazmat ||
        product.hazmatClassId !== null ||
        product.hazmatUnId !== null ||
        isHazmat.checked ? (
          <Grid item md={6} xs={12}>
            <CardItem
              label="Hazmat Class"
              content={
                <FormSelect
                  canEdit={canEdit}
                  name={`products.${index}.hazmatClassId`}
                  onChange={(e, name) => {
                    setFieldValue(
                      `products.${index}.hazmatClassId`,
                      name.props.value
                    );
                  }}
                  options={hazmatClasses}
                  value={product.hazmatClassId}
                />
              }
            />
            <CardItem
              label="UN#"
              content={
                canEdit ? (
                  <Suggest
                    edit="true"
                    name={`products.${index}.hazmatUnId`}
                    placeholder="Search for Hazmat UN number..."
                    valuesToExclude={[]}
                    value={hazmatUNName || ''}
                    editHook={hazmatUnSearchObj => {
                      setHazmatUNObj(hazmatUnSearchObj);
                    }}
                    urlString="load/search-hazmat-un-number"
                    onAddressEmpty={() => {
                      setHazmatUNObj(null);
                    }}
                  />
                ) : (
                  <Typography>{hazmatUNName}</Typography>
                )
              }
            />
            <CardItem
              label="Packing Group"
              content={
                <FormSelect
                  canEdit={canEdit}
                  name={`products.${index}.packingGroupId`}
                  onChange={(e, name) => {
                    setFieldValue(
                      `products.${index}.packingGroupId`,
                      name.props.value
                    );
                  }}
                  options={packagingGroup}
                  value={product.packingGroupId}
                />
              }
            />
          </Grid>
        ) : null}

        {equipmentType === 'Reefer' || product.reeferOptionId ? (
          <Grid item md={6} xs={12}>
            <CardItem
              label="Reefer Type"
              content={
                <FormSelect
                  canEdit={canEdit}
                  name={`products.${index}.reeferOptionId`}
                  onChange={handleChange(`products.${index}.reeferOptionId`)}
                  options={refeerOptions}
                  value={product.reeferOptionId}
                />
              }
            />
            <CardItem
              label="Min Temp (°F)"
              content={
                <FormField
                  canEdit={canEdit}
                  name={`products.${index}.loTemp`}
                  onChange={change.bind(null, 'loTemp')}
                  value={product.loTemp}
                />
              }
            />
            <CardItem
              label="Max Temp (°F)"
              content={
                <FormField
                  canEdit={canEdit}
                  name={`products.${index}.hiTemp`}
                  onChange={change.bind(null, 'hiTemp')}
                  value={product.hiTemp}
                />
              }
            />
          </Grid>
        ) : null}
      </Grid>
    </ShadowCard>
  );
};

const useStyles = makeStyles({
  cardHeadSpacer: {
    marginBottom: '12px',
  },
  gridItemSpacer: {
    marginBottom: '20px',
  },
});

export default connect(ProductInfoForm);
